export default {
  geral: {
    app: 'Educational Sponte EN',
    selecioneAluno: 'Select a student',
    pedagogico: 'Pedagogical',
    integrantes: 'Members',
    matriz: 'Headquarters',
    diarioAulas: 'Class Diary',
    notas: 'Grades',
    nota: 'Note',
    individual: 'Individual',
    reposicao: 'Replacement',
    turno: 'Shift',
    etapa: 'Stage',
    curso: 'Course',
    sala: 'Living room',
    conceitos: 'Concepts',
    aula: 'Class',
    serie: 'Series',
    qtdMinima: 'Minimum quantity',
    anoLetivo: 'School year',
    calendarioPedagogico: 'Teaching calendar',
    calendarioPadrao: 'Standard Calendar',
    calendarioPendencias: 'Calendar and Pending',
    aulasPendencias: 'Classes and Pending',
    funcaoNaTurma: 'Class role',
    equipePedagogica: 'Teaching team',
    modalidade: 'Modality',
    areaDeConhecimento: 'Knowledge area',
    codigoInep: 'INEP code',
    faixa: 'Range',
    anoFaixa: '$t(geral:ano) / $t(geral:faixa)',
    anoFaixaDe: 'De $t(geral:ano) / $t(geral:faixa)',
    anoFaixaAte: 'Até $t(geral:ano) / $t(geral:faixa)',
    matricula: 'Registration',
    matriculaSerie: '$t(geral:matricula) / $t(geral:serie)',
    precoSerie: '$t(geral:preco) / $t(geral:serie)',
    diarioDeAulas: 'Class diary',
    diarioDeAula: 'Class diary',
    diario: 'Daily',
    conteudo: 'Contents',
    nMatricula: 'Registration number',
    nDeAlunos: 'No of students',
    nChamada: 'No. of call',
    integrante: 'Integral',
    mudarDeTurma: 'Change class',
    disciplina: 'Subjects',
    componente: 'Component',
    professor: 'Teacher',
    componenteCurricular: 'Curricular component',
    disciplinaComponente: '$t(geral:disciplina) / $t(geral:componente)',
    selecioneUmAluno: 'Select a student',
    selecioneUmConteudoParaImportar: 'Select a content to import',
    escrevaAqui: 'Write here...',
    mediaGeral: 'overall average',
    componenteCurricularProfessor: '$t(geral:componenteCurricular) / $t(geral:professor)',
    turmasEmAndamento: 'Ongoing classes',
    turmasEncerradas: 'Closed classes',
    turmasCanceladas: 'Canceled classes',
    turmasEmFormacao: 'Classes in formation',
    periodoTurno: 'Time course / Shift',
    mediaFrequencia: 'Average / Frequency',
    siglaEtapa: 'Acronym of the step',
    anoFaixaInicial: 'Year / Initial Range',
    anoFaixaFinal: 'Year / Final track',
    posicaoHabilidade: 'Skill position',
    siglaPosicao: 'Position acronym',
    unidadeTematica: 'Thematic unit',
    selecioneUnidadeTematica: 'Select a thematic unit',
    objetosDoConhecimento: 'knowledge objects',
    adicionarObjetosDoConhecimento: 'Add knowledge object',
    aluno: 'Student',
    'o-que': 'Which',
    para: 'For',
    ordem: 'Order',
    clienteNumeroContrato: 'Client / Contract no.',
    situacaoTurma: 'Situation / Class',
    numeroMatricula: 'Registration number',
    numeroContrato: 'Contract no.',
    itemNumeroParcelas: 'Item / Number of installments',
    totalValorParcela: 'Total / Installment amount',
    unica: 'Only',
    adicionarTurma: 'Add class',
    totalTurmas: 'Total classes',
    turmaComponente: 'Class / Component',
    turmaMediaAtual: 'Class / Current average',
    valorCargaHoraria: 'Value / Workload',
    totalTurma: 'Class total',
    utilizarconfiguracoesDeFormulas: 'Use formula settings',
    nomeMatricula: 'Name / Registration',
    nomeMediaAtual: 'Name / Current average',
    nomeLegenda: 'Name / Subtitle',
    nomeTemplate: 'Name / Template',
    pesoOrdem: 'Weight / Order',
    segundaChamada: 'Second call',
    numeroContratoSituacao: 'Contract no. / Situation',
    gradeCurricular: 'Curriculum Grade',
    gradeCurricularSistemaAvaliacao: 'Curriculum Grade and Evaluation System',
    situacaoPedagogica: 'Pedagogical situation',
    numeroChamada: 'No. of call',
    maisSobreCampoDeExperiencia: 'More about the field of experience',
    orientacoesGeraisQuantoAoProcessoPedagogico: 'General guidelines on the pedagogical process',
    direitoAprendizagem: 'Learning right',
    presenca: 'Presence',
    presencaMedianteConfirmacao: 'Presence upon confirmation',
    falta: 'Lack',
    faltas: 'Absences',
    justificativa: 'Justification',
    visualizarJustificativa: 'View justification',
    selecionetempoDuracaoAtividade: 'Select activity duration time',
    campoExperiencia: 'Field of experience',
    numeroAulas: 'Number of classes',
    tempoDuracaoMinutos: 'Running time in minutes',
    selecionarGrade: 'Select grid',
    selecioneUmaGrade: 'Select a grid',
    cargaSemanal: 'Weekly charge',
    templateAvaliacao: 'Evaluation template',
    sistemaDeAvaliacao: 'Evaluation system',
    novaDisciplinaAdicional: 'New additional discipline',
    disciplinasAdicionais: 'Additional subjects',
    serieOuCurso: 'Series or course',
    dataDeRealizacao: 'Date of realization',
    comercial: 'Relationship',
    aoMenosUmAgendamento: 'At least one appointment',
    agendaCheia: 'Full schedule',
    diaSelecionado: 'Selected day',
    diaBloqueado: 'Blocked day',
    ano: 'Year',
    mes: 'Month',
    semana: 'Week',
    dia: 'Day',
    competencia: 'Competence',
    customizada: 'Customized',
    habilidadeEnsinoMedio: 'High school skill',
    habilidadeEnsinoFundamental: 'Elementary school skill',
    sistemaAvaliacao: 'Evaluation system',
    cargaHorariaSemanal: 'Weekly workload',
    cargaHorariaAnual: 'Annual workload',
    componenteCurricularOptativo: 'Optional curriculum component',
    adicionarComponenteCurricular: 'Add curriculum component',
    numeroAula: 'Class No.',
    planoDeEnsino: 'Teaching plan',
    parecerDescritivo: 'Descriptive opinion',
    linhaProgramatica: 'Programmatic line',
    metodologia: 'Methodology',
    tipoComponentePai: 'Type / parent component',
    cargaSemanaAnual: 'weekly charge / Yearly',
    componenteSistemaAvaliacao: 'Component / Evaluation system',
    novaAula: 'New class',
    editarAula: 'Edit class',
    organizacaoSequencial: 'Sequential curriculum organization',
    sistemaEnsino: 'Education system',
    avaliacoes: 'Assessments',
    novaAvaliacao: 'New assessment',
    configuracaoDeBolsa: 'Bag configuration',
    saldoConta: 'Account balance',
    horarioAula: 'Class schedule',
    dadosClinicos: 'Health record',
    tempoAgendamentoAntesAula: 'Time to schedule before class',
    subHabilitacoes: 'Subjects',
    habilitacoes: 'Qualifications',
    habilitacao: 'Qualification',
    subHabilitacao: 'Under-qualification',
    formula: 'Formula',
    situacaoAluno: 'Student situation',
    templateAvaliacaoPadrao: 'Standard assessment template',
    sistemaAvaliacaoPadrao: 'Standard rating system',
    intervaloAvaliacao: 'Evaluation interval',
    calculoMediaFinal: 'Calculation of the final average',
    calculoSubDisciplina: 'Subdiscipline Calculus',
    mediaMinima: 'Minimum average',
    pesoMedia: 'Average weight',
    frequenciaMinima: 'Minimum frequency',
    utilizarArredondamento: 'Use rounding',
    arredondamento: 'Rounding',
    maxPontosAnuais: 'Maximum annual points',
    validarNotaMaximaRecuperacao: 'Validate maximum recovery score',
    utilizaPontosFracionarios: 'Use fractional points',
    utilizaHabilidadesDescritivas: 'Use descriptive skills',
    utilizaRecuperacaoParcial: 'Use partial recovery',
    utilizarRecuperacaoParcial: 'Use partial recovery',
    utilizaAgrupamentoAvaliacoes: 'Uses grouping of assessments',
    utilizaExameFinal: 'Use final exam',
    ignorarMenorNotaAgrupamento: 'Ignore lowest grade of grouping',
    desconsiderarAvaliacaoNotaNaoInformada: 'Disregard evaluations if the grade is not informed',
    desconsiderarNotaRecuperacaoMenorQueZero: 'Disregard the recovery score if it is less than 0',
    desconsiderarNotaRecuperacaoMenorQueNotaPeriodo: 'Disregard the recovery score if it is less than the period score',
    desconsiderarNotaRecuperacaoMediaRecuperacaoMenorQueZero:
      'Disregard the recovery score if the average after recovery is less than 0',
    desconsiderarNotaRecuperacaoMediaRecuperacaoMenorQueNotaPeriodo:
      'Disregard the recovery grade if the average after recovery is lower than the grade for the period',
    desconsiderarNotaExameFinalMenorQueMedia:
      'Disregard the Final Exam grade if it is lower than the average after the final exam',
    considerarNotaExameFinalMediaSuperiorMediaMinima:
      'Consider the grade of the Final Exam, even if the student s average is higher than the minimum average',
    ignorarPesosCalcularMediaAposExame: 'Ignore weights to calculate average after Final Exam',
    configuracaoOuRecuperacaoParcial: 'Setup or partial recovery',
    agrupamentoAvaliacoes: 'Grouping of assessments',
    mediaNotasParciais: 'Average of partial grades',
    configuracoesRecuperacao: 'Recovery settings',
    mediaAposRecuperacao: 'Average after recovery',
    exameFinal: 'Final exam',
    configuracoesExameFinal: 'Final exam settings',
    mediaAposExameFinal: 'Average after final exam',
    notasParciais: 'Partial notes',
    nomenclatura: 'Nomenclature',
    peso: 'Weight',
    notaMaxima: 'Full mark',
    maximoPontos: 'Maximum points',
    mediaResultante: 'Resulting average',
    tipoAvaliacao: 'Type of assessment',
    mediaEFrequencia: 'Average and frequency',
    adicionarTemplate: 'Add template',
    adicionarModelo: 'Add model',
    numeroDeAvaliacoes: 'No. of Reviews',
    definicoesAula: 'Class definitions',
    teorica: 'Theoretical',
    recuperacao: 'Recovery',
    casasDecimais: 'Decimal places',
    medias: 'Averages',
    mediasParciais: 'Partial averages',
    recuperacoes: 'Recoveries',
    podeRetirarDependente: 'Can withdraw dependent',
    nBimestre: '{{number}}º Two months',
    nBim: '{{number}}º Bim',
    nTri: '{{number}}º Trim',
    nSem: '{{number}}º Sem',
    nTrimestre: '{{number}}º trimester',
    nSemestre: '{{number}}º Semester',
    nPeriodo: '{{number}}º Time course',
    nModulo: '{{number}}º module',
    modulo: 'Module',
    selecioneUmaTurma: 'Select a class',
    conselhoDeClasse: 'Class council',
    somaDasNotas: 'Sum of notes',
    mediaFinal: 'Final media',
    mediaAnual: 'Annual average',
    emExame: 'In examination',
    exame: 'Exam',
    media: 'Average',
    aprovado: 'Approved',
    reprovado: 'Disapproved',
    cursando: 'Studying',
    naoDefinida: 'Not defined',
    naoDefinido: 'Undefined',
    templatesParciais: 'Partial Templates',
    templatesFinais: 'Final templates',
    parcialFinal: 'Partial/Final',
    final: 'Final',
    parcial: 'Partial',
    planoDeAula: 'Class plan',
    objetivo: 'Goal',
    conteudos: 'Contents',
    recurso: 'Resource',
    recursos: 'Resources',
    habilidades: 'Skills',
    boletimEscolar: 'School report',
    totalDeFaltas: 'Total absences',
    frequenciaDoAluno: 'Student attendance',
    numeroDeFaltas: 'Number of absences',
    diretorEscola: 'School s director',
    secretarioEscola: 'School secretary',
    mediaAtual: 'Current average',
    rescisoes: 'Terminations',
    alunosAtivos: 'Active students',
    alunosRematriculados: 'Re-enrolled students',
    mediaAlunosTurma: 'Average Students /Class',
    quantidadeAlunosVigentes: 'Number of current students',
    brasaoDoEstado: 'State coat of arms',
    mantenedora: 'Maintainer',
    representante: 'Representative',
    mantenedoraResponsavel: 'Maintainer / Representative',
    anosFaixa: 'Years / Tracks',
    valorInicial: 'Initial value',
    valorFinal: 'Final value',
    conceito: 'Concept',
    diaLetivo: 'School days',
    cargaHoraria: 'Workload',
    estabelecimento: 'Establishment',
    cnpjEstabelecimento: 'CNPJ',
    autorizacao: 'Authorization',
    lei: 'Law',
    resultado: 'Result',
    impressos: 'Printed',
    detalhes: ' Details',
    adicionaDisciplina: 'Add discipline',
    novoItem: 'New item',
    frequenciaMedia: 'Average frequency',
    dataInicial: 'Initial date',
    dataFinal: 'Final date',
    situacoesPedagogicas: 'Pedagogical situations',
    bimestral: 'Bimonthly',
    trimestral: 'Quarterly',
    semestral: 'Semester',
    modular: 'Modular',
    dataPrimeiroVencimento: 'First due date',
    notaGeralTurma: 'Overall grade of the class',
    configuracaoAvaliacaoPadrao: 'Default assessment setup',
    dataDaAula: 'Class date',
    verParcelas: 'See parcels',
    itensServiços: 'Items and Services',
    adicionarDisciplinas: 'Add courses',
    calendarioAnual: 'Annual calendar',
    considerarDiasUteis: 'Consider business days when installments are due',
    templateComercial: 'Business template',
    estabelecimentos: 'Establishments',

    feedbacks: {
      nenhumaDisciplinaCadastrar: 'No discipline registered!',
      nenhumQuadroHorarioCadastrado: 'No timesheet registered!',
      nenhumaGradeCurricularCadastrada: 'No curriculum registered!',
      nenhumOrcamentoVinculadoMatricula: 'No budget linked to enrollment!',
      necessarioOrcamentoParaRealizarMatricula: 'In order to enroll, a linked budget is required.',
      cadastreDisciplinaGradeCurricular: 'Register at least one subject in the curriculum',
      cadastreUmQuadroHorariosAcessoDiario: 'Register the timesheet to access the diary',
      cadastreUmaGradeCurricularAcessoHorarios: 'Register the curriculum to access schedules',
      informeSomenteCasasDecimais: 'Inform only decimal places',
      contemplaNotasParciaisPeriodo: 'It includes partial grades and grades for the period',
      nenhumSistemaAvaliacaoEscolhido: 'No rating system selected.',
      sistemaAvaliacaoSemTemplate: 'Evaluation system without template.',
      nenhumaTurmaRelacionadaAnoFaixa: 'No Year related classes / Range',
      nenhumaTurmaCadastrada: 'No registered classes',
      nenhumItemAdicionado: 'No items added',
      formulaNaoConfigurada: 'Formula not configured',
      nenhumProdutoDoTipoPatrimonioCadastrado: 'No registered equity products',
      nenhumaAvaliacaoCadastradaTemplate: 'No evaluation registered',
      semAvaliacoesCadastradas: 'No evaluation registered',
      nenhumDiarioDeAulaNesteDia: 'No class diary this day',
      nenhumComentarioOuJustificativa: 'No comments or justification in this class.',
      turmaSemGradeCurricular: 'Class without registered curriculum!',
      justificativaAtualizadaSucesso: 'Justification successfully updated',
      max5colunas: 'Maximum 5 columns selected',
      disciplinaNaoTemAvaliacaoOuConfiguracoes: 'Subject has no registered evaluations or settings are missing.',
      disciplinaNaoTemTemplateFinalconfigurado: 'Subject has no final template configured',
      obrigatorioParaAdicionarDesconto: 'Required to add discount',
      naoTemTemplateFinal: 'It has no configuration',
      somaDasAvaliacoesNaoTotaliza10Pontos: 'The sum of the weights of the evaluations does not total 10.',
      precisaLancarFrequencia: 'Need to have frequency launched.',
      ultimoDiarioMes: 'There is no diary after this this month',
      primeiroDiarioMes: 'There is no diary prior to this this month',
      integrantePrecisaTerNotaLancadaParaEditarJustificativa: 'Member must have posted note to edit justification.'
    },

    navegacao: {
      pedagogico: 'Pedagogical',
      'preferencias-do-pedagogico': 'Pedagogical Preferences',
      'situacoes-pedagogicas': 'Pedagogical situations',
      'documentacao-pedagogica': 'Pedagogical Documentation',
      turmas: 'Classes',
      turnos: 'Shifts',
      'tipos-de-disciplina': 'Types of discipline',
      'areas-de-conhecimento': 'Knowledge areas',
      etapas: 'Phases',
      habilidades: 'Skills',
      'tipos-de-sala': 'Types of rooms / environments ',
      'modalidades-de-turma': 'Class modalities',
      'tipos-de-cursos': 'Types of courses',
      'series-equivalentes': 'Equivalent series',
      disciplinas: 'Subjects',
      'educacao-infantil': 'Child education',
      'ensino-fundamental': 'Elementary School',
      'ensino-medio': 'High school',
      'etapas-personalizadas': 'Custom steps',
      'ano-faixa': 'Year / Range',
      'unidades-tematicas': 'Thematic units',
      'competencias-gerais': 'General skills',
      'competencias-especificas': 'Specific skills',
      'grupos-de-avaliacao': 'Evaluation groups',
      'objetos-do-conhecimento': 'Knowledge objects',
      'campos-atuacao': 'Fields of action',
      'praticas-de-linguagem': 'Language practices',
      personalizado: 'Custom',
      matriculas: 'Enrollment',
      cursos: 'Courses',
      'sistemas-de-ensino': 'Education systems',
      'direitos-aprendizagem': 'Learning rights',
      'campos-de-experiencia': 'Fields of experience',
      'direitos-de-aprendizagem': 'Learning rights',
      'objetivos-aprendizagem-desenvolvimento': 'Learning and Development Objectives',
      'tempo-duracao-atividades': 'Activity duration time',
      'horarios-aula': 'Class schedules',
      'objetos-conhecimento': 'knowledge objects',
      'componentes-curriculares': 'Curricular components',
      'campo-atuacao-social': 'Field of Social Action',
      salas: 'Rooms / Environments',
      comercial: 'Relationship',
      'preferencias-do-comercial': 'Relationship Preferences',
      'grades-curriculares': 'Curriculum grids',
      'planos-financeiros': 'Financial plans',
      'templates-avaliacao': 'Evaluation Templates',
      'sistemas-avaliacao': 'Assessment systems',
      'dashboard-do-pedagogico': 'Pedagogical Dashboard',
      'fluxo-automacao-crm': 'Automation',
      'dashboard-do-relacionamento': 'Relationship Dashboard',
      'servicos-contratacoes': 'Services and Contracts',
      'saidas-servicos-e-itens': 'Outputs - Services and Items',
      'contratos-e-matriculas': 'Contracts and Enrollments',
      ocorrencias: 'Occurrences',
      ocorrenciasEPareceres: 'Occurrences and Opinions',
      'tipos-ocorrencia': 'Types of occurrence'
    },

    validacoes: {
      naoPodeIniciarExpressaoComOperador: 'Cannot start expression with this operator',
      avaliacaoXNaoExiste: 'Evaluation {{value}} does not exist',
      expressaoInvalida: 'Invalid expression',
      variavelXNaoExiste: 'Variable {{value}} does not exist',
      selecionarConfiguracaoDeBolsa: 'Select a bag configuration',
      selecionarConfiguracaoConvenio: 'Select an agreement setting',
      selecionarConfiguracaoDesconto: 'Select a discount setting',
      bolsaIncluida: 'Bag already included',
      convenioIncluido: 'Agreement already included',
      descontoIncluido: 'Discount already included',
      selecioneUmTipoDeCalculo: 'Select a calculation type'
    }
  },

  cadastro: {
    estabelecimento: 'Establishment',

    cliente: {
      atualizadoSucesso: 'Customer updated successfully',
      matriculaUsoInterno: 'Internal use registration',
      registroMatricula: 'Registration registration'
    },

    funcionarios: {
      habilitacoes: {
        titulo: 'Qualification Data',
        deletadoSucesso: 'Enable removed successfully',
        criadoSucesso: 'Enablement successfully added',
        registro: 'Register number',
        novo: 'New license',
        adicionar: 'Add stage and subjects',

        subHabilitacoes: {
          novo: 'New discipline',
          adicionar: 'Add discipline',
          adicionadaSucesso: 'Subject successfully added',
          removidaSucesso: 'Subject successfully removed'
        }
      }
    },

    preferencias: {
      habilitacoes: {
        nomeDaSubHabilitacao: 'Sub-enabling name',
        subHabilitacoes: {
          novo: 'New sub-enabling',
          nomeDaSubHabilitacao: 'Sub-enabling name',
          cadastradoSucesso: 'Sub-qualification successfully registered'
        }
      }
    }
  },

  historicos: {
    historicos: 'histories',
    novoHistorico: 'New History',
    resultadoPedagogico: 'Pedagogical result',
    adicionaHistorico: 'Add History',
    adicionarDisciplina: 'Add discipline',
    escrevaMensagem: 'Write the message',
    certificadoConclusao: 'Certificate of completion',
    caracteresRestantes: 'Characters remaining',
    mensagemDeTexto: 'Write the message',
    atualizadoSucesso: 'History updated successfully',
    removidoSucesso: 'History successfully removed',
    historicoAdicionadoSucesso: 'History successfully added',
    impresso: { atualizadoSucesso: 'Print updated successfully' }
  },

  comercial: {
    dashboard: {
      portal: 'Student portal'
    }
  },

  financeiro: {
    vendas: {
      orcamentos: {
        composicao: {
          cadastroErro: 'Condition Total Amount different from Budget Remaining Amount!',
          novo: 'Add - Services and Items',
          item: 'Class or item',
          condicoes: {
            descontoBolsa: 'Bag discount'
          }
        }
      },

      saidas: {
        composicao: {
          novo: 'Add class / item',
          item: 'Class or item'
        }
      }
    },
    preferencias: {
      templatesComerciais: {
        titulo: 'Business templates',
        novo: 'New business template',
        cadastradoSucesso: 'Business template successfully registered!',
        atualizadoSucesso: 'Business template successfully updated!',
        removidoSucesso: 'Business template successfully removed!'
      }
    }
  },

  configuracoes: {
    empresa: {
      autorizacao: {
        anoFaixa: {
          cadastradoSucesso: 'Year / track successfully added!',
          removidoSucesso: 'Year / track successfully removed!'
        }
      }
    }
  },

  pedagogico: {
    contratosMatriculas: {
      titulo: 'Contracts and Enrollments',
      novo: 'New contract / registration',
      cadastradoSucesso: 'Contract / enrollment successfully registered!',
      atualizadoSucesso: 'Contract /registration updated successfully!',
      removidoSucesso: 'Contract / registration removed successfully!',

      contasAReceber: {
        titulo: 'Titles',
        novo: 'New title',
        nenhumCadastro: 'No title registered',
        cadastradoSucesso: 'Title successfully registered!',
        atualizadoSucesso: 'Title updated successfully!',
        removidoSucesso: 'Title successfully removed!'
      },

      documentos: {
        titulo: 'Documents',
        novo: 'New document',
        nenhumCadastro: 'No document registered',
        impressao: 'Document printing',
        cadastradoSucesso: 'Document registered successfully!',
        atualizadoSucesso: 'Document updated successfully!',
        removidoSucesso: 'Document removed successfully!'
      },

      gruposPersonalizados: {
        titulo: 'Custom groups',
        novo: 'New custom group',
        cadastradoSucesso: 'Successfully registered personalized group!',
        atualizadoSucesso: 'Customized group successfully updated!',
        removidoSucesso: 'Custom group successfully removed!'
      },

      orcamentos: {
        titulo: 'Budgets',
        novo: 'New sales budget',
        nenhumCadastro: 'No budget registered',
        cadastradoSucesso: 'Successfully registered sales budget!',
        atualizadoSucesso: 'Sales budget updated successfully!',
        removidoSucesso: 'Sales budget successfully removed!'
      },

      turmas: {
        titulo: 'Classes',
        novo: 'New class',
        selecione: 'Select a class',
        cadastradoSucesso: 'Class successfully registered!',
        atualizadoSucesso: 'Class updated successfully!',
        removidoSucesso: 'Class removed successfully!'
      },

      vendas: {
        titulo: 'Requests',
        novo: 'New sales order',
        nenhumCadastro: 'No sales orders registered',
        cadastradoSucesso: 'Sales order successfully registered!',
        atualizadoSucesso: 'Sales order updated successfully!',
        removidoSucesso: 'Sales order successfully removed!'
      }
    },

    relatorios: {
      titulo: 'Pedagogical Documentation',
      gerarHistoricoEscolar: 'Generate school history',
      selecioneATurma: 'Select the class',
      guiaTransferencia: 'Transfer Guide',
      motivoTransferencia: 'Transfer reason',

      fichaIndividual: {
        titulo: 'Individual File',
        fichasSeremGeradas: 'Tokens to be generated',
        gerarFichaIndividual: 'Generate Individual Record',
        gerarNFichas: 'Do you really want to generate {{number}} tokens?',
        geradoSucesso: 'Tokens generated successfully!'
      },

      historicoEscolar: {
        titulo: 'School History',
        gerarHistoricoEscolarEmLote: 'Generate history in batch',
        gerarHistoricoEscolar: 'Generate History',
        gerarEmLote: 'Generate school transcripts in batch',
        historicosSeremGerados: 'Historics to be generated',
        geradoSucesso: 'Historics generated successfully!',
        gerarNHistoricos: 'Do you really want to generate {{number}} school transcripts?'
      },

      boletim: {
        titulo: 'School Report',
        gerarBoletimEscolar: 'Generate School Report',
        gerarBoletimEmLote: 'Generate School Report in batch',
        boletinsSeremGerados: 'School Reports to be generated',
        geradoSucesso: 'School Reports generated successfully!',
        gerarNBoletins: 'Do you really want to generate {{number}} school reports?'
      }
    },

    ocorrencias: {
      titulo: 'Occurrences',
      tituloCard: 'Occurrence',
      novo: 'New Occurrence',
      data: 'Occurrence date',
      responsavelOcorrencia: 'Person responsible for the occurrence',
      tipo: 'Type of occurrence',
      principaisDados: 'Main occurrence data',
      assinadoResponsavelPedagogico: 'Occurrence signed by the pedagogical responsible',
      assinadasFiltro: 'Signed Occurrences',
      criadoPor: 'Created by',
      adicionarAlunoTurma: 'Add Student / Class',
      adicionarAluno: 'Add Student',
      responsavel: 'Responsible for the Occurrence',
      adicionarResponsavel: 'New Person Responsible for the Occurrence',
      nomeResponsavel: 'Name of responsible',
      funcionarioHabilitadoOcorrencia: 'Occurrence responsible',
      funcionarioHabilitadoSucesso: 'Successfully enabled employee!',
      funcionarioDesabilitadoSucesso: 'Employee successfully disabled!',
      funcionarioAtualizadoSucesso: 'Employee successfully updated!',
      cadastradoSucesso: 'Occurrence registered successfully!',
      atualizadoSucesso: 'Occurrence updated successfully!',
      removidoSucesso: 'Occurrence successfully removed!',
      emailEnviadoSucesso: 'Email successfully sent!',
      impressao: {
        titulo: 'Occurrence',
        nomeAluno: 'Student s name:',
        tipoOcorrencia: 'Type of Occurrence:',
        descricao: 'Description:',
        orientacao: 'Guidance:',
        criadoPor: 'Created by:',
        responsavelOcorrencia: 'Person responsible for the occurrence:',
        responsavelPedagogico: 'Pedagogical Responsible',
        turma: 'Class:',
        disciplina: 'Discipline:',
        anoFaixa: 'Year / Range'
      },
      tiposOcorrencia: {
        titulo: 'Type of occurrence',
        novo: 'New type of occurrence',
        adicionarTipo: 'Add occurrence type',
        cadastradoSucesso: 'Type of occurrence successfully registered!',
        atualizadoSucesso: 'Incident type successfully updated!',
        removidoSucesso: 'Incident type successfully removed!'
      }
    },

    matriculas: {
      titulo: 'Enrollment',
      novo: 'New registration',
      cadastradoSucesso: 'Enrollment successfully registered!',
      atualizadoSucesso: 'Enrollment updated successfully!',
      removidoSucesso: 'Registration removed successfully!',
      adicionarSerieItem: 'Add Series / Product',
      itensAnoFaixa: 'Year / Range',
      totalDoContrato: 'Contract total',
      anoFaixaCondicao: 'Year/Range / Condition',
      totalValorParcela: 'Total / Installment amount',
      itensMatricula: 'Enrollment Items',
      orcamentoMatriculaCadastradoSucesso: 'Budget for enrollment successfully registered!',
      tipoContratoNumero: 'Contract Type / Number',
      dataInicioFim: 'Start date / End',
      situacaoContrato: 'Contract status',
      verParcelas: 'See parcels',
      impressaoContrato: 'Contract Print',
      visualizarImpressao: 'Print View',
      finalizar: 'Finish',
      vendedorDoOrcamento: 'Budget seller',
      matriculasCanceladas: 'Canceled enrollments',
      matriculasEncerradas: 'Enrollments closed',
      preMatriculas: 'Pre-enrollment',
      matriculasRescindidas: 'Terminated enrollments',
      matriculasTrancadas: 'Enrollment locked',
      matriculasAtivas: 'Active enrollments',
      matriculasPendentes: 'Pending enrollment',
      proximo: 'Next',
      adicionarTurma: 'Add Class',
      turmaExistenteNaMatricula: 'The selected class is already included in the registration',
      gerarTitulos: 'Generate Titles',
      nenhumOrcamentoVinculadoMatricula: 'No budget linked to enrollment!',

      itens: {
        adicionarAnoFaixaItem: 'Add Year/Range - Item',
        editarAnoFaixaItem: 'Edit Year/Range - Item',
        selecionarAnoFaixaItem: 'Select Year/Range - Items',
        selecioneUmAnoFaixaItem: 'Select a Year/Range - Item',
        selecioneUmaTurma: 'Select a class',
        itens: 'Items',
        adicionadoSucesso: 'Composition successfully added',
        atualizadoSucesso: 'Composition successfully updated',
        deletadoSucesso: 'Composition successfully deleted',
        subTotalItem: 'Item subtotal',
        subTotalItemCondicoes: 'Subtotal with conditions',
        gerenciarCondicoes: 'Subtotal with conditions',
        efetivar: 'Effectuate',
        pedidoGeradoSucesso: 'Request generated successfully!',
        resumoMatricula: 'Enrollment Summary',
        itemResponsavelFinanceiro: 'Item / Financial Responsible',
        totalCondicao: 'Total / Condition',
        itemSemEmbalagem: 'The item does not have any packaging',
        selecioneUmItemAnoFaixa: 'Select a Year/Range item to load the financial plans',
        isentarItem: 'Exempt item',
        justificativaIsencaoItem: 'Justification for item exemption',

        condicoes: {
          titulo: 'Payment conditions',
          novo: 'Add Condition',
          atualizadoSucesso: 'Payment Terms updated successfully!',
          semDados: 'Item with no payment terms',
          semDadosDescricao: 'Add at least one payment term for the item.',
          sessionBolsa: 'Bag discount',
          sessionDesconto: 'Punctuality discount',
          sessionConvenios: 'Insurance discount',
          sessionDescontoAvulso: 'Single discount',
          configuracaoConvenio: 'Agreement configuration',
          configuracaoDesconto: 'Discount setup'
        }
      },

      documentoImpressao: {
        impressaoDocumentos: 'Printing documents',
        modeloDeDocumentos: 'Document template',
        documentoSalvoSucesso: 'Document saved successfully'
      }
    },

    etapas: {
      educacaoInfantil: {
        camposDeExperiencia: {
          titulo: 'Fields of experience',
          novo: 'New field of experience',
          cadastradoSucesso: 'Field of experience successfully registered!',
          atualizadoSucesso: 'Experience field successfully updated!',
          removidoSucesso: 'Experience field successfully removed!'
        },

        direitosDeAprendizagem: {
          titulo: 'Learning rights',
          novo: 'New learning right',
          cadastradoSucesso: 'Successfully registered learning right!',
          atualizadoSucesso: 'Learning right updated successfully!',
          removidoSucesso: 'Learning right successfully removed!'
        },

        objetivosAprendizagemDesenvolvimento: {
          titulo: 'Learning Objectives',
          novo: 'New Learning and Development Goal',
          cadastradoSucesso: 'Learning and development objective successfully registered!',
          atualizadoSucesso: 'Successfully updated learning and development objective!',
          removidoSucesso: 'Learning and development objective successfully removed!'
        }
      },

      ensinoFundamental: {
        habilidades: {
          titulo: 'Elementary school skills',
          novo: 'New Elementary School Skill',
          cadastradoSucesso: 'Elementary school skill successfully registered!',
          atualizadoSucesso: 'Elementary school skill successfully updated!',
          removidoSucesso: 'Elementary school skill successfully removed!'
        },

        unidadesTematicas: {
          titulo: 'Thematic units',
          novo: 'New thematic unit',
          cadastradoSucesso: 'Thematic unit successfully registered!',
          atualizadoSucesso: 'Thematic unit successfully updated!',
          removidoSucesso: 'Thematic unit successfully removed!',
          habilidades: {
            adicionar: 'Add new skill',
            novo: 'New Skill',
            cadastradoSucesso: 'Skill added successfully!',
            atualizadoSucesso: 'Skill updated successfully!',
            removidoSucesso: 'Skill successfully removed!'
          }
        },

        objetosConhecimento: {
          titulo: 'knowledge objects',
          novo: 'New Knowledge Object',
          cadastradoSucesso: 'Knowledge object successfully registered!',
          atualizadoSucesso: 'Knowledge object updated successfully!',
          removidoSucesso: 'Knowledge object successfully removed!',

          habilidades: {
            adicionar: 'Add Skill',
            novo: 'New Skill',
            cadastradoSucesso: 'Skill added successfully!',
            atualizadoSucesso: 'Skill updated successfully!',
            removidoSucesso: 'skill successfully removed!'
          }
        },

        praticasLinguagem: {
          titulo: 'Language practices',
          novo: 'New Language Practice',
          cadastradoSucesso: 'Successfully registered language practice!',
          atualizadoSucesso: 'Successfully updated language practice!',
          removidoSucesso: 'Language practice successfully removed!',

          habilidades: {
            adicionar: 'Add skill',
            novo: 'New skill',
            cadastradoSucesso: 'Skill added successfully!',
            atualizadoSucesso: 'Skill updated successfully!',
            removidoSucesso: 'skill successfully removed!'
          }
        }
      },
      ensinoMedio: {
        camposAtuacao: {
          titulo: 'Fields of action',
          novo: 'New Field of Activity',
          cadastradoSucesso: 'Field of action successfully registered!',
          atualizadoSucesso: 'Field of action successfully updated!',
          removidoSucesso: 'Field of action successfully removed!',
          habilidades: {
            adicionar: 'Add skill',
            novo: 'New skill',
            cadastradoSucesso: 'Skill added successfully!',
            atualizadoSucesso: 'Skill updated successfully!',
            removidoSucesso: 'Skill successfully removed!'
          }
        },

        habilidades: {
          titulo: 'High school skills',
          novo: 'New High School Skill',
          cadastradoSucesso: 'High school skill successfully registered!',
          atualizadoSucesso: 'High school skill successfully updated!',
          removidoSucesso: 'High school skill successfully removed!',
          anosFaixas: {
            adicionar: 'Add Year / Range',
            novo: 'New Year / track',
            cadastradoSucesso: 'Year / track successfully added!',
            atualizadoSucesso: 'Year / track successfully updated!',
            removidoSucesso: 'Year / track successfully removed!'
          }
        }
      },

      personalizadas: {
        etapas: {
          titulo: 'Custom steps',
          novo: 'New custom step',
          cadastradoSucesso: 'Custom step successfully registered!',
          atualizadoSucesso: 'Custom step successfully updated!',
          removidoSucesso: 'Custom step successfully removed!'
        }
      }
    },

    preferencias: {
      conteudo: {
        novo: 'New content',
        cadastradoSucesso: 'Content successfully registered!',
        descricaoDoConteudo: 'Content description'
      },

      sistemasAvaliacao: {
        titulo: 'Evaluation systems',
        novo: 'New Rating System',
        cadastradoSucesso: 'Evaluation system successfully registered!',
        atualizadoSucesso: 'Rating system successfully updated!',
        removidoSucesso: 'Rating system successfully removed!',
        substituiMenorNotaParcialPelaNotaRecuperacao:
          'Replaces the lowest partial grade defined as ‘UsaRec’ with the recovery grade',
        substituiMediaNotaParcialPelaNotaRecuperacao:
          'Replaces the average of the partial grades defined as ‘UsaRec’ with the recovery grade',
        explicacaoUsaRec: `‘UsaRec’ -> Option that defines the ratings that will be affected by the partial recovery score,
        defined in the evaluation register.`,
        pesoPeriodo: 'Weight period {{period}}',
        pesoMaximo: 'Maximum weight',
        pontuacaoMaximaAnual: 'No. annual maximum score',
        numeroModulos: 'No of modules',
        considerarHabilidades: 'Consider skills',
        sistemaDeAvaliacaoFinal: 'Final assessment system',
        periodoTipoPontuacao: 'Period and Type of Score',
        configuracaoAvaliacaoFinal: 'Setting final grades',
        configuracaoAvaliacao: 'Assessment setup',

        conceitos: {
          novo: 'New concept',
          atualizadoSucesso: 'Concept updated successfully',
          cadastradoSucesso: 'Concept successfully registered!',
          removidoSucesso: 'Concept removed successfully!',
          titulo: 'Concept',
          editar: 'Edit Concept',
          valorEquivalente: 'Equivalent value',
          valorInicial: 'Start value',
          valorFinal: 'Final value'
        },

        arredondamentos: {
          medias: {
            cadastradoSucesso: 'Rounding of averages successfully registered!',
            atualizadoSucesso: 'Rounding of averages updated successfully!'
          },

          mediasParciais: {
            cadastradoSucesso: 'Rounding of partial averages successfully registered!',
            atualizadoSucesso: 'Rounding of partial averages successfully updated!'
          },

          notas: {
            cadastradoSucesso: 'Rounding of notes successfully registered!',
            atualizadoSucesso: 'Rounding of notes successfully updated!'
          },

          recuperacoes: {
            cadastradoSucesso: 'Rounding of recoveries registered successfully!',
            atualizadoSucesso: 'Rounding of recoveries updated successfully!'
          }
        }
      },

      sistemasEnsino: {
        titulo: 'Education systems',
        novo: 'New education system',
        cadastradoSucesso: 'Successfully registered education system!',
        atualizadoSucesso: 'Education system successfully updated!',
        removidoSucesso: 'Education system successfully removed!'
      },

      modelosAvaliacao: {
        titulo: 'Evaluation models',
        novo: 'New assessment model',
        editar: 'Edit assessment model',
        cadastradoSucesso: 'Successfully registered evaluation model!',
        atualizadoSucesso: 'Evaluation model successfully updated!',
        removidoSucesso: 'Evaluation model successfully removed!',

        avaliacoes: {
          novo: 'New assessment',
          editar: 'Edit review',
          cadastradoSucesso: 'Evaluation registered successfully!',
          atualizadoSucesso: 'Evaluation updated successfully!',
          removidoSucesso: 'Assessment successfully removed!',

          periodos: {
            novo: 'New period',
            editar: 'Edit period',
            cadastradoSucesso: 'Successfully registered period!',
            atualizadoSucesso: 'Period updated successfully!',
            removidoSucesso: 'Period successfully removed!'
          },

          tiposAvaliacoes: {
            novo: 'New Type of Assessment',
            titulo: 'Types of assessment',
            cadastradoSucesso: 'Type of assessment successfully registered!',
            atualizadoSucesso: 'Assessment Type successfully updated!',
            removidoSucesso: 'Evaluation type successfully removed!',
            conceitos: {
              titulo: 'Type of evaluation concept',
              novo: 'New type of concept evaluation',
              cadastradoSucesso: 'Type of assessment concept successfully registered!',
              atualizadoSucesso: 'Assessment Type Concept Updated Successfully!',
              removidoSucesso: 'Assessment Type Concept Removed Successfully!',
              editar: 'Edit Concept Assessment Type'
            },
            habilidades: {
              titulo: 'Skill Assessment Type',
              cadastradoSucesso: 'Type of skill assessment successfully registered!',
              atualizadoSucesso: 'Skill Assessment Type successfully updated!',
              removidoSucesso: 'Skill assessment type successfully removed!'
            }
          }
        },

        formulas: {
          novo: 'New formula',
          editar: 'Edit formula',
          cadastradoSucesso: 'Formula registered successfully!',
          atualizadoSucesso: 'Formula updated successfully!',
          removidoSucesso: 'Formula successfully removed!',
          formulaSituacaoPedagogica: 'Pedagogical Situation Formula',
          exemploDeFormula: 'Formula example:',
          comPeso: 'With weight',
          semPeso: 'No weight',
          replicarFormulaParaTodosPeriodos: 'Replicate formula for all periods',

          periodos: {
            novo: 'New period',
            editar: 'Edit period',
            cadastradoSucesso: 'Successfully registered period!',
            atualizadoSucesso: 'Period updated successfully!',
            removidoSucesso: 'Period successfully removed!'
          }
        }
      },

      componentesCurriculares: {
        anosFaixas: {
          titulo: 'Years / Ranges',
          novo: 'New Year/Sash',
          cadastradoSucesso: 'Year / Track successfully registered!',
          atualizadoSucesso: 'Year / Range successfully updated!',
          removidoSucesso: 'Year/Range successfully removed!'
        },

        disciplinas: {
          titulo: 'Subjects',
          novo: 'New discipline',
          cadastradoSucesso: 'Subject successfully registered!',
          atualizadoSucesso: 'Subject successfully updated!',
          removidoSucesso: 'Subject successfully removed!'
        }
      },

      gradesCurriculares: {
        titulo: 'Curriculum grids',
        novo: 'New Curriculum Grid',
        cadastradoSucesso: 'Curriculum successfully registered!',
        atualizadoSucesso: 'Curriculum grid successfully updated!',
        removidoSucesso: 'Curricular grid successfully removed!',

        disciplinas: {
          titulo: 'Discipline',
          novo: 'New discipline',
          cadastradoSucesso: 'Subject successfully registered!',
          atualizadoSucesso: 'Subject successfully updated!',
          removidoSucesso: 'Subject successfully removed!',

          aula: {
            novo: 'New class',
            cadastradoSucesso: 'Class successfully registered!',
            atualizadoSucesso: 'Class updated successfully!',
            removidoSucesso: 'class successfully removed!',
            numeroDaAula: 'Class number',
            dataDaAula: 'Class date',

            conteudo: {
              novo: 'New content',
              cadastradoSucesso: 'Content successfully registered!',
              atualizadoSucesso: 'Content successfully updated!',
              removidoSucesso: 'Content successfully removed!',
              selecioneAvaliacaoTemplateParcial: 'Select a partial assessment',
              selecioneAvaliacaoTemplateFinal: 'Select a final assessment',
              avaliacoesTemplateParcial: 'Partial evaluations',
              avaliacoesTemplateFinal: 'Final evaluations',
              avaliacoesDoConteudo: 'Content Reviews',
              avaliacaoRemovidaComSucesso: 'Assessment successfully removed',
              avaliacaoAdicionadaComSucesso: 'Rating successfully added'
            },

            recurso: {
              novo: 'New feature',
              cadastradoSucesso: 'Resource registered successfully!',
              atualizadoSucesso: 'Feature updated successfully!',
              removidoSucesso: 'Resource successfully removed!'
            },

            habilidade: {
              cadastradoSucesso: 'Skill successfully registered!',
              removidoSucesso: 'Skill successfully removed!'
            }
          },

          metodologias: {
            tecnicasEnsino: {
              titulo: 'Teaching techniques',
              novo: 'New teaching technique',
              cadastradoSucesso: 'Teaching technique successfully registered!',
              atualizadoSucesso: 'Teaching technique successfully updated!',
              removidoSucesso: 'teaching technique successfully removed!'
            },

            recursosDidaticos: {
              titulo: 'Didactic resources',
              novo: 'New teaching resource',
              cadastradoSucesso: 'Teaching resource successfully registered!',
              atualizadoSucesso: 'Teaching resource successfully updated!',
              removidoSucesso: 'Teaching resource successfully removed!'
            },

            criteriosAvaliacao: {
              titulo: 'Rating criteria',
              novo: 'New Evaluation Criteria',
              cadastradoSucesso: 'Successfully registered evaluation criteria!',
              atualizadoSucesso: 'Assessment criteria updated successfully!',
              removidoSucesso: 'Evaluation criteria successfully removed!'
            },

            atividadesPraticas: {
              titulo: 'Practical activities',
              novo: 'New practical activity',
              cadastradoSucesso: 'Successfully registered practical activity!',
              atualizadoSucesso: 'Successfully updated practical activity!',
              removidoSucesso: 'Practical activity successfully removed!'
            },

            bibliografiaBasica: {
              titulo: 'Basic bibliography',
              novo: 'New Basic Bibliography',
              cadastradoSucesso: 'Basic bibliography successfully registered!',
              atualizadoSucesso: 'Basic bibliography successfully updated!',
              removidoSucesso: 'Basic bibliography successfully removed!'
            },

            bibliografiaComplementar: {
              titulo: 'Complementary Bibliography',
              novo: 'New Complementary Bibliography',
              cadastradoSucesso: 'Complementary bibliography successfully registered!',
              atualizadoSucesso: 'Complementary bibliography successfully updated!',
              removidoSucesso: 'Complementary bibliography successfully removed!'
            }
          },

          planosDeEnsino: {
            cadastradoSucesso: 'Teaching plan successfully registered!',
            atualizadoSucesso: 'Teaching plan successfully updated!',
            objetivosGerais: 'General objectivess',
            bibliografia: {
              novo: 'New bibliography',
              editar: 'Edit bibliography',
              cadastradoSucesso: 'Bibliography successfully registered!',
              atualizadoSucesso: 'Bibliography successfully updated!',
              removidoSucesso: 'Bibliography successfully removed!',
              novaBibliografia: 'New bibliography',
              tipoBibliografia: 'Bibliography type'
            },
            conteudoProgramatico: {
              novo: 'New syllabus',
              editar: 'Edit syllabus',
              cadastradoSucesso: 'Syllabus successfully registered!',
              atualizadoSucesso: 'Content successfully updated!',
              removidoSucesso: 'Content successfully removed!',
              titulo: 'Program content',
              validacaoPeriodoSelecionado: 'You need to select a period',
              periodoIncluido: 'Period already added',
              adicionarPeriodo: 'Add period'
            },
            ementa: {
              novo: 'New menu',
              editar: 'Edit menu',
              cadastradoSuceeso: 'Menu successfully registered!',
              atualizadoSucesso: 'Successfully updated menu!',
              removidoSucesso: 'Menu removed successfully!',
              titulo: 'Menu'
            },
            formaAvaliacao: {
              novo: 'New form of assessment',
              editar: 'Edit form of evaluation',
              cadastradoSucesso: 'Successfully registered evaluation form!',
              atualizadoSucesso: 'Successfully updated assessment form!',
              removidoSucesso: 'Evaluation form successfully removed!',
              titulo: 'Forms of evaluation'
            }
          },

          suplementar: {
            comentarios: {
              titulo: 'Comments',
              novo: 'New Comment',
              cadastradoSucesso: 'Comment registered successfully!',
              atualizadoSucesso: 'Comment updated successfully!',
              removidoSucesso: 'Comment removed successfully!',
              adicionar: 'Add comments'
            },

            possibilidadesParaOCurriculo: {
              titulo: 'Possibilities for the curriculum',
              novo: 'New possibility',
              cadastradoSucesso: 'Possibility successfully registered!',
              atualizadoSucesso: 'Possibility updated successfully!',
              removidoSucesso: 'Possibility successfully removed!',
              adicionar: 'Add possibilities'
            }
          },

          configuracoes: {
            camposDeAtuacao: {
              titulo: 'Fields of action',
              nome: 'Field of action',
              novo: 'New field of action',
              cadastradoSucesso: 'Field of action successfully registered!',
              atualizadoSucesso: 'Field of action successfully updated!',
              removidoSucesso: 'Field of action successfully removed!',
              adicionar: 'Add fields of action'
            },

            objetosDoConhecimento: {
              titulo: 'knowledge objects',
              nome: 'Object of knowledge',
              novo: 'New object of knowledge',
              cadastradoSucesso: 'Knowledge object successfully registered!',
              atualizadoSucesso: 'Knowledge object updated successfully!',
              removidoSucesso: 'Knowledge object successfully removed!',
              adicionar: 'Add knowledge objects'
            },

            praticasDeLinguagem: {
              titulo: 'Language practices',
              nome: 'Language practice',
              novo: 'New language practice',
              cadastradoSucesso: 'Language practice successfully registered!',
              atualizadoSucesso: 'Successfully updated language practice!',
              removidoSucesso: 'Language practice successfully removed!',
              adicionar: 'Add language practices'
            },

            unidadesTematicas: {
              titulo: 'Thematic units',
              nome: 'Thematic unit',
              novo: 'New thematic unit',
              cadastradoSucesso: 'Thematic unit successfully registered!',
              atualizadoSucesso: 'Thematic unit successfully updated!',
              removidoSucesso: 'Thematic unit successfully removed!',
              adicionar: 'Add thematic units'
            }
          }
        }
      },

      areasDeConhecimento: {
        titulo: 'Knowledge areas',
        novo: 'New knowledge area',
        cadastradoSucesso: 'Knowledge area successfully registered!',
        atualizadoSucesso: 'Knowledge area successfully updated!',
        removidoSucesso: 'Knowledge area successfully removed!'
      },

      competenciasEspecificas: {
        titulo: 'Specific skills',
        novo: 'New specific competence',
        cadastradoSucesso: 'Specific competence successfully registered!',
        atualizadoSucesso: 'Specific competency successfully updated!',
        removidoSucesso: 'Specific competency successfully removed!'
      },

      competenciasGerais: {
        titulo: 'General skills',
        novo: 'New general competence',
        cadastradoSucesso: 'General competence successfully registered!',
        atualizadoSucesso: 'Overall competency successfully updated!',
        removidoSucesso: 'Overall competency successfully removed!'
      },

      gruposDeAvaliacao: {
        titulo: 'Evaluation groups',
        novo: 'New assessment group',
        cadastradoSucesso: 'Successfully registered evaluation group!',
        atualizadoSucesso: 'Evaluation group updated successfully!',
        removidoSucesso: 'Evaluation group successfully removed!',
        adicionar: 'Add assessment groups'
      },

      cursos: {
        titulo: 'Courses',
        novo: 'New course',
        cadastradoSucesso: 'Course registered successfully!',
        atualizadoSucesso: 'Course updated successfully!',
        removidoSucesso: 'Course successfully removed!'
      },

      modalidadesDeTurma: {
        titulo: 'Class modalities',
        novo: 'New class modality',
        cadastradoSucesso: 'Type of successfully registered class!',
        atualizadoSucesso: 'Class modality successfully updated!',
        removidoSucesso: 'Class modality successfully removed!'
      },

      tiposDeSala: {
        titulo: 'Room types / Environment',
        novo: 'New room type',
        cadastradoSucesso: 'Type of room successfully registered!',
        atualizadoSucesso: 'Room type successfully updated!',
        removidoSucesso: 'Room type successfully removed!'
      },

      tiposDeDisciplina: {
        titulo: 'Types of discipline',
        novo: 'New type of discipline',
        cadastradoSucesso: 'Type of subject successfully registered!',
        atualizadoSucesso: 'Subject type updated successfully!',
        removidoSucesso: 'Subject type successfully removed!'
      },

      sistemasDeEnsino: {
        titulo: 'education systems',
        novo: 'New education system',
        cadastradoSucesso: 'Successfully registered education system!',
        atualizadoSucesso: 'Education system successfully updated!',
        removidoSucesso: 'Education system successfully removed!'
      },

      situacoesPedagogicas: {
        titulo: 'Pedagogical situations',
        novo: 'New teaching situation',
        cadastradoSucesso: 'Pedagogical situation successfully registered!',
        atualizadoSucesso: 'Pedagogical situation updated successfully!',
        removidoSucesso: 'Pedagogical situation successfully removed!'
      },

      turnos: {
        titulo: 'Shifts',
        novo: 'New shift',
        cadastradoSucesso: 'Shift registered successfully!',
        atualizadoSucesso: 'Shift updated successfully!',
        removidoSucesso: 'Shift removed successfully!'
      },

      salas: {
        titulo: 'Rooms / environments',
        novo: 'New room / environment',
        cadastradoSucesso: 'Room / environment successfully registered!',
        atualizadoSucesso: 'Room / environment successfully updated!',
        removidoSucesso: 'Room / environment successfully removed!',
        equipamentos: {
          titulo: 'Equipments',
          selecionar: 'Select Equipment',
          novo: 'New Equipment',
          editar: 'Edit Equipment',
          cadastradoSucesso: 'Equipment successfully registered!',
          atualizadoSucesso: 'Equipment successfully updated!',
          removidoSucesso: 'Equipment successfully removed!'
        }
      },

      horariosAula: {
        titulo: 'Class schedules',
        horarioDasAulas: 'Class schedules',
        novo: 'New class schedule',
        editar: 'Edit class schedule',
        cadastradoSucesso: 'Class schedule successfully registered!',
        atualizadoSucesso: 'Class schedule successfully updated!',
        removidoSucesso: 'Class schedule successfully removed!',
        aulaAtualizadaSucesso: 'Class updated successfully!'
      }
    },

    turmas: {
      titulo: 'Classes',
      novo: 'New class',
      cadastradoSucesso: 'Class successfully registered!',
      atualizadoSucesso: 'Class updated successfully!',
      removidoSucesso: 'Class removed successfully!',
      dados: 'Class Data',
      nomeTurma: 'Class name',
      ordemSigla: 'Order / Acronym',
      tagsTurma: 'Class tags',
      removerTurma: 'Remove class',
      alterarGrade: 'When changing the curriculum, all previously recorded information will be lost.',
      turmasCanceladas: 'Canceled classes',
      turmasEmAndamento: 'Ongoing classes',
      turmasEmFormacao: 'Classes in formation',
      turmasEncerradas: 'Closed classes',
      disciplinaDaTurma: 'Class subject',
      situacoesTurma: {
        emAndamento: 'In progress',
        emFormacao: 'Training',
        cancelada: 'Canceled',
        encerrada: 'Closed'
      },
      equipePedagogica: {
        novo: 'New member',
        atualizadoSucesso: 'Member updated successfully',
        cadastradoSucesso: 'Member successfully registered!',
        removidoSucesso: 'Member successfully removed!',
        titulo: 'Pedagogical Team',
        editar: 'Edit member'
      },

      integrantes: {
        titulo: 'Members',
        novo: 'New member',
        adicionar: 'Add member',
        jaCadastrado: 'Member already added',
        cadastradoSucesso: 'Member successfully registered!',
        atualizadoSucesso: 'Member updated successfully!',
        removidoSucesso: 'Member successfully removed!'
      },

      horarios: {
        titulo: 'Timesheet',
        tipo: 'Type of time',
        imprimirQuadroDeHorarios: 'Print timesheet',
        cadastradoSucesso: 'Class schedule successfully registered!',
        atualizadoSucesso: 'Class schedule updated successfully!',
        removidoSucesso: 'Class schedule successfully removed!',
        aulaRemovidaSucesso: 'Class successfully removed!',
        aulaAtualizadaSucesso: 'Class updated successfully!',
        tipoDeFalta: 'Type of absence',
        tipoDeFaltaPorDia: 'Per day',
        tipoDeFaltaPorDisciplina: 'By discipline',
        integrantes: 'Members',
        atualizarRecorrente: 'Replicate changes for subsequent classes?',
        removerRecorrente: 'Also remove following classes?',
        gerarRecorrente: 'Recurring class',
        aulaRecorrente: 'Recurring class',
        aulaUnica: 'Single class',
        editarAula: 'Edit class',
        todosRegistrosPosteriores: 'Update all later records',
        aulaSelecionada: 'Selected classes',
        selecionarDias: 'Select days',
        seusHorariosAula: 'Your class schedules',
        horariosTurma: 'Class schedules',
        mapaHorarios: 'Timetable',
        tipoMapaHorarios: 'Type of timetable',
        opcoesEdicao: 'Editing options',
        atualizarTodosRegistrosPosteriores: 'Update all subsequent records',
        calendarioAulas: 'Class schedule',
        cadastrarAula: 'Register class',
        selecionarOutro: 'Select other',
        selecionarUsuario: 'Select user',
        confirmarAula: 'Confirm class',
        pesquisarPorAluno: 'Search by student',
        marcarPresenca: 'Check in',
        detalhesPresenca: 'Presence Details',
        xAulasHoje: '{{value}} classes for today',
        justificarFalta: 'Justify absence',
        justificarTodasFaltas: 'Justify all absences',
        faltaJustificada: 'Justified absence',
        descrevaJustificativa: 'Describe the justification',
        removerJustificativa: 'Remove justification',
        presencaParcial: 'Partial presence',
        contemXAulasSemPresenca: 'Contains {{value}} classes without presence',
        contemXAulasFaltaJustificada: 'Contains {{value}} justified absences',
        registrarComportamento: 'Record behavior',
        notasAvaliacoes: 'Grades and evaluations',
        horariosAluno: 'Student hours',
        gerarMapa: 'Generate map',
        selecionarTurma: 'Select class',
        selecionarProfessor: 'Select teacher'
      },

      diariosAula: {
        aulaNaoPossuiIntegrantes: 'This class has no members.!',
        titulo: 'Class diaries',
        novo: 'New class diary',
        cadastradoSucesso: 'Class diary successfully registered!',
        atualizadoSucesso: 'Class diary successfully updated!',
        removidoSucesso: 'Class diary successfully removed!',
        alunoNaoParticipaDestaDisciplina: 'Student does not participate in this discipline',
        controleFrequencia: 'Frequency Control',
        pesquisarAlunos: 'Search students',
        alunoAtualizadoComSucesso: 'Student updated successfully',
        problemaAtualizarAluno: 'Problem update student',
        conteudoPendente: 'Pending content',
        conteudoLancado: 'Content released',
        aulaConfirmada: 'Class confirmed',
        presenca: 'Presence',
        presencaMedianteReposicao: 'Presence upon replacement',
        presencaJustificada: 'Justified presence',
        justificativa: 'Justification',
        visualizar: 'To view',
        falta: 'Lack',
        nomeDisciplina: 'Subject name',
        acoesDiarioAula: 'Class Diary Actions',
        aulaNaoLancada: 'Class not launched',
        atualizarFrequencia: 'Update frequencies',
        atualizarConteudo: 'Update content',
        naoTemAulasNessePeriodo: 'There are no classes during this period',

        conteudos: {
          cadastradoSucesso: 'Class content successfully registered!',
          atualizadoSucesso: 'Lesson content successfully updated!'
        }
      },

      notas: {
        titulo: 'Grades',
        cadastradoSucesso: 'Note successfully registered!',
        atualizadoSucesso: 'Note updated successfully!',
        removidoSucesso: 'Note successfully removed!',
        selecioneUmaDisciplina: 'Select a discipline',
        colunas: 'Columns',
        filtroDeNotas: 'Notes filter',
        notaInicial: 'Opening note',
        notaFinal: 'Final grade',
        relacioneUm: 'List one ',
        naAba: ' on the tab ',
        paraHabilitarLancamentoNota: ' to enable note posting'
      }
    }
  },

  agenda: {
    preferencias: {
      tempoDuracaoAtividades: {
        titulo: 'Duration time of activities',
        novo: 'New time',
        cadastradoSucesso: 'Time registered successfully!',
        atualizadoSucesso: 'Weather updated successfully!',
        removidoSucesso: 'Time removed successfully!'
      }
    }
  },

  dashboard: {
    categorias: {
      ultimos12Meses: 'Last 12 months',
      ultimoMes: 'Last month',
      ultimaSemana: 'Last week',
      ultimoDia: 'Last day',
      semAgendamentosPorStatus: 'No appointments in the last 12 months',
      agendamentosPorStatus: 'Schedules by status (last 12 months)'
    },
    resumoFinanceiro: {
      hoje: 'Financial summary (today)'
    }
  }
}
