"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SptSnackbar = exports.SptSnackbarStyled = exports.SptSnackbarVariants = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _tools = require("@sponte/lib-utils/dist/theme/tools");

var _Flex = require("../../elements/Flex");

var _Text = require("../../elements/Text");

var _Icon = require("../Icon");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  max-width: 550px;\n  min-height: 40px;\n\n  ", ";\n\n  span {\n    word-break: break-word;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    background-color: ", ";\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    background-color: ", ";\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    background-color: ", ";\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    background-color: ", ";\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var SptSnackbarVariants = {
  error: (0, _styledComponents.css)(_templateObject(), (0, _tools.theme)('colors.error')),
  success: (0, _styledComponents.css)(_templateObject2(), (0, _tools.theme)('colors.success')),
  warning: (0, _styledComponents.css)(_templateObject3(), (0, _tools.theme)('colors.warning')),
  info: (0, _styledComponents.css)(_templateObject4(), (0, _tools.theme)('colors.info'))
};
exports.SptSnackbarVariants = SptSnackbarVariants;
var SptSnackbarStyled = (0, _styledComponents.default)(_Flex.SptFlex)(_templateObject5(), (0, _tools.switchProp)('variant', SptSnackbarVariants));
exports.SptSnackbarStyled = SptSnackbarStyled;
SptSnackbarStyled.displayName = 'SptSnackbarStyled';
var SptSnackbar = (0, _react.memo)(function (_ref) {
  var data = _ref.data,
      timeout = _ref.timeout,
      children = _ref.children,
      dismissable = _ref.dismissable,
      message = _ref.message,
      onDismiss = _ref.onDismiss,
      sticky = _ref.sticky,
      position = _ref.position,
      themeVariant = _ref.theme,
      props = _objectWithoutProperties(_ref, ["data", "timeout", "children", "dismissable", "message", "onDismiss", "sticky", "position", "theme"]);

  var content = (0, _react.useMemo)(function () {
    if (children) {
      return children;
    }

    return _react.default.createElement(_Text.SptText, {
      textAlign: "justify",
      color: "white",
      fontWeight: "bold"
    }, message);
  }, [children, message]);
  var icon = (0, _react.useMemo)(function () {
    if (sticky || dismissable) {
      return _react.default.createElement(_Flex.SptFlex, null, _react.default.createElement(_Icon.SptIcon, {
        ml: 6,
        color: "white",
        fontSize: "medium",
        onClick: onDismiss
      }, "spt-close"));
    }

    return null;
  }, [sticky, dismissable, onDismiss]);
  return _react.default.createElement(_Flex.SptFlex, {
    width: "100vw"
  }, _react.default.createElement(_Flex.SptFlex, {
    pr: "15px",
    width: "100vw",
    justifyContent: "center",
    position: position
  }, _react.default.createElement(SptSnackbarStyled, _extends({
    py: 4,
    pl: 10,
    pr: dismissable || sticky ? 6 : 10,
    mb: 5,
    "data-testid": "snackbar-".concat(themeVariant),
    variant: themeVariant,
    dismissable: dismissable,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "sm",
    bg: "mediumGrey"
  }, props), content, icon)));
});
exports.SptSnackbar = SptSnackbar;
SptSnackbar.displayName = 'SptSnackbar';
SptSnackbar.propTypes = {
  children: _propTypes.default.node,
  timeout: _propTypes.default.number,
  dismissable: _propTypes.default.bool,
  message: _propTypes.default.string,
  onDismiss: _propTypes.default.func,
  sticky: _propTypes.default.bool
};
SptSnackbar.defaultProps = {};