"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  geral: {
    app: 'Sponte',
    currency: 'R$',
    percent: '%',
    currency_code: 'EN',
    date_mask: 'DD/MM/YYYY',
    date_format_describe: 'dd/mm/aaaa',
    time_mask: 'HH:mm:ss',
    time_format_describe: '0:00h',
    datetime_mask: '$t(geral:date_mask) $t(geral:time_mask)',
    currency_formatter: '$t(geral:currency) {{value, currency|BRL}}',
    percent_formatter: '{{value, percent|BRL}}',
    date_formatter: '{{date, date|DD/MM/YYYY}}',
    time_formatter: '{{date, date|HH:mm}}',
    time_with_seconds_formatter: '{{date, date|HH:mm:ss}}',
    datetime_formatter: '{{date, date|DD/MM/YYYY HH:mm:ss}}',
    numberRange_formatter: '{{start}} de {{end}}',
    atuaisNovas: 'current and new',
    apenasNovas: 'just new',
    salvar: 'To save',
    continuar: 'Continue',
    cancelar: 'Cancel',
    remover: 'to remove',
    removerCadastro: 'Remove registration',
    desejaRemover: 'Do you really want to remove?',
    desejaEstornar: 'Do you really want to reverse?',
    estornar: 'Reverse',
    desejaGerarTitulos: 'Do you really want to generate titles?',
    naoReceberSms: 'I don t want to receive SMS',
    naoReceberEmail: 'I dont want to receive email',
    tentarNovamente: 'Try again',
    novo: 'New',
    nova: 'New',
    cpf: 'CPF',
    cnpj: 'CNPJ',
    rg: 'RG',
    registro: 'Record',
    registroGeral: 'general registry',
    certidaoDeNascimento: 'Birth certificate',
    numeroRegistroCertidaoNascimento: 'Register number',
    cartorioRegistroCertidaoNascimento: 'Registry Office',
    livroRegistroCertidaoNascimento: 'log book',
    folhaRegistroCertidaoNascimento: 'record sheet',
    termoRegistroCertidaoNascimento: 'registration term',
    dataRegistroCertidaoNascimento: 'Registration Date',
    carteiraTrabalho: 'work card',
    nome: 'Name',
    tipo: 'Type',
    nomeTipo: '$t(geral:nome) / $t(geral:tipo)',
    nomeCodigo: '$t(geral:nome) / $t(geral:codigo)',
    pessoa: 'people',
    sim: 'Yes',
    nao: 'Not',
    fisica: 'Physics',
    juridica: 'Legal',
    pessoaFisica: 'Physical person',
    pessoaJuridica: 'Legal person',
    nomeCompleto: 'Full name',
    razaoSocial: 'corporate name',
    nomeFantasia: 'Fantasy name',
    nomeDependente: 'dependents name',
    aposAula: 'after class',
    nomeAtividade: 'Activity name',
    apelido: 'Surname',
    dataBase: 'Data base',
    dataFim: 'Final date',
    dataAbertura: 'Opening date',
    dataNascimento: 'Date of birth',
    dataLancamento: 'Release date of',
    nomeCnpjCpf: '$t(geral:nome) / $t(geral:cnpj) ou $t(geral:cpf)',
    cpfCnpj: '$t(geral:cpf) / $(geral:cnpj)',
    nomeMae: 'mother s name',
    nomePai: 'Father s name',
    inscricaoMunicipal: 'Municipal registration',
    inscricaoEstadual: 'State registration',
    regimeTributario: 'Tax regime',
    emissor: 'issuer',
    orgaoEmissor: 'issuing agency',
    dataEmissao: 'Issue date',
    dataEmissaoInicial: 'Initial issue date',
    dataEmissaoFinal: 'Final issue date',
    dataSaidaInicial: 'initial departure date',
    dataSaidaFinal: 'Final departure date',
    dataCompra: 'Purchase date',
    dataOrcamento: 'Budget date',
    dataSaida: 'exit date',
    cidadeNascimento: 'City of birth',
    nacionalidade: 'Nationality',
    tituloEleitor: 'voter registration card',
    zonaEleitoral: 'electoral zone',
    secao: 'Section',
    passaporte: 'Passport',
    dataEntrada: 'Entry date',
    pisPasep: 'PIS / PASEP',
    ctps: 'CTPS',
    uf: 'UF',
    sexo: 'Sex',
    estadoCivil: 'Marital status',
    profissao: 'Profession',
    renda: 'Income',
    etnia: 'ethnicity',
    turma: 'Class',
    escolaridade: 'education',
    frequentaEscola: 'Go to school?',
    nomeResponsavel: 'Name of responsible',
    tipoResponsavel: 'Type of responsible',
    tipoResponsavelServico: 'Type of service responsible',
    tipoResponsavelContaBancaria: 'Type of bank account holder',
    novoResponsavel: 'new responsible',
    editarResponsavel: 'Edit responsible',
    novoDependente: 'new dependent',
    editarDependente: 'edit dependent',
    responsavel: 'Responsible',
    responsaveis: 'Responsible',
    responsaveisPeloProcedimento: 'Responsible for the procedure',
    parentesco: 'Kinship',
    dependente: 'Dependent',
    dependentes: 'Dependents',
    tipoDependente: 'dependent type',
    telefone: 'Telephone',
    email: 'Email',
    nomeEmail: '$t(geral:nome) / $t(geral:email)',
    emailAdicional: 'Additional email',
    telefoneFixo: 'Landline',
    telefoneCelular: 'Cell phone',
    telefoneAdicional: 'Additional phone',
    operadora: 'Operator',
    telefoneEmail: '$t(geral:telefone) / $t(geral:email)',
    endereco: 'Address',
    celular: 'Cell phone',
    redeSocial: 'Social network',
    linkPerfil: 'Profile Link',
    meuPerfil: 'My profile',
    link: 'Link',
    social: 'Social',
    perfil: 'Profile',
    lead: 'Lead',
    cliente: 'Client',
    suspect: 'suspicion',
    prospect: 'Prospect',
    situacao: 'Situation',
    ativo: 'Active',
    ativos: 'Active',
    inativo: 'Inactive',
    inativos: 'inactive',
    bloqueado: 'Blocked',
    incompleto: 'Incomplete',
    outros: 'Others',
    necessidadesEspeciais: 'Special needs',
    necessidade: 'Necessity',
    autismo: 'Autism',
    gestacao: 'Gestation',
    obesidade: 'Oobesity',
    idosidade: 'age',
    deficienciaVisual: 'Visual impairment',
    deficienciaFisica: 'physical disability',
    porteCriancaPequena: 'small child bearing',
    item: 'Item',
    quantidade: 'The amount',
    qntd: 'Qntd',
    subTotalItem: 'Item Subtotal',
    selecioneTipoCfop: 'Select a CFOP type',
    itens: 'Items',
    totalItens: 'Total items',
    detalhes: 'Details',
    nomeItem: 'Item name',
    itemQuantidade: 'Item / Quantity',
    valorCondicao: 'Value / Condition',
    valorAcrescimo: 'Addition Value',
    valorMultaReal: 'R$ penalty',
    valorMulta: '% penalty',
    jurosMulta: 'Fees e Penalty',
    juros: 'Fees',
    multa: 'Penalty',
    custos: 'Costs',
    siglaQuantidade: 'Qtd',
    valorUnitario: 'Unitary value',
    subtotal: 'Subtotal',
    venda: 'Sale',
    custo: 'Cost',
    custoLabel: 'cost type',
    custoDigitado: 'Cost entered',
    custoUltimaCompra: 'Last purchase cost',
    custoMedio: 'average cost',
    unidadeDeMedida: 'Unit of measurement',
    unidadeTipo: 'Unit / Type',
    finalidade: 'Goal',
    ncm: 'NCM',
    precificacao: 'Pricing',
    localRealizacao: 'Place of realization',
    cbo: 'CBO',
    cidade: 'City',
    estado: 'State',
    pais: 'Country',
    opEntrada: 'input operation',
    opSaida: 'output operation',
    opEntradaSaida: 'Entrance and exit',
    descricao: 'Description',
    nomeDescricao: '$t(geral:nome) / $t(geral:descricao)',
    contaPrincipal: 'Main Account',
    codigo: 'Code',
    empresa: 'Company',
    numeroAbreviado: 'N°',
    numeroDeFiliais: '$t(geral:numeroAbreviado) de filiais',
    siteEnderecoDigital: 'Website / Digital address',
    complemento: 'Complement',
    ruaAvenida: 'Road / Avenue',
    cep: 'Zip code',
    optanteSimplesNacional: 'Opting for the Simple National',
    naturalidade: 'Naturalness',
    preco: 'Cost',
    precoPadrao: 'Standard price',
    novoPreco: 'New Price',
    semConsumo: 'no consumption',
    categoria: 'Category',
    subcategorias: 'Subcategories',
    precoCategoria: '$t(geral:preco) / $t(geral:categoria)',
    precoTabela: '$t(geral:preco) / $t(geral:tabela)',
    tags: 'Tags',
    vagas: 'Wave',
    vaga: 'Wave',
    bancoTipo: 'Bank/ Account Type',
    bancoConta: 'Bank/ Account',
    conta: 'Account',
    agencia: 'Agency',
    contaAgencia: '$t(geral:conta) / $t(geral:agencia)',
    forma: 'Form',
    operacao: 'Operation',
    operacao_plural: 'Operations',
    tipoOperacao: 'Operation type',
    configuracoesSpontePay: 'Sponte Pay Settings',
    configuracoesMedPay: 'MedPay Settings',
    opcoesPagamento: 'Payment options',
    canaisEnvioLink: 'Link Shipping Channels',
    numMaximoParcelas: 'Maximum Number of Installments',
    acrescimoParcelamento: 'Addition in Installments',
    percentualAcrescimo: 'Percentage of Addition',
    confirmarDadosClientePagamento: 'Confirm customer data on payment',
    tempoExpiracaoLinkAposVencimento: 'Link expiry time after expiration',
    diasEnvioAntesVencimento: 'Shipping days before expiration',
    medpay: 'Medpay',
    spontepay: 'Sponte pay',
    gerarLinkPagamento: 'Generate payment link',
    linkPagamentoGerado: 'Payment Link Generated',
    enviarLinkSms: 'Send link via SMS',
    enviarLinkEmail: 'Send link via Email',
    cancelarLink: 'Cancel link',
    linkCopiadoAreaTransferencia: 'Link copied to clipboard',
    desejaCancelarLinkPagamento: 'Do you want to cancel the payment link?',
    linkCanceladoSucesso: 'Link canceled successfully',
    linkGeradoSucesso: 'Successfully generated link',
    linkEnviadoCanalPaciente: 'Link sent to the users {{canalEnvio}}',
    validade: 'Validity',
    condicoesPagamentoRecorrentes: 'Recurring Payment Terms',
    alterarCartaoCredito: 'Change Card',
    assinarRecorrencia: 'Sign Recurrence',
    multiempresa: 'Situation',
    fornecedor: 'Provider',
    fornecedores: 'Providers',
    dadosBasicos: 'Basic data',
    dadosParaIntegracao: 'Data for Integration',
    liberacao: 'Release',
    liberacaoPorEmpresas: 'Release by companies',
    documento: 'Documento',
    documentos: 'Documents',
    criarDocumento: 'Create document',
    contato: 'Contact',
    financeiro: 'Financial',
    comercial: 'Commercial',
    personalizado: 'Custom',
    acesso: 'Access',
    anexo: 'Attachment',
    anexos: 'Attachments',
    orientacoes: 'Guidelines',
    dadosClinicos: 'Clinical data',
    historico: 'Historic',
    dados: 'Data',
    composicao: 'Composition',
    tributacao: 'Taxation',
    cfop: 'CFOP',
    cnae: 'CNAE',
    selecioneUmaCnae: 'Select a National Classification of Economic Activities',
    selecioneUmCliente: 'Select a customer',
    aliquotas: 'Rates',
    outrasAliquotas: 'Other rates',
    codigoFiscalDePrestacaoDeServicos: 'Tax code for the provision of services',
    tipoTributacao: 'Type of taxation',
    estoque: 'Inventory',
    sigla: 'Initials',
    observacoes: 'Comments',
    observacao: 'Observation',
    versao: 'Version',
    cadastradoEm: 'Registered in',
    editadoEm: 'Edited in',
    salvoEm: 'Saved in',
    editadoPor: 'Edited by',
    ultimaAlteracao: 'Last registration change',
    anexadoPor: 'Attached by',
    preenchido: 'Filled',
    armazenamento: 'Storage',
    tamanho: 'Size',
    hoje: 'Today',
    solteArquivos: 'Drop your files here',
    escolhaArquivo: 'Choose a file',
    escolhaArquivoOuArraste: 'Choose a file or drag here',
    solteArquivosTextoSecundario: 'or use the button "$t(geral:escolhaArquivoOuArraste)"',
    solteImagemDeDestaque: 'Drop the featured image here',
    selecioneItem: 'Select an item',
    itemPrincipal: 'main item',
    rateio: 'apportionment',
    aplicativos: 'Applications',
    atendimentoOnline: 'Online service',
    centroAjuda: 'help center',
    sobre: 'About',
    informacoes: 'Information',
    informeContato: 'Inform the main contact',
    tagsCadastro: 'Registration Tags',
    adicionarTags: 'Add tags',
    adicionarDocumento: 'Add document',
    horarioInicial: 'Start time',
    horarioFinal: 'End time',
    horarioInicio: 'Start time',
    horarioFim: 'Closing time',
    horarioContato: 'Best time to contact',
    horarioInicioContato: 'Best time to start contact',
    horarioTerminoContato: 'Best time to end contact',
    selecioneResponsavel: 'Select a responsible person to link to the registration',
    selecioneDependente: 'Select a dependent to link to the record',
    definaMultiempresa: 'Define a situation for this registration and in which companies will be displayed',
    possuiNecessidadesEspeciais: 'Do you have any special needs?',
    custoAquisicao: 'Purchase price',
    custoComposicao: 'Composition cost',
    custoAdicional: 'Additional costs',
    custoTotal: 'Total cost of item',
    parcela: 'Portion',
    lucroDesejado: 'Desired profit',
    precoSugerido: 'Suggested price',
    precoSugeridoVenda: 'Suggested selling price',
    indisponivel: 'Unavailable on the portal',
    semResultados: 'No results',
    fator: 'Factor',
    fatorConversao: 'Conversion factor',
    saida: 'Exit',
    saidas: 'exit',
    entrada: 'Input',
    entradas: 'Appetizer',
    itemPadrao: 'Standard item',
    controlaEstoque: 'Controls stock',
    movimentaFinanceiro: 'Financial movement',
    novaEmbalagem: 'New package',
    editarEmbalagem: 'Edit packaging',
    embalagem: 'Packaging',
    embalagem_plural: 'packaging',
    embalagemEntrada: 'inbound packaging',
    embalagemSaida: 'outgoing packaging',
    embalagemControleEstoque: 'Inventory control packaging',
    selecioneGrupoAdicionar: 'Select a group to add',
    selecioneNovoGrupoContato: 'Select a new contact group',
    novoEndereco: 'New address',
    editarEndereco: 'edit address',
    informeEndereco: 'Enter the main address',
    codigoBarras: 'bar code',
    responsaveisCurso: 'Responsible for the course',
    tributacaoEntrada: 'input taxation',
    tributacaoSaida: 'outgoing taxation',
    selecioneTributacao: 'Select taxation',
    custosFixos: 'fixed costs',
    custoFixoEmpresa: 'Fixed cost of the company',
    custoVariavel: 'Variable cost',
    custosVariaveis: 'Variable costs',
    lucroPercentual: 'Percentage profit',
    pesquisar: 'Search',
    appBarPesquisar: 'research something...',
    pesquisarNomeCpf: 'Search by Name and CPF',
    pesquisarNomeCpfCnpj: 'Search by Name, CPF or CNPJ',
    emDesenvolvimento: 'Under development',
    favoritos: 'Favorites',
    lucro: 'Profit',
    lucroEm: 'Profit in',
    lucroEmPorcentagem: 'Profit in %',
    lucroEmReais: 'Profit in R$',
    tipoConta: 'Account Type',
    instituicaoBancaria: 'Banking institution',
    naoPermiteLancamentos: 'Does not allow postings',
    nomeContatoBanco: 'Bank contact name',
    digito: 'Digit',
    selecioneExecutor: 'Select an executor to link',
    consulta: 'Query',
    restricoes: 'Restrictions',
    comissoes: 'Commissions',
    tipoComissoes: 'Type of commissions',
    origemComissao: 'Origin of commission',
    equipePadrao: 'Standard team',
    cargo: 'Office',
    grupo: 'Group',
    cargoGrupo: '$t(geral:cargo) / $t(geral:grupo)',
    percentual: 'Percentage',
    chanceConversao: '% conversion chance',
    descricaoExemplo: 'Example Description',
    interessado: 'Interested',
    grauConversao: 'Conversion degree',
    adicionarItem: 'Add item',
    adicionarAvaliacao: 'Add review',
    periodo: 'Time course',
    periodoPedagogico: 'Pedagogical Period',
    de: 'In',
    ate: 'Until',
    para: 'For',
    geral: 'General',
    automatica: 'Automatic',
    automatico: 'Automatic',
    manual: 'Manual',
    tipoCampo: 'Field type',
    status: 'Status',
    statusOrcamento: 'Status / Budget',
    statusNumeroOrcamento: 'Status / Nº budget',
    numeroOrcamento: 'Nº budget',
    codigoTitulo: 'Title code',
    cicloCalculo: 'Cycle / Calculation',
    condicaoComissaoem: 'Condition / commission on',
    descontarCancelamentosDevolucoes: 'Discounting cancellations and returns',
    considerarDescontoCascata: 'consider cascade discount',
    cicloDePagamento: 'payment cycle',
    cicloDePagamentoComissao: 'commission payment cycle',
    baseCalculo: 'Calculation basis',
    basesAtivas: 'Active bases',
    funcionalidadePadrao: 'Standard functionality',
    novaMeta: 'New goal',
    duracao: 'Duration',
    dataInicio: 'Start date',
    dataTermino: 'End date',
    dataVencimento: 'Due date',
    dataGeracao: 'Generation date',
    dataEncerramento: 'Closing date',
    numeroFatura: 'Nº of the invoice',
    naturezaParcelas: 'Nature / Parcel',
    restricaoAcrescimo: 'Restriction / Addition',
    bandeiraCredenciadora: 'Flag / Accreditor',
    formaCondicao: 'Form / Condition',
    taxaPrazo: 'Rate / Deadline',
    contaCompensacao: 'Bank account / Compensation',
    documentoFornecedor: 'Document/ Provider',
    origemDocumentoCliente: 'Document source / Client',
    emissaoVencimento: 'Issue / Due date',
    emissaoEntrada: 'Issue / Input',
    totalQuantidade: 'Total / The amount',
    totalQuantidadeItems: 'Total / Quantity of items',
    operacaoObservacoes: 'Operação / Comments',
    AVista: 'In cash',
    APrazo: 'To term',
    restricao: 'Restriction',
    selecioneRestricao: 'Select restriction',
    selecioneTipoCalculoComissao: 'Select the type of calculation used in commission',
    acrescimo: 'Addition',
    emMoeda: 'In $t(geral:currency)',
    emPorcentagem: 'In %',
    porcentagem: 'Percentage',
    vencimento: 'Due date {{number}}',
    porcentagemDaParcela: 'Installment percentage',
    mensal: 'Monthly',
    semanal: 'Weekly',
    quinzenal: 'Fortnightly',
    diario: 'Daily',
    parcelas: 'installments',
    valorMeta: 'goal value',
    valorDaParcela: 'Installment Value',
    informacaoDePagamento: 'Payment Information',
    utilizaValorVencimentoFixo: 'Use fixed value and fixed maturity',
    valorVencimentoVaiSerDefinido: 'Amount and maturity defined when using this payment term',
    diaVencimento: ' Due date Day',
    diasAposVencimento: 'Days after expiration',
    valorComissao: 'commission amount',
    pagamento: 'Payment',
    recebimento: 'Receivement',
    ambos: 'Both',
    dia: 'Day',
    porDia: 'Per Day',
    porMes: 'Per Month',
    dia_plural: 'Days',
    enviarFoto: 'Send picture',
    disponivelEm: 'Available in',
    modulosDisponiveis: 'Available modules',
    moduloPadrao: 'Make the default module',
    situacaoModuloAtiva: 'Make module status active',
    selecioneModuloRelacionar: 'Select a module to list',
    contaDebito: 'Debit account',
    contaCredito: 'Credit account',
    tipoDeContato: 'Contact type',
    tipoDeCalculo: 'Calculation Type',
    selecioneContato: 'Select a contact',
    produtosEServicos: 'Products and services',
    produtosServicos: 'Products / Services',
    origem: 'Source',
    origemOportunidade: 'Origin of Opportunity',
    quemIndicou: 'who referred?',
    novaTarefa: 'New task',
    indicadoPor: 'Indicated by',
    equipe: 'Team',
    selecioneTarefa: 'Select a task',
    grauDeInteresse: 'Degree of interest',
    tipoDeCompromisso: 'Type of appointment',
    motivo: 'Reason',
    desconto: 'Discount',
    motivoComercial: 'Commercial motive',
    descontoConcedido: 'Discount granted',
    valorDesconto: 'Discount amount',
    valorOriginal: 'Original value',
    valorBolsa: 'Stock price',
    usuariosResponsaveis: 'Responsible users',
    selecioneUsuarios: 'Select Users',
    selecioneUsuario: 'Select a user',
    comentarios: 'Comments',
    possibilidades: 'Possibilities',
    escrevaUmComentario: 'Write a comment',
    gerenciarWorkflow: 'Manage funnel',
    novoTipoDeContato: 'New Contact Type',
    restringirA: 'Restrict to',
    restringirAFormaDePagamento: 'Restrict to payment method',
    novoCadastro: 'New register',
    disponivel: 'Available',
    disponivelNoPortal: 'Available on the portal',
    responsavelOportunidade: 'Responsible for the opportunity',
    principal: 'Main',
    selecionarEmpresa: 'Select Company',
    selecionarModelo: 'Select model',
    situacaoAtualizadaSucesso: 'Status updated successfully',
    empresaAdicionadaSucesso: 'Company successfully added',
    editarSituacao: 'Edit situation',
    selecioneEmpresaRelacionar: 'Select a company to list',
    bandeira: 'Flag',
    credenciadora: 'Accreditor',
    formaDePagamento: 'Form of payment',
    taxa: 'Rate',
    prazo: 'Deadline',
    valorMaximo: 'Maximum value',
    limitePacientes: 'Limit patients',
    numeroSequencial: 'Sequential number',
    compensaVencimento: 'Compensates on maturity',
    selecioneContaBancaria: 'Select a banking institution',
    conciliacao: 'Conciliation',
    conciliacaoAutomatica: 'Automatic reconciliation',
    orcamento: 'Budget',
    orcamentos: 'Budgets',
    valor: 'Value',
    orcamentoData: '$t(geral:orcamento) / $t(geral:data)',
    ano: 'Year',
    anual: 'Yearly',
    serieAno: 'Series or Course / Year',
    valorParcelas: '$t(geral:valor) / $t(geral:parcelas)',
    situacaoTipo: '$t(geral:situacao) / $t(geral:tipo)',
    fornecedorDataVencimento: 'Provider / Due date',
    statusDataPagamento: 'Status / Payday',
    caixaEmpresa: 'Nº box / Company',
    valorParcela: 'Value / Portion',
    parcelaVencimento: 'Portion / Due date',
    nomeCep: 'Name / CEP',
    cidadeEstado: 'City / State',
    tipoEndereco: 'Type of address',
    logradouro: 'Public place',
    logradouroNumero: 'Public place / Nº',
    tipoLogradouro: 'Type of street',
    bairro: 'Neighborhood',
    numero: 'Number',
    operador: 'Operator',
    aberto: 'Open',
    consolidado: 'Consolidated',
    efetivado: 'Made effective',
    cancelado: 'Called off',
    pendente: 'Pending',
    processando: 'Processing',
    fechado: 'Closed',
    finalizado: 'Finished',
    abertura: 'Opening',
    fechamento: 'Closure',
    retirar: 'Withdraw',
    suprimento: 'Supply',
    numeroDoContrato: 'Contact number',
    codigoDoContrato: 'Contract code',
    quitado: 'Settled',
    vencido: 'Overdue',
    atendimentos: 'Attendances',
    evolucao: 'Evolution',
    dataEHora: 'Date and time',
    data: 'Date',
    hora: 'Time',
    subTotal: 'Subtotal',
    intervaloVencimentoParcelas: 'Installment Maturity Interval',
    acrescimoDesconto: 'Addition / Discount',
    notas: 'Grades',
    situacaoFinanceira: 'Financial situation',
    acoes: 'Actions',
    total: 'Total',
    media: 'Average',
    frequencia: 'Frequency',
    nomeFrequencia: 'Name / Frequency',
    reposicao: 'Replacement',
    horario: 'Schedule',
    sala: 'Living room',
    salas: 'Rooms',
    horarioSala: '$t(geral:horario) / $t(geral:sala)',
    maisContatos: 'More contacts',
    nomeDetalhes: 'Name / Details',
    inicioTermino: 'Start / end',
    tipoDeProduto: 'Product type',
    textoRespostaCurta: 'Short answer text',
    textoRespostaLonga: 'Long answer text',
    digiteSuaPergunta: 'Enter your question here',
    tempoAgendamento: 'Time to schedule before appointment',
    tempoDuracaoAtividade: 'Activity Duration Time',
    intervaloRecreio: 'Interval - playground',
    configuracoes: 'Settings',
    configuracoesDeVigencia: 'Effectiveness Settings',
    configuracaoDaEmpresa: 'Company configuration',
    tipoPagamento: 'Type of payment',
    inicio: 'Start',
    termino: 'End',
    horarios: 'Schedules',
    diarioClasse: 'Class diary',
    selecioneFormaDePagamentoVincular: 'Select a payment method to link',
    peso: 'Weight',
    pesoOrdem: 'Weight / Order',
    nomeTemplate: 'Name / Template',
    adicionarOpcoes: 'Add options',
    adicionarEmpresa: 'Add company',
    visualizar: 'To view',
    selecioneCaixa: 'Select a box',
    condicaoDePagamento: 'Payment terms',
    condicoesDePagamento: 'Payment conditions',
    gerarTitulos: 'Generate titles',
    estornarLancamentos: 'Reverse entries',
    funcao: 'Occupation',
    especialidade: 'Specialty',
    subEspecialidade: 'Subspecialty',
    somarHonorario: 'Add fee',
    honorario: 'Honorary',
    honorarios: 'Fees',
    tipoFalta: 'Type of Fault',
    funcaoTipo: '$t(geral:funcao) / $t(geral:tipo)',
    valorEmpresa: '$t(geral:valor) / $t(geral:empresa)',
    dataAdmissao: 'Admission date',
    dataDemissao: 'Resignation date',
    contasBancarias: 'Bank accounts',
    pedidoCompra: 'Purchase order',
    pedidoCompras: 'Purchase order',
    countUnidade: '{{count}} unity',
    countUnidade_plural: '{{count}} units',
    countItem: '{{count}} item',
    countItem_plural: '{{count}} items',
    valorTotal: 'Amount',
    repetir: 'Repeat',
    novoItem: 'New item',
    editarItem: 'Edit item',
    selecioneFase: 'Select a stage',
    faseDoNegocio: 'Business phase',
    editar: 'To edit',
    enviar: 'To send',
    verRespostas: 'See Answers',
    resposta: 'Response',
    resposta_plural: 'Answers',
    compartilhar: 'To share',
    baixarCSV: 'Download CSV',
    novoContato: 'New contact',
    recorrencia: 'Recurrence',
    tarefas: 'Tasks',
    prestador: 'Provider',
    sistema: 'System',
    clientes: 'Client',
    clienteOuFornecedor: 'Customer or supplier',
    clienteFornecedorOperador: 'Customer, supplier or operator',
    clienteFornecedorFuncionario: 'Customer, supplier or employee',
    contaBancaria: 'Bank account',
    adicionar: 'Add',
    adicionarValor: 'Add value',
    novaCondicao: 'New condition',
    adicionarCondicao: 'Add condition',
    gerenciarCondicoes: 'Manage conditions',
    novoValor: 'New value',
    abrirCaixa: 'Open box',
    fecharCaixa: 'Close box',
    suprirCaixa: 'Supply box',
    retirarCaixa: 'Cash out',
    justificativa: 'Justification',
    origemDocumento: 'Document source',
    vendedor: 'Salesman',
    vendidoPor: 'Sold by',
    totalFechamento: 'Closing total',
    saldoFechamento: 'Closing balance',
    totalSuprimento: 'Total supply',
    saldoSuprimento: 'Supply balance',
    totalRetirada: 'Total withdrawal',
    saldoRetirada: 'Withdrawal Balance',
    saldoAtual: 'Current balance',
    valorRetirada: 'Withdrawal amount',
    valorFechamento: 'Closing amount',
    caixa: 'Box',
    dataPagamento: 'Payday',
    profissional: 'Profissional',
    profissionais: 'Professionals',
    adicionarProfissional: 'Add Professional',
    selecioneProfissionalParaRelacionar: 'Select a professional to list',
    selecionePrestadorParaRelacionar: 'Select a provider to list',
    equipamentos: 'Equipments',
    servicos: 'Services',
    intervalo: 'Interval',
    diasDaSemana: 'Days of the week',
    diaUtil: 'Business day',
    diaAposVencimento: 'Days after expiration',
    feriado: 'Holiday',
    faixaEtaria: 'age group',
    falta: 'Lack',
    presente: 'Present',
    limiteFaltas: 'Absence limit',
    limiteRetornos: 'Return limit',
    selecionarSabados: 'Select saturdays',
    selecionarDomingos: 'Select sundays',
    horarioFuncionamento: 'Opening hours',
    acessoOutrosProfissionais: 'Access by other professionals',
    notificarLimiteFaltas: 'Notify absence limit',
    adicionarDependencia: 'Add Dependency',
    dadosDaMatricula: 'Enrollment Data',
    dependencias: 'Dependencies',
    opcoes: 'Options',
    calendario: 'Calendar',
    movimento: 'Movement',
    'contas-a-pagar': 'bills to pay',
    'contas-a-receber': 'Bills to receive',
    carregarMais: 'Load more',
    tempo: 'Time',
    novoIntegrante: 'New member',
    modalidadeTurma: 'Class modality',
    novaSituacao: 'New situation',
    novoProdutoServico: 'New product / service',
    especie: 'Species',
    cartao: 'Card',
    cheque: 'Check',
    funcionario: 'Employee',
    respondidoEm: 'Answered in',
    respondidoPor: 'Answered by',
    estrela: 'Star',
    estrela_plural: 'Stars',
    pesquisePorResposta: 'Search for an answer...',
    emocao: 'Emotion',
    enviarPesquisa: 'Submit survey',
    envieLink: 'Send the link',
    copiarLink: 'Copy link',
    linkCopiado: 'Link copied',
    valorPago: 'Amount paid',
    valorPendente: 'Outstanding amount',
    preDefinidos: 'Predefined',
    novaSecao: 'New section',
    adicionarCampo: 'Add field',
    novoCampo: 'New field',
    editarCampo: 'Edit field',
    tipoDoCampo: 'Field type',
    tamanhoDoCampo: 'Field size',
    valorPadrao: 'Standard value',
    muito_pequeno: 'Tiny',
    pequeno: 'Little',
    medio: 'Medium',
    grande: 'Great',
    textoCurto: 'Short text',
    termo: 'Term',
    laboratorio: 'Laboratory',
    estaSalaEUmLaboratorio: 'This room is a laboratory?',
    locacao: 'Location',
    permiteLocacao: 'Allows rental?',
    tipoDeSala: 'Room type',
    equipamentoFixo: 'Fixed equipment?',
    capacidade: 'Capacity',
    capacidadeMaxima: 'Maximum capacity',
    verPerfil: 'View profile',
    assinarDigitalmente: 'Digitally sign',
    nomeSocial: 'Social name',
    serChamadoPeloNomeSocial: 'Be called by social name',
    profissaoResponsavel: 'Responsible profession',
    idadeResponsavel: 'Guardian age',
    midia: 'Media',
    plano: 'Flat',
    tipoCusto: 'Cost type',
    valorCusto: 'Cost value',
    qtdProfissionais: 'Professional quantity',
    necessidadeEspecial: 'Special need',
    unidade: 'Unit',
    subUnidade: 'Sub-unit',
    optativo: 'Optional',
    obrigatorio: 'Mandatory',
    numeroDocumento: '$t(geral:numeroAbreviado) document',
    nomeDocumento: 'Document name',
    indicacao: 'Recommendation',
    modelos: 'Models',
    solicitante: 'Requester',
    executante: 'Performer',
    imprimir: 'Print out',
    imprimirApenasDescricao: 'Print description only',
    imprimirProfissionais: 'Print professionals',
    digiteAqui: 'Type here',
    exame: 'Exam',
    exame_plural: 'Exams',
    origemExame: 'Exam origin',
    selecionarExame: 'Select exam',
    adicionarExame: 'Add exam',
    novoProtocolo: 'New protocol',
    tabela: 'Table',
    saldoConta: 'Account balance',
    tipoProtocolo: 'Protocol type',
    selecionarProtocolo: 'Select protocol',
    efetivados: 'Effective',
    pendentes: 'Outstanding',
    finalizados: 'Finalized',
    tipoDeConversao: 'Conversion type',
    configuracao: 'Settings',
    permiteCompra: 'Allows purchase',
    permiteVenda: 'Allows sale',
    anosFaixa: 'Years / tracks',
    competenciaEspecifica: 'specific competence',
    novoUsuario: 'new user',
    patrimonio: 'Patrimony',
    identificacaoPatrimonio: 'Identification of Assets',
    idadeMinima: 'Minimum age',
    idadeMaxima: 'Maximum age',
    executor: 'Executor',
    orientacao: 'Orientation',
    precaucao: 'Precaution',
    alergia: 'Allergy',
    tipoSanguineo: 'Blood type',
    classificacao: 'Classification',
    url: 'URL',
    videoUrl: 'video URL',
    secoesDisponiveis: 'Available sections',
    finalizarImprimir: 'Finish and print',
    naoInformado: 'Uninformed',
    naoInformada: 'Not informed',
    emissao: 'Issue',
    codigoTabelaTuss: 'TUSS Table Code',
    codigoTuss: 'TUSS code',
    alunos: 'Students',
    valorAtual: 'Current value',
    itemQtd: 'Item / amount',
    valorAnual: 'Annual value',
    filtros: 'Filters',
    verBoleto: 'See billet',
    copiarCodigoDeBarra: 'Copy barcode',
    gerarBoleto: 'Generate billet',
    baixarPdf: 'Download payment slip in PDF',
    receber: 'Receive',
    efetivar: 'Effectuate',
    gerado: 'Generated',
    liquidado: 'Liquidated',
    falhou: 'Failed',
    baixado: 'Downloaded',
    novaEspecialidade: 'New specialty',
    prazoMaximoAtendimento: 'Maximum service period',
    tributacaoPadrao: 'Standard taxation',
    selecionar: 'Select',
    pedidoVenda: 'Sales order',
    acessarPedido: 'Access order',
    fecharCaixaAberto: 'Close open box',
    ultimosNMeses: 'Latest {{qtdeMeses}} months',
    contasAPagar: 'Bills to pay',
    contasAReceber: 'Bills to receive',
    movimentacoes: 'Movements',
    saldoTotal: 'Total balance',
    recebido: 'Received',
    aReceber: 'Receivable',
    pago: 'Paid out',
    aPagar: 'payable',
    saldoInicial: 'Opening balance',
    saldoDaConta: 'Account balance',
    historicoDeMovimentacoes: 'Movement history',
    motivoDoDesfecho: 'Reason for the outcome',
    tipoMotivoDesfecho: 'Type of outcome reason',
    situacaoMatricula: 'Registration status',
    tipoMatricula: 'Type of registration',
    avaliacaoRealizada: 'Evaluation performed',
    grauNecessidadeEspecial: 'Degree of special need',
    filial: 'Branch',
    matriz: 'Headquarters',
    trocarEmpresa: 'Change Company',
    senha: 'Password',
    senhaAtual: 'Current password',
    novaSenha: 'New Password',
    confirmacaoSenha: 'Password confirmation',
    emailAcesso: 'Access email',
    solicitarNovaSenha: 'Request new password after',
    permitirAcesso: 'Allow access',
    manha: 'Morning',
    tarde: 'Evening',
    noite: 'Night',
    adicionarLocal: 'Add location',
    enderecoIP: 'IP address',
    recurso: 'Resource',
    tipoNecessidadeEspecial: 'Type of special need',
    horariosLivres: 'Free hours',
    chegada: 'Arrival',
    cor: 'Color',
    cor_plural: 'Colors',
    selecioneCor: 'Select a color',
    adicionarRecurso: 'Add resource',
    faixaEtariaInicial: 'Initial age group',
    faixaEtariaFinal: 'Final age group',
    limiteReposicoes: 'Replacement limit',
    prazoParaReposicao: 'Replacement deadline',
    notificarLimiteFaltasExcedido: 'Notify absence limit exceeded',
    verCadastro: 'See registration',
    bloquear: 'Block',
    excluir: 'Delete',
    personalizarLayout: 'Customize layout',
    topo: 'Top',
    rodape: 'Baseboard',
    produto: 'Product',
    impressao: 'Print',
    disponibilizarAssinatura: 'Provide for subscription',
    iniciar: 'Start',
    escolhaUmModelo: 'Choose a template',
    anexarArquivo: 'Attach file',
    exibirCabecalho: 'Display header',
    alinhamentoDoCabecalho: 'Header Alignment',
    fonte: 'Source',
    texto: 'Text',
    espacamento: 'Spacing',
    mostrarData: 'Show date',
    estornos: 'Chargebacks',
    mensagem: 'Message',
    subespecialidade: 'Subspecialty',
    clienteDesde: 'Customer since',
    ticketMedio: 'Average ticket',
    tipoSituacaoCheque: 'Status',
    tipoConciliacao: 'Type of reconciliation',
    compensado: 'Offset',
    devolvido: 'returned',
    em_aberto: 'Opened',
    dataExpiracao: 'Expiration date',
    ambiente: 'Environment',
    horaData: 'Hour and date',
    ativa: 'Active',
    inativa: 'Inactive',
    formaFisica: 'Physical form',
    formula: 'Recipe',
    diasDisponiveis: 'Available days',
    responsavelPelaCondicao: 'Responsible for the condition',
    clientesAtivos: 'Active clients',
    faltosos: 'Defaulters',
    modalidades: 'Modalities',
    verTodos: 'See all',
    verTodas: 'See all',
    particular: 'Private',
    dataAtualizacao: 'Update date',
    enviarSms: 'Send SMS',
    template: 'Template',
    quantidadeItens: 'Quantity of items',
    quantidadeItensInicial: 'Initial quantity of items',
    quantidadeItensFinal: 'End Item Quantity',
    mes: 'Month',
    procedimentos: 'Procedures',
    tempoAgendamentoAntesConsulta: 'Time to schedule before appointment',
    verAgenda: 'See schedule',
    formulas: 'Recipe',
    permite: 'Allows',
    prestadoresEmissores: 'Providers and issuers',
    exibirRodape: 'Show footer',
    alinhamentoDoRodape: 'Footer alignment',
    idade: 'Age',
    adicionarSecao: 'Add section',
    replicarHorarios: 'Replicate times',
    replicar: 'Replicate',
    diaSemana: 'Day of the week',
    as: 'at',
    integracao: 'Integration',
    novoCheque: 'New check',
    novoLancamentoCartao: 'New card release',
    nsu: 'NSU',
    diferencaConsolidacao: 'Difference consolidation',
    diferencaFechamento: 'Closing difference',
    contaContabilCredito: 'Account for credit',
    contaContabilDebito: 'Account for debit',
    contaContabilDebitoHelper: 'A debit ledger account is the destination account of the value, good, and or service.',
    contaContabilCreditoHelper: 'A credit ledger account is the source account of the value, good, and or service.',
    tipoOperacaoHelper: 'As every movement that will result in financial representation in the DRE must contain an operation associated with a G/L account for the origin of the resources and a G/L account for the destination of the resources',
    funil: 'Funnel',
    calculo: 'Calculation',
    grupoDeDocumentos: 'Document group',
    conteudo: 'Contents',
    marcarTodos: 'Mark all',
    professores: 'Teachers',
    funcionarios: 'Employees',
    aberturaCaixa: 'Cash opening',
    condicao: 'Condition',
    condicao_plural: 'Conditions',
    variavel: 'Variable',
    variavel_plural: 'Variables',
    parametro: 'Parameter',
    parametro_plural: 'Parameters',
    tenteNovamente: 'Try again',
    notFound: 'Could not load page.',
    conclusao: 'Conclusion',
    periodos: 'Periods',
    adicionarFormula: 'Add formula',
    adicionarPeriodo: 'Add period',
    isento: 'Exempt',
    isenta: 'Exempt',
    nomeTurma: 'Class name',
    verParcelas: 'See parcels',
    dataContrato: 'Contract date',
    prezado_prezada: 'Dear',
    recomendacao: 'Recommendation',
    recomendacao_plural: 'Recommendations',
    anonimo: 'Anonymous',
    cameraDesligada: 'Camera off',
    enviarEmail: 'Send email',
    selecioneClienteOuFornecedor: 'Select a customer or supplier',
    condicaoTaxa: 'Condition / Tax',
    prazoCompensaVencimento: 'Deadline / Compensates on maturity',
    quitarConta: 'Pay off account',
    quitarLote: 'Settle bills in batch',
    consolidacaoLote: 'Consolidation of boxes in batch',
    desconsolidacaoLote: 'Batch box deconsolidation',
    consolidacaoTitulosLote: 'Consolidation of titles in batch',
    desconsolidacaoTitulosLote: 'Deconsolidation of securities in batch',
    titulosSomando: 'titles adding up',
    lancarBaixa: 'Post write off',
    tipoAcrescimoDesconto: 'Addition type / discount',
    agendadoEm: 'Scheduled on',
    acessarTeleconsulta: 'Access teleconsultation',
    notificacoes: 'Notifications',
    dataFixa: 'Fixed date',
    valorFixo: 'Fixed value',
    data_hora: 'Date / time',
    aceitarTermos: 'Accept the terms',
    dataVencimentoInicial: 'Initial Maturity Date',
    dataVencimentoFinal: 'Final Due Date',
    agrupamento: 'Grouping',
    semAgrupamento: 'No Grouping',
    agruparVencimento: 'Group by Expiration',
    novoPeriodo: 'New period',
    editarPeriodo: 'Edit period',
    novoRecurso: 'New feature',
    prazoParaRetorno: 'Deadline for return',
    disponivelAgendamentoOnline: 'Available in online scheduling',
    disponivelPortalAluno: 'Available on the student portal',
    diasSemana: 'Days of the week',
    categoriaAgendamento: 'Scheduling Category',
    novoFuncionario: 'New employee',
    editarFuncionario: 'Edit funcionário',
    historicoChat: 'Chat history',
    campos: 'Fields',
    local: 'Local',
    nenhumRecursoCadastrado: 'No resources registered',
    relatorios: 'Reports',
    gerarOrcamento: 'Generate budget',
    gerarVenda: 'Generate sale',
    padrao: 'Pattern',
    visualizacao: 'Visualization',
    visualizacaoEmLista: 'List view',
    visualizacaoPorCard: 'View by card',
    download: 'Download',
    nomeMantenedora: 'Maintainer s name',
    representanteLegal: 'Legal representative',
    limit50MB: 'in 50MB',
    arquivoRemovido: 'File removed successfully',
    arquivoAtualizado: 'File updated successfully',
    detalhesAnexo: 'Attachment Details',
    visualizarBaixar: 'To view / Download',
    alergias: 'Allergies',
    medicamentosUsoContinuo: 'Continuous use medications',
    precaucoes: 'Precautions',
    agenda: 'Schedule',
    pesquiseAqui: 'Search here...',
    funcionarioPermitido: 'Allowed employee',
    publica: 'Public',
    termosDeAceite: 'Terms of acceptance',
    aceito: 'Accepted',
    naoAceito: 'I do not accept',
    adendo: 'Addendum',
    assinar: 'To sign',
    planos: 'Plans',
    atributoAnatomico: 'Anatomical attribute',
    convenios: 'Covenants',
    tipoSecao: 'Section Type',
    inserirImagem: 'Insert image',
    mostrarX: 'Show "{{name}}"',
    titulo: 'Title',
    tamanhoFolha: 'Sheet size',
    alinhamentoCarimbo: 'Stamp alignment',
    imprimirCarimboSomenteUltimaPagina: 'Print stamp on last page only',
    modeloImpressao: 'Print template',
    modeloReceituario: 'Recipe template',
    publico: 'Public',
    privado: 'Private',
    termosUso: 'Terms of use',
    dataAceite: 'Acceptance date',
    documentacao: 'Documentation',
    modeloDeDocumentos: 'Document template',
    dataInicioVigencia: 'Effective date',
    dataTerminoVigencia: 'Effective date',
    tipoContrato: 'Type of contract',
    tipoAgendamento: 'Schedule Type',
    retornoExames: 'Return of exams',
    cabecalho: 'Header',
    titulos: 'Titles',
    vendas: 'Sales',
    registradoEm: 'Registered in',
    cargaHorariaTotal: 'Total workload',
    adicionarDesconto: 'Add discount',
    utilizarCondicaoPagamentoItem: 'Use item payment term',
    dadosDoContrato: 'Contract data',
    referencia: 'Reference',
    dadosDaParcela: 'Parcel data',
    nossoNumero: 'Our number',
    valorDoDocumento: 'Document s value',
    valorLiquido: 'Net value',
    dadosDoPagamento: 'Payment details',
    dataInicial: 'Initial date',
    dataFinal: 'Final date',
    horaInicial: 'Start time',
    horaFinal: 'End time',
    considerarDiaLetivo: 'Consider it as a school day',
    mostrarCalendarioImpresso: 'Show on Printed Calendar',
    copiarCalendario: 'Copy calendar',
    criarCopia: 'Create copy',
    desejaRealmenteCriarCopiaRegistro: 'Do you really want to create a copy of this record?',
    disponiveisAno: 'Available in the year',
    letivosAno: 'Schools in the year',
    naoLetivos: 'Non-teaching',
    feriados: 'Holidays',
    dilatacao: 'Dilation',
    imprimirReceita: 'Print recipe',
    apenasComTitulos: 'Titles only',
    totalCondicao: 'Condition total',
    verDetalhes: 'See details',
    simNao: 'Yes or no',
    inativarLink: 'Inactivate link',
    manter: 'To maintain',
    voltarDepois: 'Come back later',
    debugs: 'Debugs',
    falhas: 'Failures',
    relacionamento: 'Relationship',
    hooks: 'Hooks',
    mensalidade: 'Monthly payment',
    usuarios: 'Users',
    smsEnviados: 'SMS sent',
    emailsEnviados: 'Sent emails',
    boletosEmitidos: 'Bills issued',
    bemVindo: 'Welcome',
    por: 'per',
    totalDoCaixa: 'Cash total',
    abertos: 'Open',
    verContrato: 'View contract',
    filtrosAvancados: 'Advanced filters',
    limparFiltros: 'Clean filters',
    maisFiltros: 'More filters',
    dataCadastroInicial: 'Initial Registration Date',
    dataCadastroFinal: 'Final Registration Date',
    dataEdicaoInicial: 'Initial Issue Date',
    dataEdicaoFinal: 'Final Editing Date',
    codigoCliente: 'Customer code',
    criado: 'Created',
    editado: 'Edited',
    ativas: 'Active',
    inativas: 'Inactive',
    interno: 'Internal',
    externo: 'External',
    todos: 'All',
    totalGeral: 'Grand total',
    totalExterno: 'Total Externals',
    totalInterno: 'Total Interns',
    totalAtivas: 'Total Assets',
    totalInativas: 'Total inactive',
    confirmar: 'Confirm',
    fechados: 'Closed',
    consolidar: 'Consolidate',
    desconsolidar: 'Deconsolidate',
    saldo: 'Balance',
    restamXDias: 'Remain {{dias}} days!',
    ultimoDia: 'Last day!',
    totalFormaPagamento: 'Total payment method',
    saldoFormaPagamento: 'Payment method balance',
    operacaoMovimento: 'Movement operation',
    diferenca: 'Difference',
    valorDeAbertura: 'Opening value',
    usuarioHabilitado: 'Enabled user',
    valorDeFechamento: 'Closing value',
    valorConsolidado: 'Consolidated value',
    valorDaDiferenca: 'Difference amount',
    situacaoDoCaixa: 'Cash Status',
    escrevaUmaJustificativa: 'Write a justification',
    excluirBaixa: 'Delete write-off',
    dataDeFechamento: 'Closing date',
    baixar: 'Download',
    filtrar: 'filter',
    comparar: 'To compare',
    compararImagens: 'Compare images',
    toolbar: 'Toolbar',
    tipoDeArquivo: 'File type',
    modulos: 'Modules',
    usuario: 'User',
    selecionarSecao: 'Select a section from another model to import',
    titularConta: 'Owner of the account',
    contaCorrente: 'Checking account',
    contaPoupanca: 'Savings account',
    banco: 'Bank',
    titular: 'Holder',
    site: 'Site',
    diaDaTransferencia: 'Transfer day',
    margens: 'Margins',
    previsualizacao: 'Preview',
    alinhamento: 'Alignment',
    adicionarLinhaAcima: 'Add line above',
    removerLinha: 'Remove line',
    removerColuna: 'Remove column',
    adicionarLinhaAbaixo: 'Add line below',
    adicionarColunaAEsquerda: 'Add left column',
    adicionarColunaADireita: 'Add column on the right',
    adicionarTag: 'Add tag',
    esquerda: 'Left',
    direita: 'Right',
    visualizarPedido: 'View order',
    composicoes: 'Compositions',
    adicionarComposicao: 'Add composition',
    quantidadeMinimaItens: 'Minimum quantity of items',
    quantidadeMaximaItens: 'Maximum number of items',
    dataEntradaInicial: 'Initial entry date',
    dataEntradaFinal: 'Final entry date',
    totalComposicao: 'Total compositions',
    outrosFiltros: 'Other filters',
    imprimeNaGuia: 'Prints on the tab',
    terminologiaDeCodigoDaDespesa: 'Expense Code Terminology',
    utilizarFormulas: 'Use formulas',
    numeroCaixa: 'Cashier number',
    dataLancamentoInicial: 'Initial release date',
    dataLancamentoFinal: 'Final release date',
    verMais: 'View more',
    dataPagamentoInicial: 'Initial payment date',
    dataPagamentoFinal: 'Final payment date',
    opcaoPagamento: 'Payment option',
    verMenos: 'See less',
    novoPrestadorEmissor: 'New provider or issuer',
    certificado: 'Certified',
    issRetido: 'ISS withheld',
    usaNfse: 'Use NFS-e',
    numeroSerieNfse: 'NFS-e series number',
    responsavelIss: 'ISS Responsible',
    indicadorLocalServico: 'Service location indicator',
    regimeEspecialTributacao: 'Special taxation regime',
    naturezaOperacao: 'Nature of operation',
    operacaoTributacao: 'Taxation operation (DSFNET)',
    numeroUltimoRps: 'Last RPS No.',
    codigoUnidadeMedida: 'Unit of measure code',
    ocultarPisCofinsExibirApenasObservacoesNota: 'Hide PIS and COFINS and show only in note remarks',
    formatarAliquotaIss: 'Format ISS rate (Tag ValAliqISS)',
    br: 'BR',
    validarCpfCnpj: 'Validate CPF / CNPJ',
    validarDadosCadastrais: 'Validate registration data',
    sempreValorParcela: 'Always the value of the installment',
    valorParcelaPendenteValorPagoQuitado: 'Installment amount if pending and amount paid if paid',
    deduzirJuros: 'Deduct interest',
    jurosComposto: 'Compound Interest',
    jurosSimples: 'Simple Interest',
    discriminarDesconto: 'Discriminate discounts',
    condicionado: 'Conditioning',
    incondicionado: 'Unconditioned',
    paisTomador: 'borrower s country',
    pagarme: 'Pay me',
    taxasAtualizadasSucesso: 'Rates updated successfully!',
    atualizarTaxas: 'Update rates',
    ativeIntegracaoAcessarAba: 'Activate the integration to access this tab',
    nenhumaTarifaEncontrada: 'No rates found :(',
    tenteAtualizarTarifasAbaixo: 'Try to update the rates below',
    arquivos: 'Files',
    agendar: 'To schedule',
    ted: 'TED',
    movimentar: 'move',
    valorPagamento: 'Payment Amount',
    codigoDeBarras: 'Bar code',
    boletoInavalido: 'Invalid billet',
    vigenciaContrato: 'Term of contract',
    dataContratacaoInicial: 'Initial contract date',
    dataContratacaoFinal: 'Date of final contract',
    padraoNfseChave: 'Default NFS-e key',
    chaveAcesso: 'Access key',
    gerarNf: 'Generate NF',
    emissaoNotaFiscal: 'Issuance of invoice',
    prestadorEmissor: 'Provider / Issuer',
    meses: {
      janeiro: 'January',
      janeiro_abrev: 'JAN',
      fevereiro: 'February',
      fevereiro_abrev: 'FEB',
      marco: 'March',
      marco_abrev: 'MAR',
      abril: 'April',
      abril_abrev: 'APR',
      maio: 'May',
      maio_abrev: 'MAY',
      junho: 'June',
      junho_abrev: 'JUNE',
      julho: 'July',
      julho_abrev: 'JULY',
      agosto: 'August',
      agosto_abrev: 'AUG',
      setembro: 'September',
      setembro_abrev: 'SEPT',
      outubro: 'October',
      outubro_abrev: 'OCT',
      novembro: 'November',
      novembro_abrev: 'NOV',
      dezembro: 'December',
      dezembro_abrev: 'DEC'
    },
    weekDays: {
      domingo: 'Sunday',
      segundaFeira: 'Monday',
      tercaFeira: 'Tuesday',
      quartaFeira: 'Wednesday',
      quintaFeira: 'Thursday',
      sextaFeira: 'Friday',
      sabado: 'Saturday',
      domingoAbr: 'Sun',
      segundaFeiraAbr: 'Mon',
      tercaFeiraAbr: 'Tue',
      quartaFeiraAbr: 'Wed',
      quintaFeiraAbr: 'Thu',
      sextaFeiraAbr: 'Fri',
      sabadoAbr: 'Sat'
    },
    dateTime: {
      segundo: 'second',
      segundo_plural: 'seconds',
      minuto: 'minute',
      minuto_plural: 'minutes',
      hora: 'hour',
      hora_plural: 'hours',
      dia: 'day',
      dia_plural: 'days',
      mes: 'month',
      mes_plural: 'months',
      ano: 'year',
      ano_plural: 'years old'
    },
    feedbacks: {
      desejaContinuar: 'Changes made will not be saved, want to continue?',
      removerPermanentemente: 'Remove this record permanently',
      algoInesperado: 'Something unexpected happened',
      emailAdicionadoSucesso: 'Email successfully added!',
      emailRemovidoSucesso: 'Email successfully removed!',
      enderecoAdicionadoSucesso: 'Address added successfully!',
      enderecoAtualizadoSucesso: 'Address updated successfully!',
      enderecoRemovidoSucesso: 'Address successfully removed!',
      emailNaoExiste: 'E-mail informed does not exist!',
      emailRecuperacaoEnviadoSucesso: 'Recovery email sent successfully!',
      senhaAlteradaSucesso: 'Password changed successfully!',
      redeSocialAdicionadaSucesso: 'Social network successfully added!',
      redeSocialRemovidaSucesso: 'Social network successfully removed!',
      telefoneAdicionadoSucesso: 'Phone added successfully!',
      telefoneRemovidoSucesso: 'Phone removed successfully!',
      documentoAtualizadoSucesso: 'Documents updated successfully!',
      dependenteAdicionadoSucesso: 'Dependent updated successfully!',
      dependenteRemovidoSucesso: 'Dependent successfully removed!',
      responsavelAdicionadoSucesso: 'Responsible added successfully!',
      responsavelAtualizadoSucesso: 'Responsible updated successfully!',
      responsavelRemovidoSucesso: 'Responsible removed successfully!',
      dependenteAtualizadoSucesso: 'Dependent updated successfully!',
      itemIncluidoSucesso: 'Item successfully added!',
      itemAdicionadoSucesso: 'Item successfully added!',
      itemAtualizadoSucesso: 'Item updated successfully!',
      itemRemovidoSucesso: 'Item removed successfully!',
      itemJaCadastrado: 'Item already registered!',
      cadastroSucesso: 'Successfully registered',
      nenhumRegistro: 'No records found',
      primeiroCadastro: 'Make your first registration by clicking on',
      avatarAdicionadoSucesso: 'Avatar successfully added!',
      avatarRemovidoSucesso: 'Avatar successfully removed!',
      logotipoAdicionadoSucesso: 'Logo added successfully!',
      logotipoRemovidoSucesso: 'Logo removed successfully!',
      brasaoEstadoAdicionadoSucesso: 'State coat of arms successfully added!',
      brasaoEstadoRemovidoSucesso: 'State coat of arms successfully removed!',
      nenhumKanban: 'No kanban to display',
      primeiraConfiguracaoWorkflow: 'Set up a funnel and start managing your opportunities',
      checklistAtualizadoSucesso: 'Checklist updated successfully!',
      checklistCadastradoSucesso: 'Item successfully registered in the checklist!',
      checklistRemovidoSucesso: 'Item removed from checklist successfully!',
      adicionadoSucesso: 'Successfully added!',
      cadastradoSucesso: 'Registered successfully!',
      atualizadoSucesso: 'Successfully updated!',
      removidoSucesso: 'Successfully removed!',
      adicionadaSucesso: 'Successfully added!',
      cadastradaSucesso: 'Successfully registered!',
      atualizadaSucesso: 'Updated successfully!',
      removidaSucesso: 'Successfully removed!',
      tarefaAdicionadaSucesso: 'Task added successfully!',
      tarefaAtualizadaSucesso: 'Task updated successfully!',
      tarefaRemovidaSucesso: 'Task removed successfully!',
      situacaoCadastradaSucesso: 'Successfully registered situation',
      situacaoAtualizadaSucesso: 'Status updated successfully!',
      situacaoRemovidaSucesso: 'Situation removed successfully!',
      respostaEnviada: 'Your answer has been sent',
      obrigadoContribuirConosco: 'Thanks for contributing to us',
      alteracoesSalvasSucesso: 'Changes saved successfully',
      itemSemprePreco: 'Item without price',
      itemPrecoSelecioneUmItem: 'Select an item to upload your prices',
      contaNaoMovimentada: 'This account has not been operated yet!',
      semEstornosNoPeriodo: 'There were no chargebacks during this period',
      semContratosVigentesUltimosXMeses: 'No contracts in force in the last {{meses}} months',
      smsRequisicaoEnvioCadastrada: 'Sending SMS successfully requested',
      emailRequisicaoEnvioCadastrada: 'Sending Email Requested Successfully',
      usuarioAdicionadoSucesso: 'User added successfully!',
      baixaLancadaSucesso: 'Write-off successfully launched!',
      baixaListaLancadaSucesso: 'Successfully posted batch write-off!',
      baixaRemovidaSucesso: 'Write-off successfully removed!',
      cartaoLancadoSucesso: 'Card launch successful!',
      equipamentoAdicionadoSucesso: 'Equipment successfully added!',
      professorAdicionadoSucesso: 'Teacher successfully added!',
      salaAdicionadaSucesso: 'Room successfully added!',
      funcionarioAdicionadoSucesso: 'Employee successfully added!',
      salaRemovidaSucesso: 'Successfully removed room!',
      equipamentoRemovidoSucesso: 'Equipment successfully removed!',
      professorRemovidoSucesso: 'Teacher successfully removed!',
      funcionarioRemovidoSucesso: 'Employee successfully removed!',
      procedimentoAdicionadoSucesso: 'Procedure added successfully!',
      procedimentoRemovidoSucesso: 'Procedure removed successfully!',
      dataInicialNaoPodeSerMaiorQueDataFinal: 'Start date cannot be greater than end date',
      agendamentoRemovidoSucesso: 'Schedule removed successfully!',
      nenhumArquivoAnexado: 'No files attached',
      nenhumArquivoAnexadoAoAtendimento: 'No files were attached to this service!',
      precisaFecharCaixaAntesAbrirOutro: 'You need to close the cashier before opening another one.',
      caixaAbertoSucesso: 'Open box successfully!',
      conteReceberEstornadaSucesso: 'Account receivable successfully reversed',
      periodoAdicionadoSucesso: 'Period successfully added!',
      periodoRemovidoSucesso: 'Period successfully removed!',
      enviarMensagemErro: 'An error occurred while sending the message!',
      baixarMensagemErro: 'There was an error downloading the message!',
      apenasUmAnexoPorMensagem: 'Choose only one attachment to send attachment to message!',
      semAcrescimoOuDesconto: 'No addition or discount',
      horarioFimObrigatorioQuandoTemHorarioInicio: 'Mandatory end time when there is a start time',
      horarioInicialObrigarioQuandoTemHorarioFim: 'Mandatory start time when end time',
      nenhumaNotificacao: 'No notifications found',
      semNotificacoes: 'You still don t have any notifications.',
      nenhumGrupoDeCampoPersonalizadoVinculado: 'No custom field groups registered',
      cadastreCampoPersonalizado: 'Register one at least one custom field group',
      falhaAbrirAtendimentoOnline: 'Failed to open online service. Try again!',
      maximoArquivosUpload: 'Maximum 10 files per upload!',
      recursoRemovidoSucesso: 'Resource successfully removed',
      boletoCanceladoSucesso: 'Ticket canceled successfully',
      nenhumaSecaoDisponivelParaAtendimento: 'No section available for this service',
      nenhumAnexoEncontrado: 'No Attachments Found!',
      nenhumResultadoEncontrado: 'No results found!',
      nenhumResultadoComAFraseEncontrado: 'No results with the phrase "{{frase}}" was found!',
      nenhumFuncionarioCadastrado: 'No registered employees',
      nenhumFuncionarioEncontrado: 'No employees found',
      nenhumUsuarioAssociado: 'No associated users',
      nenhumAtendimentoClientePossuiDesfechoPublico: 'None of this customer s service has a public outcome!',
      tituloJaQuitado: 'Title already paid.',
      voceNaoTemAcessoEsseRecurso: 'You do not have access to this feature!',
      voceDeseja: 'You wish?',
      voceNaoTemPermissaoParaRealizarEssaAcao: 'You are not allowed to perform this action!',
      trocaPCondicaoItem: 'When confirming, you will be using the item s payment terms and condition discounts..',
      trocaPCondicaoPedido: 'When confirming, you will be using the payment term of the order and discounts are applied to the item.',
      contaBancariaNaoEncontrada: 'Bank account not found!',
      erroAoRealizarUploadImagensTenteNovamente: 'There was an error uploading the images, try again!',
      trocaStatusHabilitado: 'By enabling all the features of the module, you are enabling the module for companies:',
      trocaStatusDesabilitado: 'By disabling all features of the module, you are disabling the module for companies:',
      trocaStatusFeatureHabilitado: 'By enabling the feature, you are enabling the feature for companies:',
      trocaStatusFeatureDesabilitado: 'By disabling the feature, you are disabling the feature for companies:',
      transacaoAutorizada: 'Authorized transaction!',
      transacaoNaoAutorizada: 'Unauthorized transaction!',
      porFavorAguarde: 'Please wait...',
      casoNaoExistirInformarX: 'If not, inform {{string}}.',
      vocePossuiCaixaEmAberto: 'Do you have open cash from previous days',
      voceNaoPossuiCaixaEmAberto: 'You have no open cash',
      valorVerificacaoIncorreto: 'Incorrect value in verification.',
      contaExistenteInativada: 'There is an inactive user with this email',
      contaExistenteInativadaMensagem: 'The user {{usuario}} you already have this email registered in your access and it is inactivated. Reactivate user for access.',
      emailJaCadastrado: 'This email is already registered'
    },
    cores: {
      neutral: 'Neutral',
      white: 'White',
      lightGrey: 'Light gray',
      mediumGrey: 'Gray',
      darkGrey: 'Dark grey',
      almostBlack: 'Almost black',
      black: 'Black',
      primary: 'Primary',
      primaryLight: 'Primary course',
      primaryDark: 'Primary dark',
      secondary: 'Secondary',
      secondaryLight: 'Secondary of course',
      secondaryDark: 'Secondary dark',
      info: 'Informatics',
      infoLight: 'Informatics of course',
      infoDark: 'dark informatics',
      success: 'Success',
      successLight: 'Clear success',
      successDark: 'Dark success',
      warning: 'Alert',
      warningLight: 'Clear alert',
      warningDark: 'Dark alert',
      error: 'Error',
      errorLight: 'Clear error',
      errorDark: 'Dark error',
      red: 'Red',
      redLight: 'Light red',
      redDark: 'Dark red',
      pink: 'Pink',
      pinkLight: 'Light pink',
      pinkDark: 'Dark pink',
      purple: 'Purple',
      purpleLight: 'Light purple',
      purpleDark: 'Dark purple',
      deepPurple: 'Deep Purple',
      deepPurpleLight: 'Deep light purple',
      deepPurpleDark: 'Deep dark purple',
      indigo: 'Indigo',
      indigoLight: 'Light indigo',
      indigoDark: 'Dark indigo',
      blue: 'Blue',
      blueLight: 'Light blue',
      blueDark: 'Dark blue',
      cyan: 'Cyan',
      cyanLight: 'Light cyan',
      cyanDark: 'Dark cyan',
      teal: 'Teal',
      tealLight: 'Light teal',
      tealDark: 'Dark teal',
      green: 'Green',
      greenLight: 'Light green',
      greenDark: 'Dark green',
      lime: 'Lime',
      limeLight: 'Clear file',
      limeDark: 'Dark lime',
      yellow: 'Yellow',
      yellowLight: 'Light yellow',
      yellowDark: 'Dark yellow',
      orange: 'Orange',
      orangeLight: 'Light orange',
      orangeDark: 'Dark orange',
      deepOrange: 'Deep orange',
      deepOrangeLight: 'Light deep orange',
      deepOrangeDark: 'deep dark orange',
      brown: 'Brown',
      brownLight: 'Light brown',
      brownDark: 'Dark brown'
    },
    validacoes: {
      cpf: 'Invalid CPF',
      cnpj: 'Invalid CNPJ',
      email: 'Invalid email',
      numero: 'Field must be numeric',
      dezCaracteres: 'Maximum limit of 10 characters',
      numeroMinimo: 'Must be greater than or equal to {{min}}',
      numeroMaximo: 'Must be less than or equal to {{max}}',
      numeroCartao: 'Card number is invalid',
      valorMinimo: 'Must be greater than or equal to $t(geral:currency) {{value, currency|BRL}}',
      valorMaximo: 'Must be less than or equal to $t(geral:currency) {{value, currency|BRL}}',
      caracteresMinimos: 'Must have at least {{value}} characters',
      caracteresMaximos: 'Must have the maximum {{value}} characters',
      caracteresFixos: 'Must have {{value}} characters',
      quantidadeMininaNumeros: 'Must have ${min} or more numbers',
      obrigatorio: 'Required field',
      senhaCurta: 'Password too short',
      senhaLonga: 'Password too long',
      senhasDiferentes: 'Different passwordss',
      sim: 'Yes',
      nao: 'Not',
      anoInvalido: 'Invalid year',
      dataInvalida: 'Invalid date',
      dataMaximaHoje: 'It can t be bigger than today',
      dataMinimaHoje: 'It can t be smaller than today',
      dataDeveSerMaiorQue: 'Date must be greater than or equal to {{date, date|DD/MM/YYYY}}',
      dataDeveSerMenorQue: 'Date must be less than or equal to {{date, date|DD/MM/YYYY}}',
      dataInicialObrigatoria: 'Mandatory start date',
      dataJaCadastrada: 'Date already registered ',
      urlIncorreta: 'Incorrect URL',
      digito: 'Invalid Type',
      senhaDeveConter: 'Password must contain',
      minimoCaracteres: 'Minimum of 8 characters',
      conterLetras: 'At least one lowercase letter',
      conterLetraMaiuscula: 'At least one capital letter',
      conterNumeros: 'Numbers',
      conterCaractereEspecial: 'Special characters',
      valorMaiorQue: 'Must be greater than {{value}}',
      valorMenorQue: 'Must be less than {{value}}',
      senhaFraca: 'Password does not meet criteria',
      cargaHorarioSemanalMaiorCargaHorarioTotal: 'Weekly credit hours must be less than the total',
      horaInvalida: 'Invalid time format',
      minutosInvalidos: 'Minutes in invalid format',
      documentoSemConteudo: 'Document template without content',
      horaDeveSerMaiorQue: 'Time must be greater than or equal to {{hour, hour|HH:mm}}'
    },
    navegacao: {
      concluir: 'Conclude',
      proximo: 'Next',
      anterior: 'Previous',
      inicio: 'Start',
      dashboard: 'Dashboard',
      cadastro: 'Registration',
      clientes: 'Customers',
      fornecedores: 'Providers',
      funcionarios: 'Employees',
      'preferencias-do-cadastro': 'Registration Preferences',
      catalogo: 'Catalog',
      produtos: 'Products',
      servicos: 'services',
      categoria: 'Category',
      categorias: 'Categories',
      estoque: 'Inventory',
      precificacoes: 'Pricing',
      'tipos-de-produto': 'Product Types',
      'classificacao-de-produtos': 'Product classification',
      'preferencias-do-catalogo': 'Catalog Preferences',
      agenda: 'Schedule',
      agendamento: 'Scheduling',
      'preferencias-da-agenda': 'Calendar Preferences',
      calendario: 'Calendar',
      calendarios: 'Calendars',
      'lista-de-espera': 'Waiting list',
      'liberacao-de-modulos': 'Module Release',
      financeiro: 'Financial',
      'bancos-e-contas': 'Banks and Accounts',
      'formas-de-pagamento': 'Payment methods',
      'preferencias-do-financeiro': 'Financial Preferences',
      'planos-financeiros': 'Financial plans',
      fiscal: 'Supervisor',
      'plano-de-contas': 'Chart of accounts',
      'centro-de-custos': 'Cost center',
      'preferencias-do-fiscal': 'Fiscal preferences',
      relatorios: 'Reports',
      finalidades: 'Purposes',
      preferencias: 'Preferences',
      'locais-de-realizacao': 'Places of performance',
      'servicos-e-procedimentos': 'Services and procedures',
      analise: 'Analysis',
      oportunidades: 'Opportunities',
      workflows: 'Funnels',
      'pre-tarefas': 'pre-tasks',
      contatos: 'Contacts',
      'preferencias-do-comercial': 'Commercial Preferences',
      'detalhes-personalizados': 'Custom Details',
      'graus-de-interesse': 'Degrees of interest',
      'tipos-de-compromisso': 'Commitment Types',
      'configuracoes-de-comissoes': 'Commission settings',
      'condicoes-de-pagamentos': 'Payment Terms',
      'motivos-comerciais': 'Commercial reasons',
      'origens-do-contato': 'Contact origins',
      tags: 'Tags',
      'status-de-exames': 'Exam Status',
      'unidades-de-medida': 'Units of measure',
      'usos-de-produtos': 'Product Uses',
      'tipos-de-contato': 'Types of contact made',
      tesouraria: 'Treasury',
      'controle-de-cartoes-de-credito': 'Credit card control',
      'controle-de-cheques': 'Check control',
      'controle-de-cartoes': 'Card control',
      'configuracoes-de-cartoes': 'Card Settings',
      'configuracoes-de-cheques': 'Check Settings',
      'bandeiras-de-cartoes-de-credito': 'Credit card brands',
      'credenciadoras-de-cartoes-de-credito': 'Credit Card Accreditation Companies',
      operacoes: 'Operations',
      'configuracoes-de-bolsa': 'Bag settings',
      contabil: 'Accounting',
      'preferencias-do-contabil': 'Accounting Preferences',
      tributacoes: 'Taxation',
      questionarios: 'Questionnaires',
      'configuracoes-de-convenios': 'Insurance Settings',
      'configuracoes-de-bandeiras': 'flags settings',
      'configuracoes-de-credenciadoras': 'Accreditor configurations',
      'configuracoes-de-descontos': 'Discount Settings',
      'orcamentos-compra': 'Purchase budgets',
      'orcamentos-venda': 'Sales budgets',
      entradas: 'Appetizer',
      compras: 'Purchases',
      vendas: 'Sales',
      saidas: 'Departures',
      'contas-a-pagar': 'Bills to pay',
      'contas-a-receber': 'Bills to receive',
      configuracoes: 'Settings',
      empresas: 'Companies',
      funcoes: 'Roles',
      cargos: 'Positions',
      caixa: 'Daily cash',
      'caixa-diario': 'Daily cash',
      'caixa-geral': 'General box',
      comercial: 'Commercial',
      boletos: 'Ticket control',
      especialidades: 'Specialties',
      'dashboard-do-financeiro': 'Financial Dashboard',
      'necessidades-especiais': 'Special needs',
      'configuracoes-de-calendarios': 'Calendar Settings',
      templates: 'Templates',
      sms: 'SMS',
      'dashboard-comercial': 'Dashboard',
      salas: 'Rooms',
      'configuracoes-de-pagamentos': 'Payment Settings',
      'salas-ambientes': 'Rooms / Environments',
      'politicas-de-acesso': 'Access Policies',
      'painel-de-tarefas': 'task pane',
      'modelos-de-documentos': 'Document Templates',
      documentos: 'Documents',
      'grupos-de-documentos': 'Document groups',
      notificacoes: 'Notifications',
      email: 'Email',
      'grupos-cadastro-personalizado': 'custom groups',
      'motivos-cancelamento': 'Reasons for cancellation ',
      chat: 'Chat',
      banners: 'Banners',
      dre: 'DRE',
      faturas: 'Invoices',
      banking: 'Banking',
      'dashboard-banking': 'Dashboard do banking',
      movimentos: 'Movements',
      extratos: 'Extracts',
      'contatos-bancarios': 'Bank contacts',
      'templates-comerciais': 'Business templates'
    }
  },
  testeGratis: {
    estaGostandoMedplus: 'Are you enjoying MedPlus?',
    estaGostandoSponte: 'Are you enjoying the bridge?',
    contrateAgora: 'Hire now!',
    restam: 'Remain',
    xDias: '{{dias}} Days',
    seuPeriodoTeste: 'Of your trial period.',
    ligamosPraVoce: 'We call you!',
    ouEntreEmContato: 'Or contact us',
    ultimoDia: 'it s the last day',
    seuPeriodoTesteAcabou: 'Your testing period has come to an end :(',
    paraSeguirUsandoPlano: 'To continue using MedPlus, it is necessary to contract a plan. For this, or for more information, contact us through the channels below:'
  },
  baseInativa: {
    titulo: 'Your base is inactive',
    paraReativar: 'To reactivate access, it is necessary to contact our sales team through the channels below:'
  },
  chat: {
    abrirChat: 'Open chat',
    canais: 'Channels',
    mensagens: 'Messages',
    membros: 'Members',
    membro: 'Member',
    adicionarMembro: 'Add member',
    administrador: 'Administrator',
    sairCanal: 'Leave this channel',
    pesquisarConversas: 'Search Channels/Messages',
    pesquisarFuncionarios: 'Search Employees',
    novoCanal: 'New channel',
    criarCanal: 'Create channel',
    canalCriado: 'Channel created successfully',
    canalExcluido: 'Channel removed successfully',
    editarCanal: 'Edit channel',
    canaisPublicos: 'Public Channels',
    novasMensagens: 'New Messages',
    entrouCanal: 'Joined the channel',
    saiuCanal: 'left the channel',
    erroEntrarCanal: 'Error joining the channel',
    papelAtualizado: 'Updated paper',
    canalExluido: 'Deleted channel',
    canalAtualizado: 'Updated channel',
    mensagemApagada: 'This message has been deleted.',
    membroAdicionado: 'Member successfully added',
    membroRemovido: 'Member successfully removed',
    verMais: 'View more',
    naoExcluirMensagem: 'Cannot delete messages from another user',
    erroIniciarConversa: 'Error starting conversation',
    usuarioDesativado: 'Disabled',
    usuarioRemovido: 'Removed',
    placeholders: {
      envioMensagem: 'Write a message...',
      nomeCanal: 'Channel name',
      canalPrivado: 'Private channel'
    }
  },
  catalogo: {
    servicos: {
      titulo: 'services',
      novo: 'New service',
      cadastradoSucesso: 'Service registered successfully!',
      atualizadoSucesso: 'Service updated successfully!',
      removidoSucesso: 'Service removed successfully!',
      principaisDados: 'Main Service Data',
      preco: {
        titulo: 'Price',
        novo: 'New price',
        cadastradoSucesso: 'Price registered successfully!',
        atualizadoSucesso: 'Price updated successfully!',
        removidoSucesso: 'Price removed successfully!',
        profissionais: {
          titulo: 'Professionals',
          novo: 'New professional',
          edit: 'Edit professional',
          cadastradoSucesso: 'Successfully registered professional!',
          atualizadoSucesso: 'Professional updated successfully!',
          removidoSucesso: 'Professional removed successfully!',
          selecioneProfissional: 'Select a professional to list',
          funcaoProfissional: 'Professional role'
        },
        condicaoDePagamento: {
          totalPlano: 'Plan total',
          subTotal: 'Condition Subtotal',
          adicionar: 'Add payment term',
          relacionar: 'List payment condition',
          cadastradoSucesso: 'Conditions registered successfully!',
          saldo: 'Balance of conditions',
          totalCondicoes: 'Total conditions',
          removidoSucesso: 'Payment condition removed successfully!',
          precoSujerido: 'Item suggested price'
        }
      },
      composicao: {
        novo: 'New composition',
        edit: 'Edit composition',
        cadastradoSucesso: 'Membership successfully registered!',
        atualizadoSucesso: 'Composition successfully updated!',
        removidoSucesso: 'Composition successfully removed!'
      },
      tributacao: {
        novo: 'New taxation',
        edit: 'Edit taxation',
        cadastradoSucesso: 'Taxation successfully registered!',
        atualizadoSucesso: 'Taxation updated successfully!',
        removidoSucesso: 'Taxation successfully removed!'
      },
      embalagem: {
        novo: 'New packaging control',
        edit: 'Edit packaging control',
        cadastradoSucesso: 'Package successfully registered!',
        atualizadoSucesso: 'Package updated successfully!',
        removidoSucesso: 'Package successfully removed!'
      }
    },
    produtos: {
      titulo: 'Products',
      novo: 'New product',
      cadastradoSucesso: 'Product registered successfully!',
      atualizadoSucesso: 'Product updated successfully!',
      removidoSucesso: 'Product removed successfully!',
      principaisDados: 'Main Product Data',
      preco: {
        titulo: 'Price',
        novo: 'New price',
        cadastradoSucesso: 'Price registered successfully!',
        atualizadoSucesso: 'Price updated successfully!',
        removidoSucesso: 'Price removed successfully!',
        profissionais: {
          titulo: 'Professionals',
          novo: 'New professional',
          edit: 'Edit professional',
          cadastradoSucesso: 'Successfully registered professional!',
          atualizadoSucesso: 'Professional updated successfully!',
          removidoSucesso: 'Professional removed successfully!',
          selecioneProfissional: 'Select a professional to relater',
          funcaoProfissional: 'Professional role'
        }
      },
      composicao: {
        novo: 'New composition',
        edit: 'Edit composition',
        cadastradoSucesso: 'Membership successfully registered!',
        atualizadoSucesso: 'Composition successfully updated!',
        removidoSucesso: 'Composition successfully removed!'
      },
      tributacao: {
        novo: 'New taxation',
        edit: 'Edit taxation',
        cadastradoSucesso: 'Taxation successfully registered!',
        atualizadoSucesso: 'Taxation updated successfully!',
        removidoSucesso: 'Taxation successfully removed!'
      },
      embalagem: {
        novo: 'New packaging control',
        edit: 'Edit packaging control',
        cadastradoSucesso: 'Package successfully registered!',
        atualizadoSucesso: 'Package updated successfully!',
        removidoSucesso: 'Package successfully removed!'
      }
    },
    procedimentos: {
      titulo: 'Procedures',
      novo: 'New procedure',
      cadastradoSucesso: 'Procedure registered successfully!',
      atualizadoSucesso: 'Procedure updated successfully!',
      removidoSucesso: 'Procedure removed successfully!',
      principaisDados: 'Main data of the procedure',
      preco: {
        titulo: 'Price',
        novo: 'Novo preço',
        cadastradoSucesso: 'Price registered successfully!',
        atualizadoSucesso: 'Price updated successfully!',
        removidoSucesso: 'Price removed successfully!',
        profissionais: {
          titulo: 'Professionals',
          novo: 'New professional',
          edit: 'Edit professional',
          cadastradoSucesso: 'Successfully registered professional!',
          atualizadoSucesso: 'Professional updated successfully!',
          removidoSucesso: 'Professional removed successfully!',
          selecioneProfissional: 'Select a professional to list',
          funcaoProfissional: 'Professional role'
        }
      },
      composicao: {
        novo: 'New composition',
        edit: 'Edit composition',
        cadastradoSucesso: 'Membership successfully registered!',
        atualizadoSucesso: 'Composition successfully updated!',
        removidoSucesso: 'Composição removida com sucesso!'
      },
      orientacao: {
        novo: 'New orientation',
        edit: 'Edit orientation',
        cadastradoSucesso: 'Guidance successfully registered!',
        atualizadoSucesso: 'Guidance updated successfully!',
        removidoSucesso: 'Orientation successfully removed!'
      }
    },
    preferencias: {
      categorias: {
        titulo: 'Categories',
        novo: 'New category',
        cadastradoSucesso: 'Category successfully registered!',
        atualizadoSucesso: 'Category updated successfully!',
        removidoSucesso: 'Category successfully removed!',
        principaisDados: 'Main Category Data',
        nomeDaCategoria: 'Category name',
        categoriaPai: 'Father Category',
        nomeDaCategoriaPai: 'Parent category name',
        subcategoria: {
          nome: 'Subcategory name',
          cadastradoSucesso: 'Subcategory successfully registered!',
          removidoSucesso: 'Subcategory successfully removed!'
        }
      },
      classificacaoDeProdutos: {
        titulo: 'Product classification',
        novo: 'New Product Classification',
        cadastradoSucesso: 'Product classification successfully registered!',
        atualizadoSucesso: 'Product classification successfully updated!',
        removidoSucesso: 'Product classification successfully removed!'
      },
      finalidades: {
        titulo: 'Purposes',
        novo: 'New purpose',
        nome: 'Purpose name',
        principaisDados: 'Main Category Data',
        cadastradoSucesso: 'Purpose registered successfully!',
        atualizadoSucesso: 'Purpose updated successfully!',
        removidoSucesso: 'Purpose removed successfully!'
      },
      locaisDeRealizacao: {
        titulo: 'Places of performance',
        novo: 'New venue',
        cadastradoSucesso: 'Place of achievement registered successfully!',
        atualizadoSucesso: 'Location successfully updated!',
        removidoSucesso: 'Place of achievement successfully removed!'
      },
      precificacoes: {
        titulo: 'Pricing',
        novo: 'New pricing',
        cadastradoSucesso: 'Pricing successfully registered!',
        atualizadoSucesso: 'Pricing successfully updated!',
        removidoSucesso: 'Pricing successfully removed!',
        custosVariaveis: {
          titulo: 'Variable costs',
          cadastradoSucesso: 'Variable cost successfully added!',
          atualizadoSucesso: 'Variable cost successfully removed!',
          removidoSucesso: 'Variable cost successfully removed!'
        }
      },
      tiposDeProduto: {
        titulo: 'Product Types',
        novo: 'New Product Type',
        cadastradoSucesso: 'Type of product successfully registered!',
        atualizadoSucesso: 'Product type successfully updated!',
        removidoSucesso: 'Product type successfully removed!'
      },
      unidadesDeMedida: {
        titulo: 'Units of measure',
        novo: 'New unit of measure',
        cadastradoSucesso: 'Unit of measure successfully registered!',
        atualizadoSucesso: 'Unit of measure successfully updated!',
        removidoSucesso: 'Unit of measure successfully removed!',
        editar: 'Edit unit of measure'
      },
      usosDeProdutos: {
        titulo: 'Product Uses',
        novo: 'New Product Use',
        cadastradoSucesso: 'Successfully using registered product!',
        atualizadoSucesso: 'Successfully updated product use!',
        removidoSucesso: 'Use of product removed successfully!',
        editar: 'Edit product usage'
      },
      statusDeExames: {
        titulo: 'Exam Status',
        novo: 'New exam status',
        cadastradoSucesso: 'Successfully registered exam status!',
        atualizadoSucesso: 'Successfully updated exam status!',
        removidoSucesso: 'Exam Status Removed Successfully!',
        editar: 'Edit exam status'
      }
    }
  },
  comercial: {
    dashboard: {
      negociosGanhos: 'Business won',
      negociosPerdidos: 'Lost business',
      portal: 'Portal',
      nfse: 'NFS-e',
      nfe: 'NF-e',
      tituloFunil: 'Funnel',
      tituloDistribuicao: 'Distribution',
      feedback: 'No record for the filter entered'
    },
    fluxoAutomacao: {
      novo: 'New flow',
      titulo: 'Automation flows',
      nomeFluxo: 'Flow name',
      blocosFluxo: 'Flow blocks',
      arrasteSolteBlocosTela: 'Drag and drop blocks on the screen',
      executarDatasEspecificas: 'Run on specific dates',
      cadastradoSucesso: 'Successfully registered automation flow!',
      atualizadoSucesso: 'Successfully updated automation flow!',
      novaAcao: 'New action',
      novaDecisao: 'New decision block',
      novaCondicao: 'New condition',
      nomeBloco: 'Block name',
      selecioneModuloSistema: 'Select a system module',
      selecioneSubModulo: 'Select sub-module',
      opcoesDecisao: 'Decision options',
      tipoCondicao: 'Condition types',
      quantoTempo: 'How much time?',
      tipoAcao: 'Type of action',
      quemNotificar: 'Who to notify?',
      adicionarEmailPersonalizado: 'Add custom email',
      mensagemEmail: 'Email message'
    },
    oportunidades: {
      titulo: 'Opportunities',
      novo: 'New opportunity',
      cadastradoSucesso: 'Opportunity successfully registered!',
      atualizadoSucesso: 'Opportunity updated successfully!',
      removidoSucesso: 'Opportunity successfully removed!',
      financeiro: {
        orcamento: {
          novo: 'New budget'
        }
      }
    },
    painelDeTarefas: {
      titulo: 'Task pane',
      novo: 'New task',
      cadastradoSucesso: 'Task registered successfully!',
      atualizadoSucesso: 'Task updated successfully!',
      removidoSucesso: 'Task removed successfully!',
      informacoes: 'Information',
      nome: 'Title',
      selecioneTipoTarefa: 'Select task type',
      faseTarefa: 'Task phase',
      verMais: 'View more',
      horaInicio: 'Start time',
      horaTermino: 'End Time',
      descricaoPlaceholder: 'Write here...',
      checklist: {
        titulo: 'Items from the list',
        novo: 'New item',
        edit: 'Edit item',
        adicionar: 'Add item',
        cadastradoSucesso: 'Item registered successfully!',
        atualizadoSucesso: 'Item updated successfully!',
        removidoSucesso: 'Item removed successfully!',
        nome: 'Name',
        comentario: 'Comment',
        concluido: 'Concluded'
      },
      chart: {
        aFazer: 'To do',
        fazendo: 'Making',
        atrasado: 'Late',
        feito: 'Done'
      },
      filtro: {
        titulo: 'Title',
        tipoTarefa: 'Type of task',
        situacao: 'Situation',
        outro: 'Others',
        limpar: 'To clean'
      }
    },
    questionarios: {
      titulo: 'Questionnaires',
      novo: 'New quiz',
      cadastradoSucesso: 'Successfully registered questionnaire!',
      atualizadoSucesso: 'Quiz updated successfullyo!',
      removidoSucesso: 'Quiz successfully removed!'
    },
    preferencias: {
      preTarefas: {
        titulo: 'Pre-tasks',
        novo: 'New pre-task',
        dados: 'Pre-task data',
        tipo: 'Type of pre-task',
        adiar: 'Postpone pre-task',
        marcarTarefa: 'Mark pre-task without checklist as completed',
        cadastradoSucesso: 'Pre-task successfully registered!',
        atualizadoSucesso: 'Pre-task successfully updated!',
        removidoSucesso: 'Pre-task successfully removed!'
      },
      banners: {
        titulo: 'Banners',
        novo: 'New banner',
        cadastradoSucesso: 'Banner successfully registered!',
        atualizadoSucesso: 'Banner updated successfully!',
        removidoSucesso: 'banner successfully removed!',
        imagem: 'Image updated successfully!',
        contador: 'You have {{value}} characters remaining'
      },
      tiposDeCompromisso: {
        titulo: 'Commitment Types',
        novo: 'New type of commitment',
        cadastradoSucesso: 'Type of appointment successfully registered!',
        atualizadoSucesso: 'Appointment type updated successfully!',
        removidoSucesso: 'Appointment type successfully removed!'
      },
      motivosComerciais: {
        titulo: 'Commercial reasonss',
        novo: 'New business motive',
        cadastradoSucesso: 'Business reason successfully registered!',
        atualizadoSucesso: 'Business motif successfully updated!',
        removidoSucesso: 'Business motif successfully removed!'
      },
      tiposDeContato: {
        titulo: 'Types of contact made',
        novo: 'New type of contact made',
        cadastradoSucesso: 'Type of contact successfully registered!',
        atualizadoSucesso: 'Contact type successfully updated!',
        removidoSucesso: 'Type of contact successfully removed!'
      },
      grausDeInteresse: {
        titulo: 'Degrees of interest',
        novo: 'New degree of interest',
        cadastradoSucesso: 'Degree of interest successfully registered!',
        atualizadoSucesso: 'Degree of interest updated successfully!',
        removidoSucesso: 'Degree of interest successfully removed!'
      },
      origensDoContato: {
        titulo: 'Contact origins',
        novo: 'New Origin',
        cadastradoSucesso: 'Origin registered successfully!',
        atualizadoSucesso: 'Origin updated successfully!',
        removidoSucesso: 'Origin successfully removed!'
      },
      workflows: {
        titulo: 'Funnels',
        novo: 'New funnel',
        cadastradoSucesso: 'Funnel successfully registered!',
        atualizadoSucesso: 'Funnel successfully updated!',
        removidoSucesso: 'Funnel successfully removed!',
        colunas: {
          novo: 'New column',
          dados: 'Column data',
          nome: 'Column name',
          tarefasGeradasAutomaticamente: 'Automatically generated tasks',
          selecionarTarefas: 'Select tasks',
          concluirTarefasMudancaFase: 'Complete phase change tasks',
          cadastradoSucesso: 'Column successfully registered!',
          atualizadoSucesso: 'Column updated successfully!',
          removidoSucesso: 'Column successfully removed!'
        }
      }
    }
  },
  cadastro: {
    modulos: {
      atualizadoSucesso: 'Modules updated successfully!'
    },
    fornecedores: {
      titulo: 'Providers',
      novo: 'New supplier',
      cadastradoSucesso: 'Successfully registered supplier!',
      atualizadoSucesso: 'Supplier updated successfully!',
      removidoSucesso: 'Supplier successfully removed!',
      principaisDados: 'Main supplier data',
      financeiro: {
        orcamentos: {
          novo: 'New budget'
        },
        pedidos: {
          novo: 'New request'
        },
        contasPagar: {
          novo: 'New Account Payable'
        }
      },
      dadosClinicos: {
        precaucoes: 'Precautions',
        alergiaMedicamentosa: 'Drug allergy',
        medicamentoUsoContinuo: 'Continuous use medications',
        principioAtivo: 'Active principle',
        informePrecaucao: 'Enter a precaution',
        informeMedicamento: 'Inform a drug',
        buscarPrincipioAtivo: 'Search for an active ingredient',
        medicamentoCadastradoSucesso: 'Medicine successfully registered!',
        medicamentoRemovidoSucesso: 'Drug successfully removed!',
        tipoSanguineo: 'Blood type',
        tipoSanguineoCadastradoSucesso: 'Blood type successfully registered!',
        tipoSanguineoRemovidoSucesso: 'Blood type successfully removed!'
      }
    },
    clientes: {
      titulo: 'Customers',
      novo: 'New customer',
      cadastradoSucesso: 'Successfully registered customer!',
      atualizadoSucesso: 'Customer updated successfully!',
      removidoSucesso: 'Customer successfully removed!',
      principaisDados: 'Key customer data',
      historico: {
        disciplina: {
          cadastradoSucesso: 'Subject successfully registered!',
          removidoSucesso: 'Subject successfully removed!'
        }
      },
      necessidadesEspeciais: {
        titulo: 'Special needs',
        novo: 'New Special Need',
        adicionar: 'Add special need',
        cadastradoSucesso: 'Special need successfully registered!',
        atualizadoSucesso: 'Successfully updated special need!',
        removidoSucesso: 'Special need successfully removed!',
        necessidadeTemporaria: 'Temporary need',
        terminoDaNecessidadeTemporaria: 'End of need',
        selecionarNecessidadeEspecial: 'Select or register a need'
      },
      dadosClinicos: {
        precaucoes: 'Precautions',
        alergiaMedicamentosa: 'Drug allergy',
        medicamentoUsoContinuo: 'Continuous use medications',
        principioAtivo: 'Active principle',
        informePrecaucao: 'Enter a precaution',
        informeMedicamento: 'Inform a drug',
        buscarPrincipioAtivo: 'Search for an active ingredient',
        medicamentoCadastradoSucesso: 'Drug successfully registered!',
        medicamentoRemovidoSucesso: 'Drug successfully removed!',
        tipoSanguineo: 'Tipo sanguíneo',
        tipoSanguineoCadastradoSucesso: 'Blood type successfully registered!',
        tipoSanguineoRemovidoSucesso: 'Blood type successfully removed!',
        alergiasMedicamentosas: {
          titulo: 'Drug Allergies',
          novo: 'New drug allergy',
          adicionar: 'Add drug allergy',
          cadastradoSucesso: 'Drug allergy successfully registered!',
          atualizadoSucesso: 'Drug allergy successfully updated!',
          removidoSucesso: 'Drug allergy successfully removed!'
        }
      },
      financeiro: {
        orcamento: {
          novo: 'new budget'
        },
        contrato: {
          novo: 'New contract'
        }
      },
      filtros: {
        campoPesquisa: 'Search by Name, CPF or Date of Birth',
        textoAjudaPesquisa: 'Use a comma to search for more than one piece of information, for example: "Maria da Silva, 01/01/1988"',
        textoAjudaPesquisaCnpj: 'Use a comma to search for more than one piece of information, for example: "Maria da Silva, 578.257.040-70"'
      }
    },
    funcionarios: {
      titulo: 'Employees',
      novo: 'New employee',
      cadastradoSucesso: 'Successfully registered employee!',
      atualizadoSucesso: 'Employee successfully updated!',
      removidoSucesso: 'Employee successfully removed!',
      principaisDados: 'Employee Key Data',
      especialidades: {
        titulo: 'Specialty Data',
        deletadoSucesso: 'Successfully removed specialty',
        criadoSucesso: 'Successfully added specialty',
        registro: 'Register number',
        novo: 'New specialty',
        adicionar: 'Add specialty',
        subespecialidades: {
          adicionar: 'Add subspecialty',
          adicionadaSucesso: 'Subspecialty successfully added',
          removidaSucesso: 'Subspecialty successfully removed'
        }
      },
      necessidadesEspeciais: {
        titulo: 'Special needs',
        novo: 'New special need',
        adicionar: 'Add special need',
        cadastradoSucesso: 'Special need successfully registered!',
        atualizadoSucesso: 'Successfully updated special need!',
        removidoSucesso: 'Special need successfully removed!',
        necessidadeTemporaria: 'Temporary need',
        terminoDaNecessidadeTemporaria: 'End of need',
        selecionarNecessidadeEspecial: 'Select or register a need'
      },
      dadosClinicos: {
        precaucoes: 'Precautions',
        alergiaMedicamentosa: 'Drug allergy',
        medicamentoUsoContinuo: 'Continuous use medications',
        principioAtivo: 'Active principle',
        informePrecaucao: 'Enter a precaution',
        informeMedicamento: 'Inform a drug',
        buscarPrincipioAtivo: 'Search for an active ingredient',
        medicamentoCadastradoSucesso: 'Drug successfully registered!',
        medicamentoRemovidoSucesso: 'Drug successfully removed!',
        tipoSanguineo: 'Blood type',
        tipoSanguineoCadastradoSucesso: 'Blood type successfully registered!'
      },
      financeiro: {
        honorarios: {
          novo: 'Add fee'
        },
        contasBancarias: {
          novo: 'Add bank account'
        },
        comissoes: {
          nomeConfiguracaoComissao: 'Name of commission configuration',
          tipoCalculoComissao: 'Type of commission calculation',
          tipoPagamentoComissao: 'Type of commission payment',
          tipoVencimentoComissao: 'Type of commission due',
          nenhumaComissaoParaFuncionario: 'There is no commission calculated for this setup and employee',
          configuracaoDeComissao: 'Commission Setup',
          configuracoesDeComissao: 'Commission Settings',
          novaConfiguracaoDeComissao: 'New commission setting',
          comissaoRemovidaSucesso: 'Commission setting successfully removed',
          comissaoCadastradaSucesso: 'Successfully set up commission registered'
        }
      },
      termosDeUso: {
        titulo: 'Sponte s Terms of Use',
        revogarTermo: 'Revoke term',
        motivoRevogacao: 'Revocation reason',
        conteudoTermoAceite: 'Upon revoking the acceptance of the term, you will be disconnected from Sponte. To accept it again, just login to the system. do you wish to continue?',
        concordoTermo: 'I read and agree with the terms'
      }
    },
    preferencias: {
      alergias: {
        titulo: 'General allergies',
        novo: 'New Allergy',
        adicionar: 'Add allergy',
        cadastradoSucesso: 'Allergy successfully registered!',
        atualizadoSucesso: 'Allergy updated successfully!',
        removidoSucesso: 'Allergy successfully removed!'
      },
      cargos: {
        titulo: 'Positions',
        novo: 'new position',
        cadastradoSucesso: 'Position successfully registered!',
        atualizadoSucesso: 'Position successfully updated!',
        removidoSucesso: 'Cargo removido com sucesso!'
      },
      detalhesPersonalizados: {
        titulo: 'Custom Details',
        novo: 'New custom detail',
        personalize: 'Customize your calendar',
        cadastradoSucesso: 'Schedule type successfully registered!',
        atualizadoSucesso: 'Schedule type updated successfully!',
        removidoSucesso: 'Schedule type successfully removed!'
      },
      condicoesDePagamento: {
        assinaturaRealizadaSucesso: 'Subscription successfully completed',
        assinaturaAlteradaSucesso: 'Signature successfully changed',
        alterarCartaoCreditoRecorrencia: 'Change credit card on recurrence',
        relacionar: 'List payment condition',
        campoPesquisa: 'Search payment condition',
        relacionadaSucesso: 'Success-Related Recurring Payment Condition',
        deletadaSucesso: 'Successfully deleted recurring payment condition'
      },
      documentos: {
        modelosDeDocumentos: {
          titulo: 'Document Templates',
          novo: 'New Document Template',
          cadastradoSucesso: 'Document template successfully registered!',
          cadastradoErro: 'An error occurred when registering the document template!',
          atualizadoSucesso: 'Document template updated successfully!',
          atualizadoErro: 'An error occurred while updating the document template',
          removidoSucesso: 'Document template removed successfully!',
          removidoErro: 'An error occurred while removing the document template!',
          novoDocumento: 'New document',
          adicionarCampo: 'Add field',
          adicionarAnexo: 'Add attachment',
          criadoPor: 'Created by',
          dataCriacao: 'Creation date',
          alteradoPor: 'Changed by',
          dataAlteracao: 'Change date',
          normal: 'Normal',
          tituloEditor: 'Title',
          subtitulo: 'Caption',
          citacao: 'Quote',
          desejaSalvarAntesDeSair: 'Do you want to save changes before exiting?'
        },
        gruposDeDocumentos: {
          titulo: 'Document groups',
          novo: 'New group of documents',
          cadastradoSucesso: 'Document group successfully registered!',
          cadastradoErro: 'Failed to register document group!',
          atualizadoSucesso: 'Document group updated successfully!',
          atualizadoErro: 'Failed to update document group!',
          removidoSucesso: 'Document group successfully removed!',
          removidoErro: 'Failed to remove document group!'
        }
      },
      especialidades: {
        titulo: 'Specialties',
        novo: 'New specialty',
        nomeDaEspecialidadePai: 'Parent specialty name',
        nomeDaSubespecialidade: 'Subspecialty name',
        cadastradoSucesso: 'Successfully registered specialty!',
        atualizadoSucesso: 'Successfully updated specialty!',
        removidoSucesso: 'Successfully removed specialty!',
        subespecialidades: {
          titulo: 'Subspecialties',
          novo: 'New subspecialty',
          nomeDaSubespecialidade: 'Subspecialty name',
          cadastradoSucesso: 'Subspecialty successfully registered!',
          atualizadoSucesso: 'Subspecialty successfully updated!',
          removidoSucesso: 'Subspecialty successfully removed!'
        }
      },
      funcoes: {
        titulo: 'Roles',
        novo: 'New role',
        cadastradoSucesso: 'Function registered successfully!',
        atualizadoSucesso: 'Function updated successfully!',
        removidoSucesso: 'Function removed successfully!'
      },
      necessidadesEspeciais: {
        titulo: 'Special needs',
        novo: 'New special need',
        adicionar: 'Add special need',
        cadastradoSucesso: 'Special need successfully registered!',
        atualizadoSucesso: 'Successfully updated special need!',
        removidoSucesso: 'Special need successfully removed!',
        necessidadeTemporaria: 'Temporary need',
        terminoDaNecessidadeTemporaria: 'End of need',
        selecionarNecessidadeEspecial: 'Select or register a need',
        tipos: {
          titulo: 'Types of Special Needs',
          novo: 'New type of special need',
          cadastradoSucesso: 'Type of special need successfully registered!',
          atualizadoSucesso: 'Disability type successfully updated!',
          removidoSucesso: 'Disability type successfully removed!'
        },
        recursos: {
          titulo: 'Special Needs Resources',
          novo: 'New Special Needs Feature',
          cadastradoSucesso: 'Successfully registered special needs resource!',
          atualizadoSucesso: 'Successfully updated special needs resource!',
          removidoSucesso: 'Disability resource successfully removed!'
        }
      },
      precaucoes: {
        titulo: 'Precautions',
        novo: 'New precaution',
        adicionar: 'Add caution',
        cadastradoSucesso: 'Precaution successfully registered!',
        atualizadoSucesso: 'Caution updated successfully!',
        removidoSucesso: 'Caution removed successfully!'
      },
      tags: {
        titulo: 'Tags',
        novo: 'New tag',
        cadastradoSucesso: 'Tag registered successfully!',
        atualizadoSucesso: 'Tag updated successfully!',
        removidoSucesso: 'Tag removed successfully!',
        modulosRelacionados: {
          titulo: 'Related modules',
          novo: 'New related module',
          cadastradoSucesso: 'Related module successfully registered!',
          removidoSucesso: 'Related module successfully removed!'
        }
      },
      tiposResponsavel: {
        titulo: 'Types of responsible',
        novo: 'New type of responsible',
        cadastradoSucesso: 'Type of responsible person successfully registered!',
        atualizadoSucesso: 'Responsible type updated successfully!',
        removidoSucesso: 'Responsible type successfully removed!'
      },
      gruposCadastroPersonalizado: {
        titulo: 'Custom groups',
        novo: 'New',
        cadastradoSucesso: 'Successfully registered personalized group',
        atualizadoSucesso: 'Customized group successfully updated',
        removidoSucesso: 'Custom group successfully removed',
        disponibilidade: 'Availability',
        moduloRelacionar: 'Select a module to list',
        moduloAdicionadoSucesso: 'Module successfully added',
        moduloRemovidoSucesso: 'Module removed successfully',
        adicioneCampos: 'Add fields',
        tipoCampo: 'Field type',
        campoAdicionadoSucesso: 'Field added successfully',
        campoRemovidoSucesso: 'Field successfully removed',
        adicionarOpcoes: 'Add options',
        adicionarItem: 'Add item',
        itemAdicionadoSucesso: 'Item successfully added',
        itemRemovidoSucesso: 'Item removed successfully'
      },
      motivosCancelamento: {
        titulo: 'Reasons for cancellation ',
        novo: 'New reason',
        editar: 'Edit reason ',
        disponibilidade: 'Availability',
        disponivelEm: 'Available in',
        cadastradoSucesso: 'Reason registered successfully!',
        atualizadoSucesso: 'Reason updated successfully!',
        removidoSucesso: 'Reason successfully removed!'
      }
    },
    pessoa: {
      comercial: {
        oportunidades: 'Opportunities',
        funil: 'Funnel',
        fase: 'Phase',
        proximoContato: 'Next contact',
        ultimoContato: 'Last contact',
        novaOportunidade: 'New opportunity',
        acompanhamentos: 'Accompaniments',
        novoAcompanhamento: 'New follow-up',
        comentario: 'Comment',
        usuario: 'User',
        data: 'Date',
        hora: 'Hour',
        tarefa: 'Assignment',
        tipo: 'Type'
      }
    },
    liberacaoDeModulos: {
      titulo: 'Module Release',
      atualizadoSucesso: 'Module release updated successfully!'
    }
  },
  agenda: {
    calendario: {
      titulo: 'Calendars',
      novo: 'New calendar',
      cadastradoSucesso: 'Calendar successfully registered!',
      atualizadoSucesso: 'Calendar updated successfully!',
      removidoSucesso: 'Calendar removed successfully!'
    },
    preferencias: {
      configuracoesDeCalendarios: {
        titulo: 'Calendar Settings',
        novo: 'New Calendar Setup',
        cadastradoSucesso: 'Calendar setup successfully registered!',
        atualizadoSucesso: 'Calendar setup updated successfully!',
        removidoSucesso: 'Calendar setting removed successfully!',
        copiadoSucesso: 'Calendar setup successfully copied!',
        calendario: 'Calendar',
        feriadosEEventos: 'Holidays and Events',
        duracaoDeEventos: 'Duration of events',
        personalize: 'Customize your calendar',
        informacoes: 'Calendar Information',
        usarCalendarioPadrao: 'Use default calendar',
        duracoes: {
          nova: 'New duration',
          cadastradoSucesso: 'Duration registered successfully!',
          atualizadoSucesso: 'Duration updated successfully!',
          removidoSucesso: 'Duration successfully removed!'
        },
        feriados: {
          novo: 'New date',
          cadastradoSucesso: 'Successfully registered holiday!',
          atualizadoSucesso: 'Holiday updated successfully!',
          removidoSucesso: 'Holiday removed successfully!',
          periodos: {
            titulo: 'Time course',
            novo: 'Add period',
            editar: 'Edit period',
            cadastradoSucesso: 'Successfully registered period!',
            atualizadoSucesso: 'Period updated successfully!',
            removidoSucesso: 'Period successfully removed!'
          }
        },
        horariosDeFuncionamento: {
          titulo: 'Opening hours',
          novo: 'New opening hours',
          cadastradoSucesso: 'Opening hours successfully registered!',
          atualizadoSucesso: 'Opening hours successfully updated!',
          removidoSucesso: 'Opening hours successfully removed!',
          periodos: {
            titulo: 'Time settings',
            novo: 'New time setting',
            editar: 'Edit time setting',
            cadastradoSucesso: 'Time setting successfully registered!',
            atualizadoSucesso: 'Time setting successfully updated!',
            removidoSucesso: 'Time setting successfully removed!',
            tipoFaltaDisciplina: 'Type of absence by discipline',
            tipoFaltaDia: 'Type of absence per day',
            intervalos: {
              cadastradoSucesso: 'Interval registered successfully!',
              atualizadoSucesso: 'Interval updated successfully!',
              removidoSucesso: 'Range removed successfully!',
              titulo: 'Break',
              novo: 'New Interval',
              adicionar: 'Add range'
            }
          }
        },
        periodos: {
          titulo: 'Time settings',
          novo: 'New time setting',
          editar: 'Edit time setting',
          cadastradoSucesso: 'Time setting successfully registered!',
          atualizadoSucesso: 'Time setting successfully updated!',
          removidoSucesso: 'Time setting successfully removed!'
        },
        recursos: {
          novoRecurso: 'New feature',
          adicionarRecursos: 'Add resources',
          professor: 'Teacher',
          equipamento: 'Equipment'
        }
      }
    }
  },
  contabil: {
    centroDeCustos: {
      titulo: 'Cost center',
      novo: 'New cost center',
      cadastradoSucesso: 'Successfully registered cost center!',
      atualizadoSucesso: 'Successfully updated cost center!',
      removidoSucesso: 'Cost center successfully removed!',
      editarCentroDeCustos: 'Edit cost center',
      selecionePlanoDeContas: 'Select a chart of accounts',
      nome: 'Cost center name'
    },
    dre: {
      receitasVendasBrutas: 'Gross sales revenue',
      gastosDespesas: 'Expenses and expenses',
      resultadoOperacional: 'Operational result',
      divisaoReceitas: 'Revenue division',
      divisaoDespesas: 'Expense division',
      resultadoExercicio: 'Result of the year',
      estrutura: 'Structure',
      planejado: 'Planned',
      realizado: 'Accomplished',
      variacao: 'Variation',
      competencia: 'Competence',
      caixa: 'Box',
      regime: 'Regimen',
      preDefinido: 'Predefined',
      colunas: 'Columns',
      aplicar: 'To apply',
      periodo: 'Time course',
      de: 'In',
      ate: 'Until',
      exibeRegimePor: 'Displays regimen by:',
      feedbacks: {
        naoTemLancamentoPlanoConta: 'There s nothing released in this account plan.',
        semDadosNessePeriodo: 'No data in this period'
      }
    },
    planoDeContas: {
      titulo: 'Charts of Accounts',
      novo: 'New chart of accounts',
      cadastradoSucesso: 'Chart of accounts successfully registered!',
      atualizadoSucesso: 'Chart of accounts successfully updated!',
      removidoSucesso: 'Chart of accounts successfully removed!',
      editarPlanoDeContas: 'Edit chart of accounts'
    }
  },
  financeiro: {
    caixa: {
      titulo: 'Daily cash',
      novo: 'New release',
      editar: 'Edit release',
      cadastradoSucesso: 'Launch registered successfully!',
      atualizadoSucesso: 'Launch updated successfully!',
      removidoSucesso: 'Release successfully removed!',
      tipoMovimento: {
        SANGRIA: 'Withdrawal',
        ABERTURA: 'Opening',
        FECHAMENTO: 'Closure',
        SUPRIMENTO: 'Supply',
        TITULO_PAGAR: 'Payment',
        TITULO_RECEBER: 'Receivement',
        ESTORNO_TITULO_PAGAR: 'Payment reversal',
        ESTORNO_TITULO_RECEBER: 'Receipt reversal',
        CONSOLIDACAO: 'Consolidation',
        ESTORNO_CONSOLIDACAO: 'Consolidation Reversal'
      }
    },
    caixaGeral: {
      titulo: 'General box',
      semRegistros: 'Open a box for it to be displayed here.',
      semMovimentos: 'Make a move to have it displayed here.',
      desfazerConsolidacao: 'Undo Consolidation',
      desfazerConsolidacaoPermanentemente: 'Undo this consolidation permanently',
      desfazer: 'Undo',
      desejaDesfazer: 'really want to undo?',
      caixaConsolidadoSucesso: 'Successfully consolidated cash!',
      movimentoConsolidadoSucesso: 'Successfully consolidated movement!',
      consolidacaoDesfeitaSucesso: 'Consolidation successfully undone!',
      contaDestino: 'Destination account',
      caixaFechadoSucesso: 'Successfully closed box!',
      caixaRetiradoSucesso: 'Withdrawal successful!',
      caixaSupridoSucesso: 'Successfully supplied box!',
      caixasComFechamentoDe: 'Boxes with closing of',
      movimentosComValorDe: 'Movements worth',
      formasPagamentoFechamentoCaixa: 'Methods of payment for closing cash',
      caixaNaoPossuiMovimento: 'The cashier has no movement!',
      caixasNaoPossuemMovimento: 'Cashiers have no movement!',
      numeroDoCaixa: 'Cash No.',
      feedbacks: {
        somenteTituloPodemSerSelecionados: 'Only titles can be selected.!',
        somenteTitulosStatusConsolidacaoPodemSerSelecionado: 'Only bonds with a consolidation status can be selected.!',
        somenteTitulosStatusDiferenteConsolidacaoPodemSerSelecionados: 'Only bonds with different consolidation status can be selected.!',
        estornosTitulosNaoPodemSerConsolidados: 'Title reversals cannot be consolidated!'
      }
    },
    compras: {
      orcamentos: {
        titulo: 'Purchase budgets',
        novo: 'New purchase budget',
        editar: 'Edit budget',
        cadastradoSucesso: 'Budget registered successfully!',
        atualizadoSucesso: 'Budget updated successfully!',
        removidoSucesso: 'Budget removed successfully!',
        registradoComSucesso: 'Budget carried out successfully!',
        desejaEfetivarOrcamento: 'Do you want to make this budget effective?',
        efetivarOrcamento: 'Make budget',
        efetivados: 'Effective',
        pendentes: 'Outstanding',
        nenhumCadastro: 'No quotes found!'
      },
      entradas: {
        titulo: 'Appetizer',
        novo: 'New entry',
        editar: 'Edit entry',
        cadastradoSucesso: 'Successfully registered entry!',
        atualizadoSucesso: 'Input updated successfully!',
        removidoSucesso: 'Entry successfully removed!',
        tituloGeradoComSucesso: 'Title(s) successfully generated!',
        estornadoSucesso: 'Entry reversed successfully!',
        nenhumCadastro: 'No entries found!'
      },
      contasAPagar: {
        titulo: 'Bills to pay',
        novo: 'New Account Payable',
        editar: 'Edit account payable',
        cadastradoSucesso: 'Account successfully registered!',
        atualizadoSucesso: 'Account successfully updated!',
        removidoSucesso: 'Account successfully removed!',
        quitar: 'Pay off account',
        estornar: 'Reverse account',
        subTotalAdiantamento: 'Advance Subtotal',
        subTotalAcrescimo: 'Subtotal of the addition',
        subTotalDesconto: 'Discount subtotal',
        saldoConta: 'Account balance',
        estornadoSucesso: 'Account payable successfully reversed!',
        nenhumCadastro: 'No accounts payable found!',
        charts: {
          quitadas: 'Paid off',
          pendentes: 'Outstanding',
          vencidas: 'Overdue',
          canceladas: 'Canceled'
        }
      }
    },
    vendas: {
      orcamentos: {
        titulo: 'Sales budgets',
        novo: 'New sales budget',
        editar: 'Edit budget',
        cadastradoSucesso: 'Budget registered successfully!',
        atualizadoSucesso: 'Budget updated successfully!',
        removidoSucesso: 'Budget removed successfully!',
        registradoComSucesso: 'Budget removed successfully!',
        desejaEfetivarOrcamento: 'Do you want to make this budget effective?',
        efetivarOrcamento: 'Make budget',
        nenhumCadastro: 'No quote found!',
        efetivados: 'Effective',
        pendentes: 'Outstanding',
        composicao: {
          titulo: 'Composition',
          novo: 'new composition',
          editar: 'Edit composition',
          cadastradoSucesso: 'Membership successfully registered!',
          atualizadoSucesso: 'Composition successfully updated!',
          removidoSucesso: 'Composition successfully removed!',
          item: 'Item',
          subTotalComCondicoes: 'Subtotal with conditions',
          totalComposicaoComCondicao: 'Total composition with condition',
          totalComposicao: 'Total composition',
          condicoes: {
            titulo: 'Payment conditions',
            novo: 'Manage conditions',
            cadastradoSucesso: 'Payment condition registered successfully!',
            atualizadoSucesso: 'Payment Terms updated successfully!',
            removidoSucesso: 'Payment Terms removed successfully!',
            descontoDeletadoSucesso: 'Discount successfully deleted',
            semDados: 'Item with no payment terms',
            semDadosDescricao: 'Add at least one payment term for the item.',
            descontoAvulso: 'Single discount',
            tipoDesconto: 'Type of discount',
            saldoDasParcelas: 'Balance of installments',
            saldoDoItem: 'Item balance',
            saldoTotalParcelas: 'Total balance of installments',
            totalDesconto: 'Total discount',
            valorDesconto: 'Discount amount',
            valorDescontoAvulso: 'Single discount amount',
            totalJuros: 'Total Interest',
            totalMulta: 'Total Penalty',
            totalDeAcrescimoDaCondicaoPagamento: 'Total addition to the payment condition',
            listaDescontos: 'Discount list',
            descontosAdicionadoSucesso: 'Discount added successfully',
            descontoPontualidade: 'On-time discounte',
            descontoConvenio: 'Insurance discount',
            configuracaoConvenio: 'agreement configuration',
            configuracaoDesconto: 'Discount setup'
          }
        }
      },
      saidas: {
        titulo: 'Departures',
        novo: 'New exit',
        editar: 'Edit output',
        cadastradoSucesso: 'Successfully registered exit!',
        atualizadoSucesso: 'Output updated successfully!',
        removidoSucesso: 'Output removed successfully!',
        registradoComSucesso: 'Title(s) successfully generated!',
        desejaGerarTitulos: 'Want to generate titles?',
        gerarTitulos: 'Generate titles',
        nenhumCadastro: 'No output found!',
        nfSucesso: 'Invoice generation request successfully made!',
        composicao: {
          titulo: 'Composição',
          novo: 'New composition',
          editar: 'Edit composition',
          cadastradoSucesso: 'Membership successfully registered!',
          atualizadoSucesso: 'Composition successfully updated!',
          removidoSucesso: 'Composition successfully removed!',
          item: 'Item',
          subTotalComCondicoes: 'Subtotal with conditions',
          condicoes: {
            titulo: 'Payment conditions',
            novo: 'Manage conditions',
            atualizadoSucesso: 'Conditions updated successfully!',
            semDados: 'Item with no payment terms',
            semDadosDescricao: 'Add at least one payment term for the item.'
          }
        }
      },
      contasAReceber: {
        titulo: 'Bills to receive',
        novo: 'New account receivable',
        editar: 'Edit account receivable',
        cadastradoSucesso: 'Account successfully registered!',
        atualizadoSucesso: 'Account successfully updated!',
        removidoSucesso: 'Account successfully removed!',
        quitar: 'Pay off account',
        estornar: 'Reverse account',
        subTotalAdiantamento: 'Advance Subtotal',
        subTotalAcrescimo: 'Subtotal of the addition',
        subTotalDesconto: 'Discount Subtotal',
        saldoConta: 'Account balance',
        quitarSelecionados: 'Pay off selected',
        configuracaoCartao: 'Card configuration',
        nenhumCadastro: 'No accounts receivable found!',
        checkout: {
          ambienteSeguro: 'Safe environment',
          nomeCartaoDeCredito: 'Name (same on card)',
          cvvCartaoDeCredito: 'CVV',
          medpay: 'Medpay Checkout',
          spontepay: 'Spontepay Checkout',
          totalAPagar: 'Total to pay'
        },
        charts: {
          quitadas: 'Paid off',
          pendentes: 'Outstanding',
          vencidas: 'Overdue',
          canceladas: 'Canceled'
        }
      }
    },
    tesouraria: {
      boletos: {
        titulo: 'Ticket control',
        editar: 'Boleto details',
        cadastradoSucesso: 'Billet generated successfully!',
        atualizadoSucesso: 'Ticket updated successfully!',
        removidoSucesso: 'Boleto successfully removed!',
        feedbackSecundario: 'Generate a bill of exchange from an account receivable.',
        configuracoes: {
          titulo: 'Billet Settings',
          atualizadoSucesso: 'Billet settings updated successfully!',
          diasVencimento: 'Days before expiration',
          diasValor: 'Days to confirm value',
          valorTarifa: 'Fare amount',
          lancarValorTarifa: 'Post fare amount as output',
          prazoMaximoRecebimento: 'Deadline in days for receipt',
          operacaoPadrao: 'Standard operation'
        },
        desejaCancelar: 'Do you really want to cancel the bank slip?'
      },
      controleDeCheques: {
        titulo: 'Check control',
        novo: 'New',
        editar: 'To edit',
        cadastradoSucesso: 'Successfully registered check!',
        atualizadoSucesso: 'Check updated successfully!',
        removidoSucesso: 'Check successfully removed!',
        numeroDoCheque: 'Check number',
        dataCompensacao: 'Compensation date',
        emitente: 'Issuer name',
        dadosDoCliente: 'Client s data'
      },
      controleDeCartoes: {
        pagador: 'payer',
        desejaEstornarConciliacaoBaixa: 'Do you wish to reverse the reconciliation of this write-off?',
        conciliacaoSucesso: 'Conciliation successfully performed',
        estornoSucesso: 'Reversal performed successfully',
        estornar: 'Reverse',
        pesquisar: 'Search by Name and CPF',
        charts: {
          totalTransacoes: 'Total Transactions',
          conciliados: 'Reconciled',
          pendentes: 'Outstanding',
          estornos: 'Chargebacks',
          totalTaxas: 'Total Fees',
          saldo: 'Balance'
        }
      }
    },
    preferencias: {
      configuracoesDePagamentos: {
        formasDePagamento: {
          titulo: 'Payment methods',
          novo: 'New payment method',
          editar: 'Edit payment method',
          cadastradoSucesso: 'Payment method successfully registered!',
          atualizadoSucesso: 'Payment method successfully updated!',
          removidoSucesso: 'Payment method successfully removed!'
        },
        condicoesDePagamentos: {
          titulo: 'Payment Terms',
          novo: 'New payment term',
          cadastradoSucesso: 'Payment condition registered successfully!',
          atualizadoSucesso: 'Payment condition updated successfully!',
          removidoSucesso: 'Payment condition removed successfully!',
          restricoes: {
            novo: 'New restriction',
            cadastradoSucesso: 'Restriction successfully registered!',
            removidoSucesso: 'Constraint successfully removed!'
          }
        }
      },
      configuracoesDeCartoes: {
        configuracoesDeCartoes: {
          titulo: 'Card Settings',
          novo: 'New configuration',
          editar: 'Edit configuration',
          cadastradoSucesso: 'Successfully registered card configuration!',
          atualizadoSucesso: 'Card configuration updated successfully!',
          removidoSucesso: 'Card configuration removed successfully!',
          geraContaPagarTaxa: 'Generate account to pay fee',
          compensaVencimento: 'Compensates on maturity',
          naoCompensaVencimento: 'Does not pay off on maturity',
          configuracao: {
            titulo: 'Card Settings',
            adicionar: 'Add configuration',
            novo: 'New card configuration'
          }
        },
        configuracoesDeBandeiras: {
          titulo: 'Flag Settings',
          novo: 'New flag',
          cadastradoSucesso: 'Flag successfully registered',
          atualizadoSucesso: 'Flag updated successfully',
          removidoSucesso: 'Flag successfully removed'
        },
        configuracoesDeCredenciadoras: {
          titulo: 'Accreditor Settings',
          novo: 'new acquirer',
          cadastradoSucesso: 'Accreditor registered successfully',
          atualizadoSucesso: 'Accreditor updated successfully',
          removidoSucesso: 'Accreditor successfully removed'
        }
      },
      configuracoesDeDescontos: {
        configuracoesDeDescontos: {
          titulo: 'Discount Settings',
          novo: 'New discount setup',
          cadastradoSucesso: 'Successfully registered discount setup!',
          atualizadoSucesso: 'Discount configuration updated successfully!',
          removidoSucesso: 'Discount setting successfully removed!',
          formaDePagamento: {
            titulo: 'Payment methods',
            novo: 'New payment method',
            cadastradoSucesso: 'Payment method successfully registered!',
            removidoSucesso: 'Payment method successfully removed!'
          },
          descontos: {
            titulo: 'Discounts',
            novo: 'New discount',
            editar: 'Edit discount',
            cadastradoSucesso: 'Discount registered successfully!',
            atualizadoSucesso: 'Discount updated successfully!',
            removidoSucesso: 'Discount successfully removed!'
          }
        },
        configuracoesDeBolsa: {
          titulo: 'Bag settings',
          novo: 'New bag configuration',
          cadastradoSucesso: 'Setting up a successful registered exchange!',
          atualizadoSucesso: 'Successfully updated bag configuration!',
          removidoSucesso: 'Bag configuration removed successfully!'
        },
        configuracoesDeConvenios: {
          titulo: 'Insurance Settings',
          novo: 'New agreement configuration',
          cadastradoSucesso: 'Configuration of agreement registered successfully!',
          atualizadoSucesso: 'Agreement setup successfully updated!',
          removidoSucesso: 'Agreement setting removed successfully!',
          formaDePagamento: {
            titulo: 'Payment methods',
            novo: 'New payment method',
            formaDePagamentoUtilizada: 'This payment method is already being used!',
            cadastradoSucesso: 'Payment method successfully registered!',
            removidoSucesso: 'Payment method successfully removed!'
          },
          desconto: {
            titulo: 'Discount',
            novo: 'New discount',
            editar: 'Edit discount',
            cadastradoSucesso: 'Discount registered successfully!',
            atualizadoSucesso: 'Discount updated successfully!',
            removidoSucesso: 'Discount successfully removed!'
          }
        }
      },
      operacoes: {
        titulo: 'Operations',
        novo: 'New operation',
        editar: 'Edit operation',
        cadastradoSucesso: 'Operation registered successfully',
        atualizadoSucesso: 'Operation updated successfully',
        removidoSucesso: 'Operation removed successfully',
        selecioneCentroDeCustos: 'Select a cost center',
        centroDeCustosUtilizado: 'This cost center is already being used!'
      },
      configuracoesDeComissoes: {
        titulo: 'Commission settings',
        novo: 'New commission setting',
        cadastradoSucesso: 'Successfully set up commission registered!',
        atualizadoSucesso: 'Successfully updated commission setup!',
        removidoSucesso: 'Commission setting successfully removed!',
        metas: {
          titulo: 'Goals',
          novo: 'New goal',
          editar: 'Edit goal',
          cadastradoSucesso: 'Successfully registered goal!',
          atualizadoSucesso: 'Target updated successfully!',
          removidoSucesso: 'Target removed successfully!'
        }
      },
      bancosEContas: {
        titulo: 'Banks and Accounts',
        novo: 'New account',
        cadastradoSucesso: 'Successfully registered bank account!',
        atualizadoSucesso: 'Bank account updated successfully!',
        removidoSucesso: 'Bank account successfully removed!',
        responsaveis: {
          adicionarResponsavel: 'Add responsible',
          novoResponsavel: 'New responsible',
          nome: 'Name of responsible',
          tipoResponsavel: 'Responsible Type',
          cadastradoSucesso: 'Responsible registered successfully!',
          removidoSucesso: 'Responsible removed successfully!'
        }
      }
    },
    faturas: {
      dadosDaFatura: 'Invoice Data',
      outrosServicos: 'Other services',
      naoCompoeFatura: 'Does not make up the invoice',
      transacoesEfetuadas: 'Transactions made in the period',
      titulo: 'Invoices',
      codigoCliente: 'Customer code',
      situacao: 'Situation',
      mes: 'Month',
      ano: 'Year',
      valorFatura: 'Invoice amount',
      servicosUtilizados: 'Services used',
      atualizadoSucesso: 'Invoice updated successfully!',
      empresa: 'Company',
      servicos: 'services',
      servico: 'Service',
      quantidade: 'Quantity used',
      valorTotal: 'Amount',
      detalhes: 'Details',
      dataEmissao: 'Issue date',
      numeroBoleto: 'Boleto No.',
      numeroFiscal: 'Invoice No.',
      dataQuitacao: 'Discharge date',
      valorRecebido: 'Amount received',
      multa: '% traffic ticket',
      tipoRecebimento: 'Receipt Type',
      conta: 'Account',
      mensalidade: 'Monthly payment',
      configuradas: 'Configured licenses',
      ativas: 'Active licenses on base',
      valorLicenca: 'License fee',
      valorMensalidade: 'Monthly fee',
      resumo: 'Summary',
      valorTotalServicos: 'Total value of services',
      valorTotalMensalidade: 'Total monthly fee',
      valorTotalFatura: 'Total invoice amount',
      servicosEmpresa: 'Service / Company',
      qtdUtilizada: 'Quantity used / Exemption amount',
      valorTarifa: 'Fee amount / Amount',
      detalhesFatura: 'Invoice Details',
      dialogTexto: 'There is a previous open invoice for this customer. Do you really want to close this invoice?',
      naoHaServicosCadastrados: 'There are no services registered for this invoice',
      fechamentoFatura: 'Invoice closing',
      vencimentoFatura: 'Invoice due',
      valorServicos: 'Values of services'
    },
    mensalidades: {
      titulo: 'Tuition',
      atualizadoSucesso: 'Monthly tuition successfully updated!',
      tarifasMensalidade: 'Monthly fees',
      tarifasArmazenamento: 'Storage fees',
      valorMinimoMensalidade: 'Minimum monthly fee',
      tarifaPadraoArmazenamento: 'Value per GB'
    }
  },
  banking: {
    dashboard: {
      contatosBancarios: 'bank contacts',
      paraComecarConfigure: 'To get started, set up your bank account',
      concederPermissao: 'Grant permission',
      ultimosXDias: 'Last(s) {{dias}} days',
      voceNaoTemTransacoesNessePeriodo: 'You have no transactions in this period',
      movimentos: 'Movements',
      operacaoContato: 'Operation and contact',
      codigoAutenticacao: 'Authentication code'
    },
    contatosBancarios: {
      titulo: 'Bank contacts',
      novo: 'New contact'
    },
    movimentos: {
      novoMovimento: 'New movement',
      confirmarMovimento: 'Confirm movement',
      desejaRealmenteMovimentarValor: 'Do you really want to move this value?',
      selecionarTitulo: 'Select title'
    }
  },
  fiscal: {
    preferencias: {
      tributacoes: {
        titulo: 'Taxation',
        novo: 'New taxation',
        cadastradoSucesso: 'Taxation successfully registered!',
        atualizadoSucesso: 'Taxation updated successfully!',
        removidoSucesso: 'Taxation successfully removed!',
        cnae: {
          novo: 'New CNAE',
          adicionar: 'Add CNAE',
          codigoServicoPrestado: 'Code Service provided',
          codigoServicoMunicipal: 'Municipal Service Code',
          codigoTributacaoMunicipio: 'Municipal Taxation Code',
          codigoServicoFederal: 'Federal Service Code',
          servPrestado: 'Service provided',
          servMunicipal: 'Service Municipal',
          servFederal: 'Service Federal',
          cadastradoSucesso: 'CNAE successfully registered!',
          atualizadoSucesso: 'CNAE updated successfully!',
          removidoSucesso: 'CNAE successfully removed!'
        },
        aliquota: {
          aliquotaIss: 'ISS rate',
          aliquotaPis: 'PIS rate',
          aliquotaCofins: 'COFINS rate',
          aliquotaInss: 'INSS rate'
        },
        configuracaoDaEmpresa: {
          aliquotaIr: 'IR rate',
          aliquotaCsll: 'CSLL rate'
        },
        outrasAliquotas: {
          aliquotasFederais: 'Federal Rates',
          aliquotasEstaduais: 'State Rates',
          aliquotasMunicipais: 'Municipal Rates'
        },
        codigoFiscalDePrestacaoDeServicos: {
          cfpsIntramunicipal: 'Intra-municipal CFPS',
          cfpsIntermunicipal: 'Intermunicipal CFPS'
        },
        cfop: {
          novo: 'New CFOP',
          adicionar: 'Add CFOP',
          cadastradoSucesso: 'CFOP successfully registered!',
          atualizadoSucesso: 'CFOP updated successfully!',
          removidoSucesso: 'CFOP removed successfully!'
        }
      }
    }
  },
  servicosContratacoes: {
    titulo: 'Services and Contracts'
  },
  configuracoes: {
    empresas: {
      titulo: 'Companies',
      novo: 'New company',
      cadastradoSucesso: 'Successfully registered company!',
      atualizadoSucesso: 'Company successfully updated!',
      removidoSucesso: 'Company successfully removed!',
      autorizacao: {
        titulo: 'Authorizations',
        novo: 'New authorization',
        cadastradoSucesso: 'Authorization successfully registered!',
        atualizadoSucesso: 'Authorization updated successfully!',
        removidoSucesso: 'Authorization successfully removed!',
        autorizacaoDeFuncionamento: 'Operating permit',
        nucleoRegionalEducacao: 'Regional Education Center',
        fundamentacaoLegal: 'Legal substantiation',
        parecerCEE: 'CEE opinion',
        ldben: 'LDBEN',
        dou: 'DOU',
        codigoSEEDCDE: 'SEED code / CDE'
      },
      configuracao: {
        dataContratacao: 'The contract date',
        qtdeUsuarios: 'Number of users',
        valorTarifa: 'Fee amount',
        valorPorAluno: 'Value per student',
        valorPorMedico: 'Value per doctor',
        valorTarifaNegociado: 'Negotiated rate value',
        justificativa: 'Reason for rate change',
        valorMensalidade: 'Monthly fee',
        valorMinimoMensalidade: 'Minimum monthly fee',
        situacao: 'Situation',
        atributos: 'Attributes',
        endpoints: 'Endpoints',
        key: 'Key',
        value: 'Value',
        url: 'URL',
        name: 'Name',
        metod: 'Metod',
        integracoes: {
          titulo: 'Integrations',
          novaIntegracao: 'New integration',
          tarifa: 'tariff',
          qtdeInsencao: 'Exemption amount',
          transferenciaAutomatica: 'Automatic transfer (withdrawal)',
          tarifasStone: 'Stone rates',
          tarifasPraticadas: 'Rates practiced in the period',
          cadastradoSucesso: 'Integration registered successfully!',
          atualizadoSucesso: 'Integration updated successfully!',
          removidoSucesso: 'Integration removed successfully!',
          contasBancarias: {
            titulo: 'Bank account',
            novo: 'New bank account',
            editar: 'Edit bank account',
            cadastradoSucesso: 'Successfully registered bank account!',
            atualizadoSucesso: 'bank account updated successfully!',
            removidoSucesso: 'bank account successfully removed!',
            mensagem: 'When activating this account, the previous account will be deactivated.!'
          }
        }
      }
    },
    preferencias: {
      titulo: 'Preferences',
      atualizadoSucesso: 'Preference updated successfully!'
    },
    templates: {
      sms: {
        titulo: 'SMS Templates',
        novo: 'New SMS Template',
        cadastradoSucesso: 'Successfully registered SMS template!',
        atualizadoSucesso: 'SMS template updated successfully!',
        removidoSucesso: 'SMS template successfully removed!',
        escrevaMensagem: 'Write the message',
        adicionarTags: 'Add Tags:',
        mensagemDeTexto: 'Text message',
        caracteresRestantes: '{{value}} characters remaining',
        funcionalidade: 'Functionality'
      },
      email: {
        titulo: 'Email Templates',
        novo: 'New template',
        novoTitulo: 'New email template',
        cadastradoSucesso: 'Email template successfully registered!',
        atualizadoSucesso: 'Email template successfully updated!',
        removidoSucesso: 'Email template successfully removed!',
        escrevaMensagem: 'Write the message',
        adicionarTags: 'Add Tags:',
        mensagemDeTexto: 'Text message',
        caracteresRestantes: '{{value}} characters remaining',
        funcionalidade: 'Functionality',
        assunto: 'Subject'
      }
    },
    politicasDeAcesso: {
      titulo: 'Access Policies',
      novo: 'New profile',
      cadastradoSucesso: 'Profile registered successfully!',
      atualizadoSucesso: 'Profile updated successfully!',
      removidoSucesso: 'Profile removed successfully!',
      acessoAoSistema: 'System access',
      recursos: {
        titulo: 'Resources'
      },
      usuarios: {
        titulo: 'Users',
        novo: 'Add user',
        incluirPorCargo: 'Include users by job title',
        cadastradoSucesso: 'Users linked to profile!',
        removidoSucesso: 'User removed successfully!',
        atualizadoSucesso: 'User updated successfully!',
        jaVinculadoAoPerfil: 'User is already linked to this profile',
        confirmarUsuarioComVinculo: 'User {{usuarioNome}} is already linked to the access profile {{perfilOrigemNome}}. Do you want to change it to the profile {{perfilDestinoNome}}?'
      }
    },
    notificacoes: {
      titulo: 'System notifications',
      novo: 'New notification',
      cadastradoSucesso: 'Notification registered successfully!',
      atualizadoSucesso: 'Notification updated successfully!',
      removidoSucesso: 'Notification successfully removed!',
      canaisEnvio: {
        titulo: 'Shipping channels',
        novo: 'New shipping channel',
        cadastradoSucesso: 'Sending channel successfully registered!',
        removidoSucesso: 'Sending channel removed successfully!',
        tipoCanal: 'Channel type',
        selecione: 'Select type',
        selecioneTemplate: 'Select template',
        nomeTemplate: 'Template name'
      },
      condicoesEnvio: {
        titulo: 'When send',
        novo: 'New shipping condition',
        cadastradoSucesso: 'Sending condition registered successfully!',
        removidoSucesso: 'Shipping condition removed successfully!',
        selecioneCondicao: 'Select a shipping condition',
        intervalo: {
          hora: 'Break in hours',
          dia: 'Interval in days',
          semana: 'Break in weeks',
          mes: 'Interval in months',
          horaEnvio: 'shipping time'
        },
        helperText: {
          ANTES_DO_EVENTO: 'Allows you to select a period for sending notifications before the event',
          DEPOIS_DO_EVENTO: 'Allows you to select a period for sending notifications after the event',
          DIA_DO_EVENTO: 'Allows you to select the time to send notifications on the day of the event',
          CLIENTES: 'Notifications will be sent to all active customers',
          FORNECEDORES: 'Notifications will be sent to all active suppliers',
          FUNCIONARIOS: 'Notifications will be sent to all active employees',
          INDIVIDUAL: 'Select who the notification should be sent to'
        }
      },
      destinatarios: {
        titulo: 'Recipients',
        novo: 'New recipient or recipient group',
        cadastradoSucesso: 'Successfully added recipient!',
        removidoSucesso: 'Recipient removed successfully!',
        selecione: 'Select recipient',
        informeDestinatario: 'Inform the recipient'
      }
    }
  },
  restricoes: {
    nova: 'New restriction',
    restricaoCadastradaSucesso: 'Restriction successfully registered!'
  },
  bandeiras: {
    nova: 'New flag',
    bandeiraCadastradaSucesso: 'Flag successfully registered!'
  },
  credenciadoras: {
    nova: 'New acquirer',
    credenciadoraCadastradaSucesso: 'Accreditation company successfully registered!'
  },
  auth: {
    login: {
      titulo: 'Welcome to',
      subtitulo: 'Login to continue.',
      login: 'Login',
      senha: 'Password',
      button: 'Access',
      link: 'I forgot my password',
      sair: 'Exit',
      usuarioNaoEncontrado: 'User not found',
      usuarioNaoEncontradoDialog: 'Contact the administrator for more information..'
    },
    domain: {
      titulo: 'Welcome to',
      subtitulo: 'In order for us to identify you, please enter your company s URL.',
      domain: 'your company',
      button: 'Next',
      link: 'I forgot my URL'
    },
    changePassword: {
      titulo: 'Change Password',
      subtitulo: 'Think of something really easy to remember',
      password: 'New password',
      passwordCheck: 'Repeat password',
      button: 'Change',
      link: 'Back to login',
      senhaAlterada: 'Password changed successfully!'
    },
    forgotPassword: {
      titulo: 'I forgot my password',
      subtitulo: 'A recovery code will be sent to your email..',
      email: 'Email',
      button: 'To recover',
      link: 'Back to login',
      codigoEnviado: 'Recovery code sent to e-mail informed.'
    }
  },
  setup: {
    nomeCompleto: 'Company Name',
    verticalMercado: 'Market vertical',
    url: 'customer URL',
    cnpj: 'CNPJ',
    codigoContrato: 'Contract code',
    email: 'Email',
    senha: 'Password',
    confirmaSenha: 'Confirm password',
    licencas: {
      titulo: 'Licenses',
      licenca: 'Type of license',
      quantidade: 'The amount'
    },
    modulos: {
      titulo: 'Modules'
    },
    recursos: {
      titulo: 'Resources'
    },
    conclusao: {
      titulo: 'Conclusion',
      modulos: 'Modules',
      licencas: 'Licenses',
      encaminhar: 'Forward',
      email: 'Email',
      assunto: 'Subject',
      enviarEmail: 'Send me email',
      voltar: 'Back to start',
      'ir-para-o-sistema': 'Go to system'
    }
  },
  impressao: {
    formatoImpressao: 'Print format',
    orientacaoRelatorio: 'Report orientation',
    tipo: {
      pdf: 'PDF',
      csv: 'CSV',
      html: 'HTML'
    },
    orientacao: {
      retrato: 'PORTRAIT',
      paisagem: 'LANDSCAPE'
    }
  }
};
exports.default = _default;