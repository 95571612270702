"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _createTheme = require("./createTheme");

var _default = function _default(assets) {
  return (0, _createTheme.createTheme)({
    name: 'Educacional',
    assets: assets,
    colors: {
      primary: '#00a5e5',
      secondary: '#785afd'
    }
  });
};

exports.default = _default;