export default {
  geral: {
    app: 'Sponte Educacional',
    selecioneAluno: 'Selecionar um aluno',
    pedagogico: 'Pedagógico',
    integrantes: 'Integrantes',
    matriz: 'Matriz',
    diarioAulas: 'Diário de Aulas',
    notas: 'Notas',
    nota: 'Nota',
    individual: 'Individual',
    reposicao: 'Reposição',
    turno: 'Turno',
    etapa: 'Etapa',
    curso: 'Curso',
    sala: 'Sala',
    conceitos: 'Conceitos',
    aula: 'Aula',
    serie: 'Série',
    qtdMinima: 'Qtd mínima',
    anoLetivo: 'Ano letivo',
    calendarioPedagogico: 'Calendário pedagógico',
    calendarioPadrao: 'Calendário Padrão',
    calendarioPendencias: 'Calendário e Pendências',
    aulasPendencias: 'Aulas e Pendências',
    funcaoNaTurma: 'Função na turma',
    equipePedagogica: 'Equipe pedagógica',
    modalidade: 'Modalidade',
    areaDeConhecimento: 'Área de conhecimento',
    codigoInep: 'Código INEP',
    faixa: 'Faixa',
    anoFaixa: '$t(geral:ano) / $t(geral:faixa)',
    anoFaixaDe: 'De $t(geral:ano) / $t(geral:faixa)',
    anoFaixaAte: 'Até $t(geral:ano) / $t(geral:faixa)',
    matricula: 'Matricula',
    matriculaSerie: '$t(geral:matricula) / $t(geral:serie)',
    precoSerie: '$t(geral:preco) / $t(geral:serie)',
    diarioDeAulas: 'Diário de aulas',
    diarioDeAula: 'Diário de aula',
    diario: 'Diário',
    conteudo: 'Conteúdo',
    nMatricula: 'Nº matrícula',
    nDeAlunos: 'Nº de alunos',
    nChamada: 'Nº chamada',
    integrante: 'Integrante',
    mudarDeTurma: 'Mudar de turma',
    disciplina: 'Disciplinas',
    componente: 'Componente',
    professor: 'Professor',
    componenteCurricular: 'Componente curricular',
    disciplinaComponente: '$t(geral:disciplina) / $t(geral:componente)',
    selecioneUmAluno: 'Selecione um aluno',
    selecioneUmConteudoParaImportar: 'Selecione um conteúdo para importar',
    escrevaAqui: 'Escreva aqui...',
    mediaGeral: 'Média geral',
    componenteCurricularProfessor: '$t(geral:componenteCurricular) / $t(geral:professor)',
    turmasEmAndamento: 'Turmas em andamento',
    turmasEncerradas: 'Turmas encerradas',
    turmasCanceladas: 'Turmas canceladas',
    turmasEmFormacao: 'Turmas em formação',
    periodoTurno: 'Período / Turno',
    mediaFrequencia: 'Média / Frequência',
    siglaEtapa: 'Sigla da etapa',
    anoFaixaInicial: 'Ano / Faixa Inicial',
    anoFaixaFinal: 'Ano / Faixa Final',
    posicaoHabilidade: 'Posição da habilidade',
    siglaPosicao: 'Sigla da posição',
    unidadeTematica: 'Unidade temática',
    selecioneUnidadeTematica: 'Seleciona uma unidade temática',
    objetosDoConhecimento: 'Objetos do conhecimento',
    adicionarObjetosDoConhecimento: 'Adicionar objeto do conhecimento',
    aluno: 'Aluno',
    alunoResponsavel: 'Aluno/Responsável',
    'o-que': 'O que',
    para: 'Para',
    ordem: 'Ordem',
    clienteNumeroContrato: 'Cliente / Nº contrato',
    situacaoTurma: 'Situação / Turma',
    numeroMatricula: 'Nº matrícula',
    numeroContrato: 'Nº contrato',
    itemNumeroParcelas: 'Item / Nº de parcelas',
    totalValorParcela: 'Total / Valor de parcela',
    unica: 'Única',
    adicionarTurma: 'Adicionar turma',
    totalTurmas: 'Total de turmas',
    turmaComponente: 'Turma / Componente',
    turmaMediaAtual: 'Turma / Média atual',
    valorCargaHoraria: 'Valor / Carga horária',
    totalTurma: 'Total da turma',
    utilizarconfiguracoesDeFormulas: 'Utiliza configurações de fórmulas',
    nomeMatricula: 'Nome / Matrícula',
    nomeMediaAtual: 'Nome / Média atual',
    nomeLegenda: 'Nome / Legenda',
    nomeTemplate: 'Nome / Template',
    pesoOrdem: 'Peso / Ordem',
    segundaChamada: 'Segunda chamada',
    numeroContratoSituacao: 'Nº contrato / Situação',
    gradeCurricular: 'Grade Curricular',
    gradeCurricularSistemaAvaliacao: 'Grade Curricular e Sistema de Avaliação',
    situacaoPedagogica: 'Situação pedagógica',
    numeroChamada: 'Nº chamada',
    maisSobreCampoDeExperiencia: 'Mais sobre o campo de experiência',
    orientacoesGeraisQuantoAoProcessoPedagogico: 'Orientações gerais quanto ao processo pedagógico',
    direitoAprendizagem: 'Direito de aprendizagem',
    presenca: 'Presença',
    presencaMedianteConfirmacao: 'Presença mediante confirmação',
    falta: 'Falta',
    faltas: 'Faltas',
    justificativa: 'Justificativa',
    visualizarJustificativa: 'Visualizar justificativa',
    selecionetempoDuracaoAtividade: 'Selecione o tempo de duração da atividade',
    campoExperiencia: 'Campo de experiência',
    numeroAulas: 'Número de aulas',
    tempoDuracaoMinutos: 'Tempo de duração em minutos',
    selecionarGrade: 'Selecionar grade',
    selecioneUmaGrade: 'Selecione uma grade',
    cargaSemanal: 'Carga semanal',
    templateAvaliacao: 'Template avaliação',
    sistemaDeAvaliacao: 'Sistema de avaliacão',
    novaDisciplinaAdicional: 'Nova disciplina adicional',
    disciplinasAdicionais: 'Disciplinas adicionais',
    serieOuCurso: 'Série ou curso',
    dataDeRealizacao: 'Data de realização',
    comercial: 'Relacionamento',
    aoMenosUmAgendamento: 'Ao menos um agendamento',
    agendaCheia: 'Agenda cheia',
    diaSelecionado: 'Dia selecionado',
    diaBloqueado: 'Dia bloqueado',
    ano: 'Ano',
    mes: 'Mês',
    semana: 'Semana',
    dia: 'Dia',
    competencia: 'Competência',
    customizada: 'Customizada',
    habilidadeEnsinoMedio: 'Habilidade do ensino médio',
    habilidadeEnsinoFundamental: 'Habilidade do ensino fundamental',
    sistemaAvaliacao: 'Sistema de avaliação',
    cargaHorariaSemanal: 'Carga horária semanal',
    cargaHorariaAnual: 'Carga horária anual',
    componenteCurricularOptativo: 'Componente curricular optativo',
    adicionarComponenteCurricular: 'Adicionar componente curricular',
    numeroAula: 'Nº da aula',
    planoDeEnsino: 'Plano de ensino',
    parecerDescritivo: 'Parecer descritivo',
    linhaProgramatica: 'Linha programática',
    metodologia: 'Metodologia',
    tipoComponentePai: 'Tipo / Componente pai',
    cargaSemanaAnual: 'Carga semanal / Anual',
    componenteSistemaAvaliacao: 'Componente / Sistema de avaliação',
    novaAula: 'Nova aula',
    editarAula: 'Editar aula',
    organizacaoSequencial: 'Organização curricular sequencial',
    sistemaEnsino: 'Sistema de ensino',
    avaliacoes: 'Avaliações',
    novaAvaliacao: 'Nova avaliação',
    configuracaoDeBolsa: 'Configuração de bolsa',
    saldoConta: 'Saldo da conta',
    horarioAula: 'Horário de aula',
    dadosClinicos: 'Ficha de saúde',
    tempoAgendamentoAntesAula: 'Tempo para agendar antes da aula',
    subHabilitacoes: 'Disciplinas',
    habilitacoes: 'Habilitações',
    habilitacao: 'Habilitação',
    subHabilitacao: 'Sub-habilitação',
    formula: 'Fórmula',
    situacaoAluno: 'Situação do aluno',
    templateAvaliacaoPadrao: 'Template de avaliação padrão',
    sistemaAvaliacaoPadrao: 'Sistema de avaliação padrão',
    intervaloAvaliacao: 'Intervalo de avaliação',
    calculoMediaFinal: 'Cálculo da média final',
    calculoSubDisciplina: 'Cálculo de subdisciplina',
    mediaMinima: 'Média mínima',
    pesoMedia: 'Peso da média',
    frequenciaMinima: 'Frequência mínima',
    utilizarArredondamento: 'Utilizar arredondamento',
    arredondamento: 'Arredondamento',
    maxPontosAnuais: 'Máximo de pontos anuais',
    validarNotaMaximaRecuperacao: 'Validar nota máxima da recuperação',
    utilizaPontosFracionarios: 'Utilizar pontos fracionários',
    utilizaHabilidadesDescritivas: 'Utilizar habilidades descritivas',
    utilizaRecuperacaoParcial: 'Utiliza recuperação parcial',
    utilizarRecuperacaoParcial: 'Utilizar recuperação parcial',
    utilizaAgrupamentoAvaliacoes: 'Utiliza agrupamento de avaliações',
    utilizaExameFinal: 'Utiliza exame final',
    ignorarMenorNotaAgrupamento: 'Ignorar menor nota do agrupamento',
    desconsiderarAvaliacaoNotaNaoInformada: 'Desconsiderar avaliações se a nota não for informada',
    desconsiderarNotaRecuperacaoMenorQueZero: 'Desconsiderar a nota de recuperação se ela for menor que 0',
    desconsiderarNotaRecuperacaoMenorQueNotaPeriodo:
      'Desconsiderar a nota de recuperação se for menor que a nota do período',
    desconsiderarNotaRecuperacaoMediaRecuperacaoMenorQueZero:
      'Desconsiderar a nota de recuperação se a média após recuperação for menor que 0',
    desconsiderarNotaRecuperacaoMediaRecuperacaoMenorQueNotaPeriodo:
      'Desconsiderar a nota de recuperação se a média após recuperação for menor que a nota do período',
    desconsiderarNotaExameFinalMenorQueMedia:
      'Desconsiderar a nota de Exame Final se a ela for menor que a média após exame final',
    considerarNotaExameFinalMediaSuperiorMediaMinima:
      'Considerar a nota do Exame Final, mesmo que a média do aluno seja superior a média mínima',
    ignorarPesosCalcularMediaAposExame: 'Ignorar pesos para calcular média após Exame Final',
    configuracaoOuRecuperacaoParcial: 'Configuração ou recuperação parcial',
    agrupamentoAvaliacoes: 'Agrupamento de avaliações',
    mediaNotasParciais: 'Média de notas parciais',
    configuracoesRecuperacao: 'configurações da recuperação',
    mediaAposRecuperacao: 'Média após recuperação',
    exameFinal: 'Exame final',
    configuracoesExameFinal: 'configurações do exame final',
    mediaAposExameFinal: 'Média após exame final',
    notasParciais: 'Notas parciais',
    nomenclatura: 'Nomenclatura',
    peso: 'Peso',
    notaMaxima: 'Nota máxima',
    maximoPontos: 'Máximo de pontos',
    mediaResultante: 'Média resultante',
    tipoAvaliacao: 'Tipo de avaliação',
    mediaEFrequencia: 'Média e frequência',
    adicionarTemplate: 'Adicionar template',
    adicionarModelo: 'Adicionar modelo',
    numeroDeAvaliacoes: 'Nº de avaliações',
    definicoesAula: 'Definições da aula',
    teorica: 'Teórica',
    recuperacao: 'Recuperação',
    casasDecimais: 'Casas decimais',
    medias: 'Médias',
    mediasParciais: 'Médias parciais',
    recuperacoes: 'Recuperações',
    podeRetirarDependente: 'Pode retirar dependente',
    nBimestre: '{{number}}º Bimestre',
    nBim: '{{number}}º Bim',
    nTri: '{{number}}º Trim',
    nSem: '{{number}}º Sem',
    nTrimestre: '{{number}}º Trimestre',
    nSemestre: '{{number}}º Semestre',
    nAvaliacao: '{{number}}º avaliação',
    nPeriodo: '{{number}}º período',
    nModulo: '{{number}}º módulo',
    modulo: 'Módulo',
    selecioneUmaTurma: 'Selecione uma turma',
    conselhoDeClasse: 'Conselho de classe',
    somaDasNotas: 'Soma das notas',
    mediaFinal: 'Média final',
    mediaAnual: 'Média anual',
    emExame: 'Em exame',
    exame: 'Exame',
    media: 'Média',
    aprovado: 'Aprovado',
    emRecuperacao: 'Em recuperação',
    reprovado: 'Reprovado',
    cursando: 'Cursando',
    naoDefinida: 'Não definida',
    naoDefinido: 'Não definido',
    templatesParciais: 'Templates parciais',
    templatesFinais: 'Templates finais',
    parcialFinal: 'Parcial/Final',
    final: 'Final',
    parcial: 'Parcial',
    planoDeAula: 'Plano de aula',
    objetivo: 'Objetivo',
    conteudos: 'Conteúdos',
    recurso: 'Recurso',
    recursos: 'Recursos',
    habilidades: 'Habilidades',
    boletimEscolar: 'Boletim escolar',
    totalDeFaltas: 'Total de faltas',
    frequenciaDoAluno: 'Frequencia do aluno',
    numeroDeFaltas: 'Número de faltas',
    diretorEscola: 'Diretor(a) da escola',
    secretarioEscola: 'Secretário(a) da escola',
    mediaAtual: 'Média atual',
    rescisoes: 'Rescisões',
    alunosAtivos: 'Alunos ativos',
    alunosRematriculados: 'Alunos rematriculados',
    mediaAlunosTurma: 'Média Alunos/Turma',
    quantidadeAlunosVigentes: 'Quantidade de alunos vigentes',
    brasaoDoEstado: 'Brasão do Estado',
    mantenedora: 'Mantenedora',
    representante: 'Representante',
    mantenedoraResponsavel: 'Mantenedora / Representante',
    anosFaixa: 'Anos / Faixas',
    valorInicial: 'Valor inicial',
    valorFinal: 'Valor final',
    conceito: 'Conceito',
    diaLetivo: 'Dia letivo',
    cargaHoraria: 'Carga horaria',
    estabelecimento: 'Estabelecimento',
    cnpjEstabelecimento: 'CNPJ',
    autorizacao: 'Autorização',
    lei: 'Lei',
    resultado: 'Resultado',
    impressos: 'Impressos',
    detalhes: 'Detalhes',
    adicionaDisciplina: 'Adiciona disciplina',
    novoItem: 'Novo item',
    frequenciaMedia: 'Frequência média',
    dataInicial: 'Data inicial',
    dataFinal: 'Data final',
    situacoesPedagogicas: 'Situações pedagógicas',
    bimestral: 'Bimestral',
    trimestral: 'Trimestral',
    semestral: 'Semestral',
    modular: 'Modular',
    dataPrimeiroVencimento: 'Data primeiro vencimento',
    notaGeralTurma: 'Nota geral da turma',
    configuracaoAvaliacaoPadrao: 'Configuração de avaliação padrão',
    dataDaAula: 'Data da aula',
    verParcelas: 'Ver parcelas',
    itensServiços: 'Itens e serviços',
    adicionarDisciplinas: 'Adicionar disciplinas',
    calendarioAnual: 'Calendário anual',
    considerarDiasUteis: 'Considerar dias úteis no vencimento das parcelas',
    templateComercial: 'Template comercial',
    estabelecimentos: 'Estabelecimentos',

    feedbacks: {
      nenhumaDisciplinaCadastrar: 'Nenhuma disciplina cadastrada!',
      nenhumQuadroHorarioCadastrado: 'Nenhum quadro de horário cadastrado!',
      nenhumaGradeCurricularCadastrada: 'Nenhuma grade curricular cadastrada!',
      nenhumOrcamentoVinculadoMatricula: 'Nenhum orçamento vínculado a matrícula!',
      necessarioOrcamentoParaRealizarMatricula: 'Para realizar a matrícula, é necessário haver um orçamento vínculado',
      cadastreDisciplinaGradeCurricular: 'Cadastre ao menos uma disciplina na grade curricular',
      cadastreUmQuadroHorariosAcessoDiario: 'Cadastre o quadro de horário pa acessar o diário',
      cadastreUmaGradeCurricularAcessoHorarios: 'Cadastre a grade currícular para acessar os horários',
      informeSomenteCasasDecimais: 'Informe somente as casas decimais',
      contemplaNotasParciaisPeriodo: 'Contempla notas parcias e notas do período',
      nenhumSistemaAvaliacaoEscolhido: 'Nenhum sistema de avaliação selecionado.',
      sistemaAvaliacaoSemTemplate: 'Sistema de avaliação sem template.',
      nenhumaTurmaRelacionadaAnoFaixa: 'Nenhuma turma relacionada ao Ano / Faixa',
      nenhumaTurmaCadastrada: 'Nenhuma turma cadastrada',
      nenhumItemAdicionado: 'Nenhum item adicionado',
      formulaNaoConfigurada: 'Fórmula não configurada',
      nenhumProdutoDoTipoPatrimonioCadastrado: 'Nenhum produto do tipo patrimônio cadastrado',
      nenhumaAvaliacaoCadastradaTemplate: 'Nenhuma avaliação cadastrada',
      semAvaliacoesCadastradas: 'Nenhuma avaliação cadastrada',
      nenhumDiarioDeAulaNesteDia: 'Nenhum diário de aula neste dia',
      nenhumComentarioOuJustificativa: 'Nenhum comentário ou justificativa nesta aula.',
      turmaSemGradeCurricular: 'Turma sem grade curricular cadastrada!',
      justificativaAtualizadaSucesso: 'Justificativa atualizada com sucesso',
      max5colunas: 'Máximo de 5 colunas selecionadas',
      disciplinaNaoTemAvaliacaoOuConfiguracoes: 'Disciplina não tem avaliações cadastradas ou faltam configurações.',
      disciplinaNaoTemTemplateFinalconfigurado: 'Disciplina não tem template final configurado',
      obrigatorioParaAdicionarDesconto: 'Obrigatório para adicionar desconto',
      naoTemTemplateFinal: 'Não possui configuração',
      precisaLancarFrequencia: 'Precisa ter frequência lançada.',
      ultimoDiarioMes: 'Não tem diário posterior a esse nesse mês',
      primeiroDiarioMes: 'Não tem diário anterior a esse nesse mês',
      integrantePrecisaTerNotaLancadaParaEditarJustificativa:
        'O integrante precisa ter nota lançada para editar justificativa.'
    },

    navegacao: {
      pedagogico: 'Pedagógico',
      'preferencias-do-pedagogico': 'Preferências do pedagógico',
      'situacoes-pedagogicas': 'Situações pedagógicas',
      'documentacao-pedagogica': 'Documentação Pedagógica',
      turmas: 'Turmas',
      turnos: 'Turnos',
      'tipos-de-disciplina': 'Tipos de disciplina',
      'areas-de-conhecimento': 'Áreas de conhecimento',
      etapas: 'Etapas',
      habilidades: 'Habilidades',
      'tipos-de-sala': 'Tipos de salas / ambientes ',
      'modalidades-de-turma': 'Modalidades de turma',
      'tipos-de-cursos': 'Tipos de cursos',
      'series-equivalentes': 'Séries equivalentes',
      disciplinas: 'Disciplinas',
      'educacao-infantil': 'Educação infantil',
      'ensino-fundamental': 'Ensino fundamental',
      'ensino-medio': 'Ensino médio',
      'etapas-personalizadas': 'Etapas personalizadas',
      'ano-faixa': 'Ano / Faixa',
      'unidades-tematicas': 'Unidades temáticas',
      'competencias-gerais': 'Competências gerais',
      'competencias-especificas': 'Competências específicas',
      'grupos-de-avaliacao': 'Grupos de avaliação',
      'objetos-do-conhecimento': 'Objetos do conhecimento',
      'campos-atuacao': 'Campos de atuação',
      'praticas-de-linguagem': 'Práticas de linguagem',
      personalizado: 'Personalizado',
      matriculas: 'Matrículas',
      cursos: 'Cursos',
      'sistemas-de-ensino': 'Sistemas de ensino',
      'direitos-aprendizagem': 'Direitos de apredizagem',
      'campos-de-experiencia': 'Campos de experiência',
      'direitos-de-aprendizagem': 'Direitos de aprendizagem',
      'objetivos-aprendizagem-desenvolvimento': 'Objetivos de aprendizagem e desenvolvimento',
      'tempo-duracao-atividades': 'Tempo de duração de atividades',
      'horarios-aula': 'Horários de aula',
      'objetos-conhecimento': 'Objetos do conhecimento',
      'componentes-curriculares': 'Componentes curriculares',
      'campo-atuacao-social': 'Campo de Atuação Social',
      salas: 'Salas / ambientes',
      comercial: 'Relacionamento',
      'preferencias-do-comercial': 'Preferências do relacionamento',
      'grades-curriculares': 'Grades curriculares',
      'planos-financeiros': 'Planos financeiros',
      'templates-avaliacao': 'Templates de avaliação',
      'sistemas-avaliacao': 'Sistemas de avaliação',
      'dashboard-do-pedagogico': 'Dashboard do pedagógico',
      'fluxo-automacao-crm': 'Automação',
      'dashboard-do-relacionamento': 'Dashboard do relacionamento',
      'servicos-contratacoes': 'Serviços e contratações',
      'saidas-servicos-e-itens': 'Saídas - Serviços e itens',
      'contratos-e-matriculas': 'Contratos e matrículas',
      ocorrencias: 'Ocorrências',
      ocorrenciasEPareceres: 'Ocorrências e Pareceres',
      'tipos-ocorrencia': 'Tipos de ocorrência'
    },

    validacoes: {
      naoPodeIniciarExpressaoComOperador: 'Não pode iniciar expressão com esse operador',
      avaliacaoXNaoExiste: 'Avaliação {{value}} não existe',
      expressaoInvalida: 'Expressão inválida',
      variavelXNaoExiste: 'Variável {{value}} não existe',
      selecionarConfiguracaoDeBolsa: 'Selecione uma configuração de bolsa',
      selecionarConfiguracaoConvenio: 'Selecione uma configuração de convênio',
      selecionarConfiguracaoDesconto: 'Selecione uma configuração de desconto',
      bolsaIncluida: 'Bolsa já incluída',
      convenioIncluido: 'Convênio já incluído',
      descontoIncluido: 'Desconto já incluído',
      selecioneUmTipoDeCalculo: 'Selecione um tipo de cálculo'
    }
  },

  cadastro: {
    estabelecimento: 'Estabelecimento',

    cliente: {
      atualizadoSucesso: 'Cliente atualizado com sucesso',
      matriculaUsoInterno: 'Matrícula uso interno',
      registroMatricula: 'Registro matrícula',
      registroAluno: 'RA'
    },

    funcionarios: {
      habilitacoes: {
        titulo: 'Dados da Habilitação',
        deletadoSucesso: 'Habilitação removida com sucesso',
        criadoSucesso: 'Habilitação adicionada com sucesso',
        registro: 'Número do registro',
        novo: 'Nova habilitação',
        adicionar: 'Adicionar etapa e disciplinas',

        subHabilitacoes: {
          novo: 'Nova disciplina',
          adicionar: 'Adicionar disciplina',
          adicionadaSucesso: 'Disciplina adicionada com sucesso',
          removidaSucesso: 'Disciplina removida com sucesso'
        }
      }
    },

    preferencias: {
      habilitacoes: {
        nomeDaSubHabilitacao: 'Nome da sub-habilitação',
        subHabilitacoes: {
          novo: 'Nova sub-habilitação',
          nomeDaSubHabilitacao: 'Nome da sub-habilitação',
          cadastradoSucesso: 'Sub-habilitação cadastrada com sucesso'
        }
      }
    }
  },

  historicos: {
    historicos: 'Históricos',
    novoHistorico: 'Novo Histórico',
    resultadoPedagogico: 'Resultado pedagógico',
    adicionaHistorico: 'Adiciona Histórico',
    adicionarDisciplina: 'Adicionar disciplina',
    escrevaMensagem: 'Escreva a mensagem',
    certificadoConclusao: 'Certificado de conclusão',
    caracteresRestantes: 'caracteres restantes',
    mensagemDeTexto: 'Escreva a mensagem',
    atualizadoSucesso: 'Histórico atualizado com sucesso',
    removidoSucesso: 'Histórico removido com sucesso',
    historicoAdicionadoSucesso: 'Histórico adicionado com sucesso',
    impresso: { atualizadoSucesso: 'Impresso atualizado com sucesso' }
  },

  comercial: {
    dashboard: {
      portal: 'Portal do aluno'
    },
    painelDeTarefas: {
      titulo: 'Painel de tarefas',
      novo: 'Nova tarefa',
      cadastradoSucesso: 'Tarefa cadastrada com sucesso!',
      atualizadoSucesso: 'Tarefa atualizada com sucesso!',
      removidoSucesso: 'Tarefa removida com sucesso!',
      informacoes: 'Informações',
      nome: 'Título',
      selecioneTipoTarefa: 'Selecione o tipo da tarefa',
      faseTarefa: 'Fase da tarefa',
      verMais: 'Ver mais',
      horaInicio: 'Hora de início',
      horaTermino: 'Hora de Término',
      descricaoPlaceholder: 'Escreva aqui...',
      checklist: {
        titulo: 'Itens da lista',
        novo: 'Novo item',
        edit: 'Editar item',
        adicionar: 'Adicionar item',
        cadastradoSucesso: 'Item cadastrado com sucesso!',
        atualizadoSucesso: 'Item atualizado com sucesso!',
        removidoSucesso: 'Item removido com sucesso!',
        nome: 'Nome',
        comentario: 'Comentário',
        concluido: 'Concluído'
      },
      chart: {
        aFazer: 'A fazer',
        fazendo: 'Fazendo',
        atrasado: 'Atrasado',
        feito: 'Feito'
      },
      filtro: {
        titulo: 'Título',
        tipoTarefa: 'Tipo de tarefa',
        situacao: 'Situação',
        outro: 'Outros',
        limpar: 'Limpar'
      }
    }
  },

  financeiro: {
    vendas: {
      orcamentos: {
        composicao: {
          cadastroErro: 'Valor Total da condição diferente do Valor restante do Orçamento!',
          novo: 'Adicionar - Serviços e itens',
          item: 'Turma ou item',
          condicoes: {
            descontoBolsa: 'Desconto de bolsa'
          }
        }
      },

      saidas: {
        composicao: {
          novo: 'Adicionar turma / item',
          item: 'Turma ou item'
        }
      }
    },
    preferencias: {
      templatesComerciais: {
        titulo: 'Templates comerciais',
        novo: 'Novo template comercial',
        cadastradoSucesso: 'Template comercial cadastrado com sucesso!',
        atualizadoSucesso: 'Template comercial atualizado com sucesso!',
        removidoSucesso: 'Template comercial removido com sucesso!'
      }
    }
  },

  configuracoes: {
    empresa: {
      autorizacao: {
        anoFaixa: {
          cadastradoSucesso: 'Ano / faixa adicionado com sucesso!',
          removidoSucesso: 'Ano / faixa removido com sucesso!'
        }
      }
    }
  },

  pedagogico: {
    contratosMatriculas: {
      titulo: 'Contratos e matrículas',
      novo: 'Novo contrato / matrícula',
      cadastradoSucesso: 'Contrato / matrícula cadastrada com sucesso!',
      atualizadoSucesso: 'Contrato / matrícula atualizada com sucesso!',
      removidoSucesso: 'Contrato / matrícula removida com sucesso!',
      naoEhPossivelRemoverIntegrante: 'Não é possível remover integrantes que já possuem nota ou frequência lançada',

      contasAReceber: {
        titulo: 'Títulos',
        novo: 'Novo título',
        nenhumCadastro: 'Nenhum título cadastrado',
        cadastradoSucesso: 'Título cadastrado com sucesso!',
        atualizadoSucesso: 'Título atualizado com sucesso!',
        removidoSucesso: 'Título removido com sucesso!'
      },

      documentos: {
        titulo: 'Documentos',
        novo: 'Novo documento',
        nenhumCadastro: 'Nenhum documento cadastrado',
        impressao: 'Impressão de documentos',
        cadastradoSucesso: 'Documento cadastrado com sucesso!',
        atualizadoSucesso: 'Documento atualizado com sucesso!',
        removidoSucesso: 'Documento removido com sucesso!'
      },

      gruposPersonalizados: {
        titulo: 'Grupos personalizados',
        novo: 'Novo grupo personalizado',
        cadastradoSucesso: 'Grupo personalizado cadastrado com sucesso!',
        atualizadoSucesso: 'Grupo personalizado atualizado com sucesso!',
        removidoSucesso: 'Grupo personalizado removido com sucesso!'
      },

      orcamentos: {
        titulo: 'Orçamentos',
        novo: 'Novo orçamento de venda',
        nenhumCadastro: 'Nenhum orçamento cadastrado',
        cadastradoSucesso: 'Orçamento de venda cadastrado com sucesso!',
        atualizadoSucesso: 'Orçamento de venda atualizado com sucesso!',
        removidoSucesso: 'Orçamento de venda removido com sucesso!'
      },

      turmas: {
        titulo: 'Turmas',
        novo: 'Nova turma',
        selecione: 'Selecione uma turma',
        cadastradoSucesso: 'Turma cadastrada com sucesso!',
        atualizadoSucesso: 'Turma atualizada com sucesso!',
        removidoSucesso: 'Turma removida com sucesso!'
      },

      vendas: {
        titulo: 'Pedidos',
        novo: 'Novo pedido de venda',
        nenhumCadastro: 'Nenhum pedido de venda cadastrado',
        cadastradoSucesso: 'Pedido de venda cadastrado com sucesso!',
        atualizadoSucesso: 'Pedido de venda atualizado com sucesso!',
        removidoSucesso: 'Pedido de venda removido com sucesso!'
      }
    },

    relatorios: {
      titulo: 'Documentação Pedagógica',
      gerarHistoricoEscolar: 'Gerar histórico escolar',
      selecioneATurma: 'Selecione a turma',
      guiaTransferencia: 'Guia de Transferência',
      motivoTransferencia: 'Motivo da Transferência',

      fichaIndividual: {
        titulo: 'Ficha Individual',
        fichasSeremGeradas: 'Fichas a serem geradas',
        gerarFichaIndividual: 'Gerar Ficha Individual',
        gerarNFichas: 'Deseja realmente gerar {{number}} ficha(s)?',
        geradoSucesso: 'Fichas geradas com sucesso!'
      },

      historicoEscolar: {
        titulo: 'Histórico Escolar',
        gerarHistoricoEscolarEmLote: 'Gerar histórico em lote',
        gerarHistoricoEscolar: 'Gerar histórico',
        gerarEmLote: 'Gerar histórico escolar em lote',
        historicosSeremGerados: 'Históricos a serem gerados',
        geradoSucesso: 'Históricos gerados com sucesso!',
        gerarNHistoricos: 'Deseja realmente gerar {{number}} histórico(s)?'
      },

      boletim: {
        titulo: 'Boletim',
        gerarBoletimEscolar: 'Gerar boletim',
        gerarBoletimEmLote: 'Gerar boletim em lote',
        boletinsSeremGerados: 'Boletins a serem gerados',
        geradoSucesso: 'Boletins gerados com sucesso!',
        gerarNBoletins: 'Deseja realmente gerar {{number}} boletim(s)?'
      }
    },

    ocorrencias: {
      titulo: 'Ocorrências',
      tituloCard: 'Ocorrência',
      novo: 'Nova Ocorrência',
      data: 'Data da ocorrência',
      responsavelOcorrencia: 'Responsável da ocorrência',
      tipo: 'Tipo da ocorrência',
      principaisDados: 'Principais dados da ocorrência',
      assinadoResponsavelPedagogico: 'Ocorrência assinada pelo responsável pedagógico',
      assinadasFiltro: 'Ocorrências Assinadas',
      criadoPor: 'Criado por',
      adicionarAlunoTurma: 'Adicionar Aluno / Turma',
      adicionarAluno: 'Adicionar Aluno',
      responsavel: 'Responsável da Ocorrência',
      adicionarResponsavel: 'Novo Responsável da Ocorrência',
      nomeResponsavel: 'Nome do responsável',
      funcionarioHabilitadoOcorrencia: 'Responsável de ocorrência',
      funcionarioHabilitadoSucesso: 'Funcionário habilitado com sucesso!',
      funcionarioDesabilitadoSucesso: 'Funcionário desabilitado com sucesso!',
      funcionarioAtualizadoSucesso: 'Funcionario atualizado com sucesso!',
      cadastradoSucesso: 'Ocorrência cadastrada com sucesso!',
      atualizadoSucesso: 'Ocorrência atualizada com sucesso!',
      removidoSucesso: 'Ocorrência removida com sucesso!',
      emailEnviadoSucesso: 'E-mail enviado com sucesso!',
      habiliteResponsavelOcorrencia: 'Habilite um funcionário como responsável por ocorrências',
      impressao: {
        titulo: 'Ocorrência',
        nomeAluno: 'Nome do aluno:',
        tipoOcorrencia: 'Tipo da Ocorrência:',
        descricao: 'Descrição:',
        orientacao: 'Orientação:',
        criadoPor: 'Criado por:',
        responsavelOcorrencia: 'Responsável da ocorrência:',
        responsavelPedagogico: 'Responsável Pedagógico',
        turma: 'Turma:',
        disciplina: 'Disciplina:',
        anoFaixa: 'Ano / Faixa'
      },
      tiposOcorrencia: {
        titulo: 'Tipo de ocorrência',
        novo: 'Novo tipo de ocorrência',
        adicionarTipo: 'Adicionar tipo de ocorrência',
        cadastradoSucesso: 'Tipo de ocorrência cadastrado com sucesso!',
        atualizadoSucesso: 'Tipo de ocorrência atualizado com sucesso!',
        removidoSucesso: 'Tipo de ocorrência removido com sucesso!'
      }
    },

    matriculas: {
      titulo: 'Matrículas',
      novo: 'Nova matrícula',
      cadastradoSucesso: 'Matrícula cadastrada com sucesso!',
      atualizadoSucesso: 'Matrícula atualizada com sucesso!',
      removidoSucesso: 'Matrícula removida com sucesso!',
      adicionarSerieItem: 'Adicionar Serie / Produto',
      itensAnoFaixa: 'Ano / Faixa',
      totalDoContrato: 'Total do contrato',
      anoFaixaCondicao: 'Ano/Faixa / Condição',
      totalValorParcela: 'Total / Valor da parcela',
      itensMatricula: 'Itens da Matrícula',
      orcamentoMatriculaCadastradoSucesso: 'Orçamento para a matrícula cadastrado com sucesso!',
      tipoContratoNumero: 'Tipo do Contrato / Número',
      dataInicioFim: 'Data Inínio / Fim',
      situacaoContrato: 'Situação do contrato',
      verParcelas: 'Ver parcelas',
      impressaoContrato: 'Impressão de Contrato',
      visualizarImpressao: 'Visualizar Impressão',
      finalizar: 'Finalizar',
      vendedorDoOrcamento: 'Vendedor do orçamento',
      matriculasCanceladas: 'Matrículas canceladas',
      matriculasEncerradas: 'Matrículas encerradas',
      preMatriculas: 'Pré matrículas',
      matriculasRescindidas: 'Matrículas rescindidas',
      matriculasTrancadas: 'Matrícula trancadas',
      matriculasAtivas: 'Matrículas ativas',
      matriculasPendentes: 'Matrículas pendentes',
      proximo: 'Próximo',
      adicionarTurma: 'Adicionar Turma',
      turmaExistenteNaMatricula: 'A turma selecionada já está inclusa na matrícula',
      gerarTitulos: 'Gerar Títulos',
      nenhumOrcamentoVinculadoMatricula: 'Nenhum orçamento vínculado a matrícula!',

      itens: {
        adicionarAnoFaixaItem: 'Adicionar Ano/Faixa - Item',
        editarAnoFaixaItem: 'Editar Ano/Faixa - Item',
        selecionarAnoFaixaItem: 'Selecionar Ano/Faixa - Itens',
        selecioneUmAnoFaixaItem: 'Selecione um Ano/Faixa - Item',
        selecioneUmaTurma: 'Selecione uma turma',
        itens: 'Itens',
        adicionadoSucesso: 'Composição adicionada com sucesso',
        atualizadoSucesso: 'Composição atualizada com sucesso',
        deletadoSucesso: 'Composição deletada com sucesso',
        subTotalItem: 'Sub-total do item',
        subTotalItemCondicoes: 'Sub-total com as condições',
        gerenciarCondicoes: 'Gerenciar Condições',
        efetivar: 'Efetivar',
        pedidoGeradoSucesso: 'Pedido gerado com sucesso!',
        resumoMatricula: 'Resumo da matrícula',
        itemResponsavelFinanceiro: 'Item / Responsável Financeiro',
        totalCondicao: 'Total / Condição',
        itemSemEmbalagem: 'O item não possui nenhuma embalagem',
        selecioneUmItemAnoFaixa: 'Selecione um item Ano/Faixa para carregar os planos financeiros',
        isentarItem: 'Isentar item',
        justificativaIsencaoItem: 'Justificativa da isenção do item',

        condicoes: {
          titulo: 'Condições de Pagamento',
          novo: 'Adicionar Condição',
          atualizadoSucesso: 'Condições de Pagamento atualizado com sucesso!',
          semDados: 'Item sem condição de pagamento',
          semDadosDescricao: 'Adicione ao menos uma condição de pagamento para o item.',
          sessionBolsa: 'Desconto de bolsa',
          sessionDesconto: 'Desconto pontualidade',
          sessionConvenios: 'Desconto de convênio',
          sessionDescontoAvulso: 'Desconto avulso',
          configuracaoConvenio: 'Configuração de convênio',
          configuracaoDesconto: 'Configuração de desconto'
        }
      },

      documentoImpressao: {
        impressaoDocumentos: 'Impressão de documentos',
        modeloDeDocumentos: 'Modelo de documentos',
        documentoSalvoSucesso: 'Documento salvo com sucesso'
      }
    },

    etapas: {
      educacaoInfantil: {
        camposDeExperiencia: {
          titulo: 'Campos de experiência',
          novo: 'Novo campo de experiência',
          cadastradoSucesso: 'Campo de experiência cadastrado com sucesso!',
          atualizadoSucesso: 'Campo de experiência atualizado com sucesso!',
          removidoSucesso: 'Campo de experiência removido com sucesso!'
        },

        direitosDeAprendizagem: {
          titulo: 'Direitos de aprendizagem',
          novo: 'Novo direito de aprendizagem',
          cadastradoSucesso: 'Direito de aprendizagem cadastrado com sucesso!',
          atualizadoSucesso: 'Direito de aprendizagem atualizado com sucesso!',
          removidoSucesso: 'Direito de aprendizagem removido com sucesso!'
        },

        objetivosAprendizagemDesenvolvimento: {
          titulo: 'Objetivos de aprendizagem',
          novo: 'Novo objetivo de aprendizagem e desenvolvimento',
          cadastradoSucesso: 'Objetivo de aprendizagem e desenvolvimento cadastrado com sucesso!',
          atualizadoSucesso: 'Objetivo de aprendizagem e desenvolvimento atualizado com sucesso!',
          removidoSucesso: 'Objetivo de aprendizagem e desenvolvimento removido com sucesso!'
        }
      },

      ensinoFundamental: {
        habilidades: {
          titulo: 'Habilidades do ensino fundamental',
          novo: 'Nova habilidade do ensino fundamental',
          cadastradoSucesso: 'Habilidade do ensino fundamental cadastrada com sucesso!',
          atualizadoSucesso: 'Habilidade do ensino fundamental atualizada com sucesso!',
          removidoSucesso: 'Habilidade do ensino fundamental removida com sucesso!'
        },

        unidadesTematicas: {
          titulo: 'Unidades temáticas',
          novo: 'Nova unidade temática',
          cadastradoSucesso: 'Unidade temática cadastrada com sucesso!',
          atualizadoSucesso: 'Unidade temática atualizada com sucesso!',
          removidoSucesso: 'Unidade temática removida com sucesso!',
          habilidades: {
            adicionar: 'Adicionar nova habilidade',
            novo: 'Nova Habilidade',
            cadastradoSucesso: 'Habilidade adicionada com sucesso!',
            atualizadoSucesso: 'Habilidade atualizada com sucesso!',
            removidoSucesso: 'Habilidade removida com sucesso!'
          }
        },

        objetosConhecimento: {
          titulo: 'Objetos do conhecimento',
          novo: 'Novo Objeto do conhecimento',
          cadastradoSucesso: 'Objeto do conhecimento cadastrado com sucesso!',
          atualizadoSucesso: 'Objeto do conhecimento atualizado com sucesso!',
          removidoSucesso: 'Objeto do conhecimento removido com sucesso!',

          habilidades: {
            adicionar: 'Adicionar Habilidade',
            novo: 'Nova Habilidade',
            cadastradoSucesso: 'Habilidade adicionada com sucesso!',
            atualizadoSucesso: 'Habilidade atualizada com sucesso!',
            removidoSucesso: 'Habilidade removida com sucesso!'
          }
        },

        praticasLinguagem: {
          titulo: 'Práticas de linguagem',
          novo: 'Nova Prática de linguagem',
          cadastradoSucesso: 'Prática de linguagem cadastrada com sucesso!',
          atualizadoSucesso: 'Prática de linguagem atualizada com sucesso!',
          removidoSucesso: 'Prática de linguagem removida com sucesso!',

          habilidades: {
            adicionar: 'Adicionar habilidade',
            novo: 'Nova habilidade',
            cadastradoSucesso: 'Habilidade adicionada com sucesso!',
            atualizadoSucesso: 'Habilidade atualizada com sucesso!',
            removidoSucesso: 'Habilidade removida com sucesso!'
          }
        }
      },
      ensinoMedio: {
        camposAtuacao: {
          titulo: 'Campos de atuação',
          novo: 'Novo Campo de atuação',
          cadastradoSucesso: 'Campo de atuação cadastrado com sucesso!',
          atualizadoSucesso: 'Campo de atuação atualizado com sucesso!',
          removidoSucesso: 'Campo de atuação removido com sucesso!',
          habilidades: {
            adicionar: 'Adicionar habilidade',
            novo: 'Nova habilidade',
            cadastradoSucesso: 'Habilidade adicionada com sucesso!',
            atualizadoSucesso: 'Habilidade atualizada com sucesso!',
            removidoSucesso: 'Habilidade removida com sucesso!'
          }
        },

        habilidades: {
          titulo: 'Habilidades do ensino médio',
          novo: 'Nova habilidade do ensino médio',
          cadastradoSucesso: 'Habilidade do ensino médio cadastrada com sucesso!',
          atualizadoSucesso: 'Habilidade do ensino médio atualizada com sucesso!',
          removidoSucesso: 'Habilidade do ensino médio removida com sucesso!',
          anosFaixas: {
            adicionar: 'Adicionar Ano / faixa',
            novo: 'Novo Ano / faixa',
            cadastradoSucesso: 'Ano / faixa adicionado com sucesso!',
            atualizadoSucesso: 'Ano / faixa atualizado com sucesso!',
            removidoSucesso: 'Ano / faixa removido com sucesso!'
          }
        }
      },

      personalizadas: {
        etapas: {
          titulo: 'Etapas personalizadas',
          novo: 'Nova etapa personalizada',
          cadastradoSucesso: 'Etapa personalizada cadastrada com sucesso!',
          atualizadoSucesso: 'Etapa personalizada atualizada com sucesso!',
          removidoSucesso: 'Etapa personalizada removida com sucesso!'
        }
      }
    },

    preferencias: {
      conteudo: {
        novo: 'Novo conteúdo',
        cadastradoSucesso: 'Conteúdo cadastrado com sucesso!',
        descricaoDoConteudo: 'Descrição do conteúdo'
      },

      sistemasAvaliacao: {
        titulo: 'Sistemas de avaliação',
        novo: 'Novo Sistema de avaliação',
        cadastradoSucesso: 'Sistema de avaliação cadastrado com sucesso!',
        atualizadoSucesso: 'Sistema de avaliação atualizado com sucesso!',
        removidoSucesso: 'Sistema de avaliação removido com sucesso!',
        substituiMenorNotaParcialPelaNotaRecuperacao:
          'Substitui a menor nota parcial definida como ‘UsaRec’ pela nota da recuperação',
        substituiMediaNotaParcialPelaNotaRecuperacao:
          'Substitui a média das notas parciais definidas como ‘UsaRec’ pela nota da recuperação',
        explicacaoUsaRec: `‘UsaRec’ -> Opção que define as avaliações que serão afetadas pela nota da recuperação parcial,
        definida no cadastro de avaliações.`,
        pesoPeriodo: 'Peso período {{periodo}}',
        pesoMaximo: 'Peso máximo',
        pontuacaoMaximaAnual: 'Nº pontuação máxima anual',
        numeroModulos: 'Nº de módulos',
        considerarHabilidades: 'Considerar habilidades',
        sistemaDeAvaliacaoFinal: 'Sistema de avaliação final',
        periodoTipoPontuacao: 'Período e Tipo de pontuação',
        configuracaoAvaliacaoFinal: 'Configuração de notas finais',
        configuracaoAvaliacao: 'Configuração de avaliações',
        salvarDoc: 'Salve suas alterações para criar um novo conceito',

        conceitos: {
          novo: 'Novo Conceito',
          atualizadoSucesso: 'Conceito atualizado com sucesso',
          cadastradoSucesso: 'Conceito cadastrado com sucesso!',
          removidoSucesso: 'Conceito removido com sucesso!',
          titulo: 'Conceito',
          editar: 'Editar Conceito',
          valorEquivalente: 'Valor Equivalente',
          valorInicial: 'Valor Inicial',
          valorFinal: 'Valor Final'
        },

        arredondamentos: {
          medias: {
            cadastradoSucesso: 'Arredondamento de médias cadastrado com sucesso!',
            atualizadoSucesso: 'Arredondamento de médias atualizado com sucesso!'
          },

          mediasParciais: {
            cadastradoSucesso: 'Arredondamento de médias parciais cadastrado com sucesso!',
            atualizadoSucesso: 'Arredondamento de médias parciais atualizado com sucesso!'
          },

          notas: {
            cadastradoSucesso: 'Arredondamento de notas cadastrado com sucesso!',
            atualizadoSucesso: 'Arredondamento de notas atualizado com sucesso!'
          },

          recuperacoes: {
            cadastradoSucesso: 'Arredondamento de recuperações cadastrado com sucesso!',
            atualizadoSucesso: 'Arredondamento de recuperações atualizado com sucesso!'
          }
        }
      },

      sistemasEnsino: {
        titulo: 'Sistemas de ensino',
        novo: 'Novo Sistema de ensino',
        cadastradoSucesso: 'Sistema de ensino cadastrado com sucesso!',
        atualizadoSucesso: 'Sistema de ensino atualizado com sucesso!',
        removidoSucesso: 'Sistema de ensino removido com sucesso!'
      },

      modelosAvaliacao: {
        titulo: 'Modelos de avaliação',
        novo: 'Novo modelo de avaliação',
        editar: 'Editar modelo de avaliação',
        cadastradoSucesso: 'Modelo de avaliação cadastrado com sucesso!',
        atualizadoSucesso: 'Modelo de avaliação atualizado com sucesso!',
        removidoSucesso: 'Modelo de avaliação removido com sucesso!',

        avaliacoes: {
          novo: 'Nova avaliação',
          editar: 'Editar avaliação',
          cadastradoSucesso: 'Avaliação cadastrada com sucesso!',
          atualizadoSucesso: 'Avaliação atualizada com sucesso!',
          removidoSucesso: 'Avaliação removida com sucesso!',
          ordenadoSucesso: 'Avaliações ordenadas com sucesso!',

          periodos: {
            novo: 'Novo período',
            editar: 'Editar período',
            cadastradoSucesso: 'Período cadastrado com sucesso!',
            atualizadoSucesso: 'Período atualizado com sucesso!',
            removidoSucesso: 'Período removido com sucesso!'
          },

          tiposAvaliacoes: {
            novo: 'Novo tipo de avaliação',
            titulo: 'Tipos de avaliação',
            cadastradoSucesso: 'Tipo de avaliação cadastrado com sucesso!',
            atualizadoSucesso: 'Tipo de avaliação atualizado com sucesso!',
            removidoSucesso: 'Tipo de avaliação removido com sucesso!',
            conceitos: {
              titulo: 'Tipo de avaliação conceito',
              novo: 'Novo tipo de avaliação conceito',
              cadastradoSucesso: 'Tipo de avaliação conceito cadastrado com sucesso!',
              atualizadoSucesso: 'Tipo de avaliação conceito atualizado com sucesso!',
              removidoSucesso: 'Tipo de avaliação conceito removido com sucesso!',
              editar: 'Editar tipo de avaliação conceito'
            },
            habilidades: {
              titulo: 'Tipo de avaliação de habilidade',
              cadastradoSucesso: 'Tipo de avaliação habilidade cadastrado com sucesso!',
              atualizadoSucesso: 'Tipo de avaliação habilidade atualizado com sucesso!',
              removidoSucesso: 'Tipo de avaliação habilidade removido com sucesso!'
            }
          }
        },

        formulas: {
          novo: 'Nova fórmula',
          editar: 'Editar fórmula',
          cadastradoSucesso: 'Fórmula cadastrada com sucesso!',
          atualizadoSucesso: 'Fórmula atualizada com sucesso!',
          removidoSucesso: 'Fórmula removida com sucesso!',
          formulaSituacaoPedagogica: 'Fórmula situação pedagógica',
          exemploDeFormula: 'Exemplo de fórmula:',
          comPeso: 'Com peso',
          semPeso: 'Sem peso',
          replicarFormulaParaTodosPeriodos: 'Replicar fórmula para todos períodos',
          simularFormula: 'Simular fórmula',

          periodos: {
            novo: 'Novo período',
            editar: 'Editar período',
            cadastradoSucesso: 'Período cadastrado com sucesso!',
            atualizadoSucesso: 'Período atualizado com sucesso!',
            removidoSucesso: 'Período removido com sucesso!'
          }
        }
      },

      componentesCurriculares: {
        anosFaixas: {
          titulo: 'Anos / Faixas',
          novo: 'Novo Ano / Faixa',
          cadastradoSucesso: 'Ano / Faixa cadastrado com sucesso!',
          atualizadoSucesso: 'Ano / Faixa atualizado com sucesso!',
          removidoSucesso: 'Ano / Faixa removido com sucesso!'
        },

        disciplinas: {
          titulo: 'Disciplinas',
          novo: 'Nova disciplina',
          cadastradoSucesso: 'Disciplina cadastrada com sucesso!',
          atualizadoSucesso: 'Disciplina atualizada com sucesso!',
          removidoSucesso: 'Disciplina removida com sucesso!'
        }
      },

      gradesCurriculares: {
        titulo: 'Grades curriculares',
        novo: 'Nova Grade curricular',
        cadastradoSucesso: 'Grade curricular cadastrada com sucesso!',
        atualizadoSucesso: 'Grade curricular atualizada com sucesso!',
        removidoSucesso: 'Grade curricular removida com sucesso!',

        disciplinas: {
          titulo: 'Disciplinas',
          novo: 'Nova disciplina',
          cadastradoSucesso: 'Disciplina cadastrada com sucesso!',
          atualizadoSucesso: 'Disciplina atualizada com sucesso!',
          removidoSucesso: 'Disciplina removida com sucesso!',

          aula: {
            novo: 'Nova aula',
            cadastradoSucesso: 'Aula cadastrada com sucesso!',
            atualizadoSucesso: 'Aula atualizada com sucesso!',
            removidoSucesso: 'Aula removida com sucesso!',
            numeroDaAula: 'Número da aula',
            dataDaAula: 'Data da aula',

            conteudo: {
              novo: 'Novo conteúdo',
              criar: 'Criar conteúdo',
              cadastradoSucesso: 'Conteúdo cadastrado com sucesso!',
              atualizadoSucesso: 'Conteúdo atualizado com sucesso!',
              removidoSucesso: 'Conteúdo removido com sucesso!',
              selecioneAvaliacaoTemplateParcial: 'Selecione uma avaliação parcial',
              selecioneAvaliacaoTemplateFinal: 'Selecione uma avaliação final',
              avaliacoesTemplateParcial: 'Avaliações parciais',
              avaliacoesTemplateFinal: 'Avaliações finais',
              avaliacoesDoConteudo: 'Avaliações do conteúdo',
              avaliacaoRemovidaComSucesso: 'Avaliação removida com sucesso',
              avaliacaoAdicionadaComSucesso: 'Avaliação adicionada com sucesso'
            },

            recurso: {
              novo: 'Novo recurso',
              cadastradoSucesso: 'Recurso cadastrado com sucesso!',
              atualizadoSucesso: 'Recurso atualizado com sucesso!',
              removidoSucesso: 'Recurso removido com sucesso!'
            },

            habilidade: {
              cadastradoSucesso: 'Habilidade cadastrada com sucesso!',
              removidoSucesso: 'Habilidade removida com sucesso!'
            }
          },

          metodologias: {
            tecnicasEnsino: {
              titulo: 'Técnicas de ensino',
              novo: 'Nova Técnica de ensino',
              cadastradoSucesso: 'Técnica de ensino cadastrada com sucesso!',
              atualizadoSucesso: 'Técnica de ensino atualizada com sucesso!',
              removidoSucesso: 'Técnica de ensino removida com sucesso!'
            },

            recursosDidaticos: {
              titulo: 'Recursos didáticos',
              novo: 'Novo Recurso didático',
              cadastradoSucesso: 'Recurso didático cadastrado com sucesso!',
              atualizadoSucesso: 'Recurso didático atualizado com sucesso!',
              removidoSucesso: 'Recurso didático removido com sucesso!'
            },

            criteriosAvaliacao: {
              titulo: 'Critérios de avaliação',
              novo: 'Novo Critério de avaliação',
              cadastradoSucesso: 'Critério de avaliação cadastrado com sucesso!',
              atualizadoSucesso: 'Critério de avaliação atualizado com sucesso!',
              removidoSucesso: 'Critério de avaliação removido com sucesso!'
            },

            atividadesPraticas: {
              titulo: 'Atividades práticas',
              novo: 'Nova Atividade prática',
              cadastradoSucesso: 'Atividade prática cadastrada com sucesso!',
              atualizadoSucesso: 'Atividade prática atualizada com sucesso!',
              removidoSucesso: 'Atividade prática removida com sucesso!'
            },

            bibliografiaBasica: {
              titulo: 'Bibliografia básica',
              novo: 'Nova Bibliografia básica',
              cadastradoSucesso: 'Bibliografia básica cadastrada com sucesso!',
              atualizadoSucesso: 'Bibliografia básica atualizada com sucesso!',
              removidoSucesso: 'Bibliografia básica removida com sucesso!'
            },

            bibliografiaComplementar: {
              titulo: 'Bibliografia complementar',
              novo: 'Nova Bibliografia complementar',
              cadastradoSucesso: 'Bibliografia complementar cadastrada com sucesso!',
              atualizadoSucesso: 'Bibliografia complementar atualizada com sucesso!',
              removidoSucesso: 'Bibliografia complementar removida com sucesso!'
            }
          },

          planosDeEnsino: {
            cadastradoSucesso: 'Plano de ensino cadastrado com sucesso!',
            atualizadoSucesso: 'Plano de ensino atualizado com sucesso!',
            objetivosGerais: 'Objetivos gerais',
            bibliografia: {
              novo: 'Nova bibliografia',
              editar: 'Editar bibliografia',
              cadastradoSucesso: 'Bibliografia cadastrada com sucesso!',
              atualizadoSucesso: 'Bibliografia atualizada com sucesso!',
              removidoSucesso: 'Bibliografia removida com sucesso!',
              novaBibliografia: 'Nova bibliografia',
              tipoBibliografia: 'Tipo bibliografia'
            },
            conteudoProgramatico: {
              novo: 'Novo conteúdo programático',
              editar: 'Editar conteúdo programático',
              cadastradoSucesso: 'Conteúdo programático cadastrado com sucesso!',
              atualizadoSucesso: 'Conteúdo programático atualizado com sucesso!',
              removidoSucesso: 'Conteúdo programático removido com sucesso!',
              titulo: 'Conteúdo programático',
              validacaoPeriodoSelecionado: 'Você precisa selecionar um período',
              periodoIncluido: 'Período já adicionado',
              adicionarPeriodo: 'Adicionar período'
            },
            ementa: {
              novo: 'Nova ementa',
              editar: 'Editar ementa',
              cadastradoSucesso: 'Ementa cadastrada com sucesso!',
              atualizadoSucesso: 'Ementa atualizada com sucesso!',
              removidoSucesso: 'Ementa removida com sucesso!',
              titulo: 'Ementa'
            },
            formaAvaliacao: {
              novo: 'Nova forma de avaliação',
              editar: 'Editar forma de avaliação',
              cadastradoSucesso: 'Forma de avaliação cadastrada com sucesso!',
              atualizadoSucesso: 'Forma de avaliação atualizada com sucesso!',
              removidoSucesso: 'Forma de avaliação removida com sucesso!',
              titulo: 'Formas de avaliação'
            }
          },

          suplementar: {
            comentarios: {
              titulo: 'Comentários',
              novo: 'Novo comentário',
              cadastradoSucesso: 'Comentário cadastrado com sucesso!',
              atualizadoSucesso: 'Comentário atualizado com sucesso!',
              removidoSucesso: 'Comentário removido com sucesso!',
              adicionar: 'Adicionar comentários'
            },

            possibilidadesParaOCurriculo: {
              titulo: 'Possibilidades para o currículo',
              novo: 'Nova possibilidade',
              cadastradoSucesso: 'Possibilidade cadastrada com sucesso!',
              atualizadoSucesso: 'Possibilidade atualizada com sucesso!',
              removidoSucesso: 'Possibilidade removida com sucesso!',
              adicionar: 'Adicionar possibilidades'
            }
          },

          configuracoes: {
            camposDeAtuacao: {
              titulo: 'Campos de atuação',
              nome: 'Campo de atuação',
              novo: 'Novo campo de atuação',
              cadastradoSucesso: 'Campo de atuação cadastrado com sucesso!',
              atualizadoSucesso: 'Campo de atuação atualizado com sucesso!',
              removidoSucesso: 'Campo de atuação removido com sucesso!',
              adicionar: 'Adicionar campos de atuação'
            },

            objetosDoConhecimento: {
              titulo: 'Objetos do conhecimento',
              nome: 'Objeto do conhecimento',
              novo: 'Novo objeto do conhecimento',
              cadastradoSucesso: 'Objeto do conhecimento cadastrado com sucesso!',
              atualizadoSucesso: 'Objeto do conhecimento atualizado com sucesso!',
              removidoSucesso: 'Objeto do conhecimento removido com sucesso!',
              adicionar: 'Adicionar objetos do conhecimento'
            },

            praticasDeLinguagem: {
              titulo: 'Práticas de linguagem',
              nome: 'Prática de linguagem',
              novo: 'Nova prática de linguagem',
              cadastradoSucesso: 'Prática de linguagem cadastrada com sucesso!',
              atualizadoSucesso: 'Prática de linguagem atualizada com sucesso!',
              removidoSucesso: 'Prática de linguagem removida com sucesso!',
              adicionar: 'Adicionar práticas de linguagem'
            },

            unidadesTematicas: {
              titulo: 'Unidades temáticas',
              nome: 'Unidade temática',
              novo: 'Nova unidade temática',
              cadastradoSucesso: 'Unidade temática cadastrada com sucesso!',
              atualizadoSucesso: 'Unidade temática atualizada com sucesso!',
              removidoSucesso: 'Unidade temática removida com sucesso!',
              adicionar: 'Adicionar unidades temáticas'
            }
          }
        }
      },

      areasDeConhecimento: {
        titulo: 'Áreas de conhecimento',
        novo: 'Nova área de conhecimento',
        cadastradoSucesso: 'Área de conhecimento cadastrada com sucesso!',
        atualizadoSucesso: 'Área de conhecimento atualizada com sucesso!',
        removidoSucesso: 'Área de conhecimento removida com sucesso!'
      },

      competenciasEspecificas: {
        titulo: 'Competências específicas',
        novo: 'Nova competência específica',
        cadastradoSucesso: 'Competência específica cadastrada com sucesso!',
        atualizadoSucesso: 'Competência específica atualizada com sucesso!',
        removidoSucesso: 'Competência específica removida com sucesso!'
      },

      competenciasGerais: {
        titulo: 'Competências gerais',
        novo: 'Nova competência geral',
        cadastradoSucesso: 'Competência geral cadastrada com sucesso!',
        atualizadoSucesso: 'Competência geral atualizada com sucesso!',
        removidoSucesso: 'Competência geral removida com sucesso!'
      },

      gruposDeAvaliacao: {
        titulo: 'Grupos de avaliação',
        novo: 'Novo grupo de avaliação',
        cadastradoSucesso: 'Grupo de avaliação cadastrado com sucesso!',
        atualizadoSucesso: 'Grupo de avaliação atualizado com sucesso!',
        removidoSucesso: 'Grupo de avaliação removido com sucesso!',
        adicionar: 'Adicionar grupos de avaliação'
      },

      cursos: {
        titulo: 'Cursos',
        novo: 'Novo curso',
        cadastradoSucesso: 'Curso cadastrado com sucesso!',
        atualizadoSucesso: 'Curso atualizado com sucesso!',
        removidoSucesso: 'Curso removido com sucesso!'
      },

      modalidadesDeTurma: {
        titulo: 'Modalidades de turma',
        novo: 'Nova modalidade de turma',
        cadastradoSucesso: 'Modalidade de turma cadastrada com sucesso!',
        atualizadoSucesso: 'Modalidade de turma atualizada com sucesso!',
        removidoSucesso: 'Modalidade de turma removida com sucesso!'
      },

      tiposDeSala: {
        titulo: 'Tipos de sala / ambiente',
        novo: 'Novo tipo de sala',
        cadastradoSucesso: 'Tipo de sala cadastrado com sucesso!',
        atualizadoSucesso: 'Tipo de sala atualizado com sucesso!',
        removidoSucesso: 'Tipo de sala removido com sucesso!'
      },

      tiposDeDisciplina: {
        titulo: 'Tipos de disciplina',
        novo: 'Novo tipo de disciplina',
        cadastradoSucesso: 'Tipo de disciplina cadastrado com sucesso!',
        atualizadoSucesso: 'Tipo de disciplina atualizado com sucesso!',
        removidoSucesso: 'Tipo de disciplina removido com sucesso!'
      },

      sistemasDeEnsino: {
        titulo: 'Sistemas de ensino',
        novo: 'Novo sistema de ensino',
        cadastradoSucesso: 'Sistema de ensino cadastrado com sucesso!',
        atualizadoSucesso: 'Sistema de ensino atualizado com sucesso!',
        removidoSucesso: 'Sistema de ensino removido com sucesso!'
      },

      situacoesPedagogicas: {
        titulo: 'Situações pedagógicas',
        novo: 'Nova situação pedagógica',
        cadastradoSucesso: 'Situação pedagógica cadastrada com sucesso!',
        atualizadoSucesso: 'Situação pedagógica atualizada com sucesso!',
        removidoSucesso: 'Situação pedagógica removida com sucesso!'
      },

      turnos: {
        titulo: 'Turnos',
        novo: 'Novo turno',
        cadastradoSucesso: 'Turno cadastrado com sucesso!',
        atualizadoSucesso: 'Turno atualizado com sucesso!',
        removidoSucesso: 'Turno removido com sucesso!'
      },

      salas: {
        titulo: 'Salas / ambientes',
        novo: 'Novo sala / ambiente',
        cadastradoSucesso: 'Sala / ambiente cadastrado com sucesso!',
        atualizadoSucesso: 'Sala / ambiente atualizado com sucesso!',
        removidoSucesso: 'Sala / ambiente removido com sucesso!',
        equipamentos: {
          titulo: 'Equipamentos',
          selecionar: 'Selecionar Equipamento',
          novo: 'Novo Equipamento',
          editar: 'Editar Equipamento',
          cadastradoSucesso: 'Equipamento cadastrado com sucesso!',
          atualizadoSucesso: 'Equipamento atualizado com sucesso!',
          removidoSucesso: 'Equipamento removido com sucesso!'
        }
      },

      horariosAula: {
        titulo: 'Horários de aula',
        horarioDasAulas: 'Horários das aulas',
        novo: 'Novo horário de aula',
        editar: 'Editar horário de aula',
        cadastradoSucesso: 'Horário de aula cadastrado com sucesso!',
        atualizadoSucesso: 'Horário de aula atualizado com sucesso!',
        removidoSucesso: 'Horário de aula removido com sucesso!',
        aulaAtualizadaSucesso: 'Aula atualizada com sucesso!'
      }
    },

    turmas: {
      titulo: 'Turmas',
      novo: 'Nova turma',
      cadastradoSucesso: 'Turma cadastrada com sucesso!',
      atualizadoSucesso: 'Turma atualizada com sucesso!',
      removidoSucesso: 'Turma removida com sucesso!',
      dados: 'Dados da Turma',
      nomeTurma: 'Nome da turma',
      ordemSigla: 'Ordem / Sigla',
      tagsTurma: 'Tags da turma',
      removerTurma: 'Remover turma',
      alterarGrade: 'Ao alterar a grade curricular todas as informações anteriormente gravadas serão perdidas.',
      turmasCanceladas: 'Turmas canceladas',
      turmasEmAndamento: 'Turmas em andamento',
      turmasEmFormacao: 'Turmas em formação',
      turmasEncerradas: 'Turmas encerradas',
      disciplinaDaTurma: 'Disciplina da turma',
      situacoesTurma: {
        emAndamento: 'Em andamento',
        emFormacao: 'Em formação',
        cancelada: 'Cancelada',
        encerrada: 'Encerrada'
      },
      equipePedagogica: {
        novo: 'Novo integrante',
        atualizadoSucesso: 'Integrante atualizado com sucesso',
        cadastradoSucesso: 'Integrante cadastrado com sucesso!',
        removidoSucesso: 'Integrante removido com sucesso!',
        titulo: 'Equipe Pedagógica',
        editar: 'Editar integrante'
      },

      integrantes: {
        titulo: 'Integrantes',
        novo: 'Novo integrante',
        adicionar: 'Adicionar integrante',
        jaCadastrado: 'Integrante já adicionado',
        cadastradoSucesso: 'Integrante cadastrado com sucesso!',
        atualizadoSucesso: 'Integrante atualizado com sucesso!',
        removidoSucesso: 'Integrante removido com sucesso!'
      },

      horarios: {
        titulo: 'Quadro de horários',
        tipo: 'Tipo de horário',
        imprimirQuadroDeHorarios: 'Imprimir quadro de horários',
        cadastradoSucesso: 'Horário da turma cadastrado com sucesso!',
        atualizadoSucesso: 'Horário da turma atualizado com sucesso!',
        removidoSucesso: 'Horário da turma removido com sucesso!',
        aulaRemovidaSucesso: 'Aula removida com sucesso!',
        aulaAtualizadaSucesso: 'Aula atualizada com sucesso!',
        tipoDeFalta: 'Tipo de falta',
        tipoDeFaltaPorDia: 'Por dia',
        tipoDeFaltaPorDisciplina: 'Por disciplina',
        integrantes: 'Integrantes',
        atualizarRecorrente: 'Replicar alterações para as aulas seguintes?',
        removerRecorrente: 'Remover também as aulas seguintes?',
        gerarRecorrente: 'Aula recorrente',
        aulaRecorrente: 'Aula recorrente',
        aulaUnica: 'Aula única',
        editarAula: 'Editar aula',
        todosRegistrosPosteriores: 'Atualizar todos registros posteriores',
        aulaSelecionada: 'Aulas selecionadas',
        selecionarDias: 'Selecionar dias',
        seusHorariosAula: 'Seus horários de aula',
        horariosTurma: 'Horários da turma',
        mapaHorarios: 'Mapa de horários',
        tipoMapaHorarios: 'Tipo de mapa de horários',
        opcoesEdicao: 'Opções de edição',
        atualizarTodosRegistrosPosteriores: 'Atualizar todos os registros posteriores',
        calendarioAulas: 'Calendário de aulas',
        cadastrarAula: 'Cadastrar aula',
        selecionarOutro: 'Selecionar outro',
        selecionarUsuario: 'Selecionar usuário',
        confirmarAula: 'Confirmar aula',
        pesquisarPorAluno: 'Pesquisar por aluno',
        marcarPresenca: 'Marcar presença',
        detalhesPresenca: 'Detalhes da presença',
        xAulasHoje: '{{value}} aulas para hoje',
        justificarFalta: 'Justificar falta',
        justificarTodasFaltas: 'Justificar todas as faltas',
        faltaJustificada: 'Falta justificada',
        descrevaJustificativa: 'Descreva a justificativa',
        removerJustificativa: 'Remover justificativa',
        presencaParcial: 'Presença parcial',
        contemXAulasSemPresenca: 'Contém {{value}} aula(s) sem presença',
        contemXAulasFaltaJustificada: 'Contém {{value}} falta(s) justificada(s)',
        registrarComportamento: 'Registrar comportamento',
        notasAvaliacoes: 'Notas e avaliações',
        horariosAluno: 'Horários do aluno',
        gerarMapa: 'Gerar mapa',
        selecionarTurma: 'Selecionar turma',
        selecionarProfessor: 'Selecionar professor'
      },

      diariosAula: {
        aulaNaoPossuiIntegrantes: 'Essa aula não possui integrantes!',
        titulo: 'Diários de aulas',
        novo: 'Novo diário de aula',
        cadastradoSucesso: 'Diário de aula cadastrado com sucesso!',
        atualizadoSucesso: 'Diário de aula atualizado com sucesso!',
        removidoSucesso: 'Diário de aula removido com sucesso!',
        alunoNaoParticipaDestaDisciplina: 'Aluno não participa dessa disciplina',
        controleFrequencia: 'Controle de Frequência',
        pesquisarAlunos: 'Pesquisar alunos',
        alunoAtualizadoComSucesso: 'Aluno atualizado com sucesso',
        problemaAtualizarAluno: 'Problema atualizar aluno',
        conteudoPendente: 'Conteúdo pendente',
        conteudoLancado: 'Conteúdo lançado',
        aulaConfirmada: 'Aula confirmada',
        presenca: 'Presença',
        presencaMedianteReposicao: 'Presença mediante reposição',
        presencaJustificada: 'Presença justificada',
        justificativa: 'Justificativa',
        visualizar: 'Visualizar',
        falta: 'Falta',
        nomeDisciplina: 'Nome da disciplina',
        acoesDiarioAula: 'Ações do diário de aula',
        aulaNaoLancada: 'Aula não lançada',
        atualizarFrequencia: 'Atualizar frequências',
        atualizarConteudo: 'Atualizar conteúdo',
        naoTemAulasNessePeriodo: 'Não tem aulas nesse período',
        alunoNaoEncontrado: 'Aluno não encontrado.',

        conteudos: {
          cadastradoSucesso: 'Conteúdo de aula cadastrado com sucesso!',
          atualizadoSucesso: 'Conteúdo de aula atualizado com sucesso!'
        }
      },

      notas: {
        titulo: 'Notas',
        cadastradoSucesso: 'Nota cadastrada com sucesso!',
        atualizadoSucesso: 'Nota atualizada com sucesso!',
        removidoSucesso: 'Nota removida com sucesso!',
        selecioneUmaDisciplina: 'Selecione uma disciplina',
        colunas: 'Colunas',
        filtroDeNotas: 'Filtro de notas',
        notaInicial: 'Nota inicial',
        notaFinal: 'Nota final',
        relacioneUm: 'Relacione um ',
        naAba: ' na aba ',
        paraHabilitarLancamentoNota: ' para habilitar o lançamento de notas'
      }
    }
  },

  agenda: {
    preferencias: {
      tempoDuracaoAtividades: {
        titulo: 'Tempo de duração de atividades',
        novo: 'Novo tempo',
        cadastradoSucesso: 'Tempo cadastrado com sucesso!',
        atualizadoSucesso: 'Tempo atualizado com sucesso!',
        removidoSucesso: 'Tempo removido com sucesso!'
      }
    }
  },

  dashboard: {
    categorias: {
      ultimos12Meses: 'Últimos 12 meses',
      ultimoMes: 'Último mês',
      ultimaSemana: 'Última semana',
      ultimoDia: 'Último dia',
      semAgendamentosPorStatus: 'Sem agendamentos nos últimos 12 meses',
      agendamentosPorStatus: 'Agendamentos por status (últimos 12 meses)'
    },
    resumoFinanceiro: {
      hoje: 'Resumo financeiro (hoje)'
    }
  }
}
